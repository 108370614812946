import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from './ControllerBase';

/**
 * @param formType - for which form do we have to fetch consents (Custom forms will always send their slug, instead of using Enum FormWithConsent)
 * This param is optional because of ControllerCustomForm. For all the other forms (Register, Contact, etc), we can use FormWithConsent enum to fetch assigned consents for them.
 * But in case of custom forms, each custom form has its own form-slug passed as a prop to ControllerCustomForm.
 * And we will use it to lazy fetch assigned consents from ControllerCustomForm.
 * */

const Consentable = formType => mixins(ControllerBase).extend({
  data() {
    return {
      formType: formType || '',
      assignedConsents: [],
      assignedConsentsUniqueNumbers: [],
      requiredConsentsUniqueNumbers: [],
      consentsFetched: false,
      form: {
        acceptedConsents: []
      }
    };
  },
  // TODO: Move to created? Fetch will be overriden with components fetch, while created wont
  async fetch() {
    if (this.formType) {
      await this.initConsents();
    }
  },
  methods: {
    //New method introduced because of ControllerCustomForm,
    //this method should be called inside Controllers which cannot immediately set formType mixin argument
    async lazyInitConsents(formType) {
      this.formType = formType;
      await this.initConsents();
    },
    async initConsents() {
      const assignationFetchSuccess = await this.fetchConsentAssignation();
      if (!assignationFetchSuccess) return;
      const detailsFetchSuccess = await this.fetchAssignedConsentsDetails();
      if (!detailsFetchSuccess) return;
      this.appendSymbolsToRequiredConsents();
      this.consentsFetched = true;
    },
    /**
     * Fetch ids of consents assigned available for this form + Fetch ids of REQUIRED consents for this form to be submitted
     * After successful fetch, set assigned and required consents
     * @returns {Promise} fetch success
     */
    async fetchConsentAssignation() {
      const res = await this.$api.cms.consent.filter({});
      if (res.errored) return false;
      const consentAssignations = res.data.data;
      this.setAssignedConsentUniqueNumbers(consentAssignations);
      this.setRequiredConsentUniqueNumbers(consentAssignations);
      return true;
    },
    setAssignedConsentUniqueNumbers(consentAssignations) {
      var _consentAssignations$;
      const formTypeAssignedConsents = ((_consentAssignations$ = consentAssignations.find(assignation => assignation.assigned_to === this.formType)) === null || _consentAssignations$ === void 0 ? void 0 : _consentAssignations$.consents) || [];
      const c = this.assignedConsentsUniqueNumbers;
      c.splice(0, c.length, ...formTypeAssignedConsents);
    },
    setRequiredConsentUniqueNumbers(consentAssignations) {
      var _consentAssignations$2;
      const formTypeRequiredConsents = ((_consentAssignations$2 = consentAssignations.find(assignation => assignation.assigned_to === this.formType)) === null || _consentAssignations$2 === void 0 ? void 0 : _consentAssignations$2.requiredConsents) || [];
      const c = this.requiredConsentsUniqueNumbers;
      c.splice(0, c.length, ...formTypeRequiredConsents);
    },
    /**
     * Fetch consent details and set details for every assigned consent
     * @returns {Promise} fetch success
     */
    async fetchAssignedConsentsDetails() {
      const consents = await this.$api.gdpr.web.consent.filter({
        forWeb: true
      });
      if (consents.errored) return false;
      const c = this.assignedConsents;
      c.splice(0, c.length, ...consents.data.records.filter(r => this.assignedConsentsUniqueNumbers.includes(r.uniqueNumber)));
      return true;
    },
    /**
     * Appends star symbol to required consents innerHtml
     * */
    appendSymbolsToRequiredConsents() {
      this.assignedConsents.forEach(consent => {
        this.requiredConsentsUniqueNumbers.includes(consent.uniqueNumber) && (consent.displayName += '<span class="required-star-symbol">*</span>');
      });
    },
    /**
     * Check consent requirements
     */
    async validateConsents(acceptorEmail = '') {
      // Fetch errored
      let tPath = '';
      if (!this.consentsFetched) {
        tPath = 'error.consentsNotFetched';
        this.$toast.error(this.$t(tPath));
        return false;
      }
      // None needed
      if (!this.assignedConsents.length || !this.requiredConsentsUniqueNumbers.length) {
        return true;
      }
      const allRequiredConsentsAccepted = this.requiredConsentsUniqueNumbers.every(uniqueNumber => this.form.acceptedConsents.includes(uniqueNumber));
      // Consent is needed, but was not given
      if (this.assignedConsents.length && !allRequiredConsentsAccepted) {
        tPath = 'core.notifications.consentRequired';
        this.$toast.error(this.$t(tPath));
        return false;
      }
      if (acceptorEmail) {
        const consentLog = await this.submitConsentLog(acceptorEmail);
        if (consentLog.errored) {
          tPath = 'error.failedConsentLog';
          this.$toast.error(this.$t(tPath));
          return false;
        }
      }
      return true;
    },
    // /**
    //  * Fetch ids of consents required for this form to be submitted
    //  * @returns {Promise} array of required consent ids or
    //  * undefined if fetch errored
    //  */
    // async fetchRequiredConsentIds() {
    //   const consentAssignations = await this.$api.cms.consent.filter()
    //   if (consentAssignations.errored) return
    //   const needed = consentAssignations.data.data.find(
    //     (assignation) => assignation.assigned_to === this.formType
    //   )
    //   if (!needed?.consents.length) return []
    //   const consents = await this.$api.gdpr.web.consent.filter()
    //   if (consents.errored) return
    //   return consents.data.records
    //     .filter((r) => needed.consents.includes(r.uniqueNumber))
    //     .map((r) => r.id)
    // },
    /**
     * Submit consent log for given consent ids
     * @param {number[]} requiredConsentIds array of ids
     * @returns {Promise} standard api response object
     */
    async submitConsentLog(acceptorEmail) {
      const acceptedConsentIds = this.assignedConsents.filter(c => this.form.acceptedConsents.includes(c.uniqueNumber)).map(c => c.id);
      return this.$api.gdpr.web.consent.log.create({
        status: 'accepted',
        statusTimestamp: Date.now(),
        sourceType: 'cmsSite',
        consents: acceptedConsentIds,
        user: {
          userType: 'webUser',
          email: acceptorEmail
        }
      });
    }
  }
});
export default Consentable;