//Types
import mixins from "vue-typed-mixins";
//Mixins
import ControllerBase from '../../mixins/ControllerBase';
const ControllerPostCategory = mixins(ControllerBase).extend({
  name: 'ControllerPostCategory',
  data() {
    return {
      categorySlug: this.$route.params.slug || '',
      category: {}
    };
  },
  provide() {
    return {
      categorySlug: this.categorySlug
    };
  },
  async fetch() {
    const categoryDetails = await this.$api.cms.term.get(this.categorySlug);
    if (categoryDetails.errored) return;
    this.category = categoryDetails.data;
  },
  mounted() {
    this.$nuxt.$on('getActivePageData', setPageData => setPageData(this.category));
  },
  beforeDestroy() {
    this.$nuxt.$off('getActivePageData');
  },
  render() {
    const slotProps = {
      item: this.category
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerPostCategory;