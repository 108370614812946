export let ProductCustomOptionType;
(function (ProductCustomOptionType) {
  ProductCustomOptionType["Text"] = "text";
  ProductCustomOptionType["Number"] = "number";
})(ProductCustomOptionType || (ProductCustomOptionType = {}));
export let ProductFormat;
(function (ProductFormat) {
  ProductFormat["Simple"] = "simple";
  ProductFormat["Configurable"] = "configurable";
  ProductFormat["Bundle"] = "bundle";
  ProductFormat["EProduct"] = "eProduct";
})(ProductFormat || (ProductFormat = {}));