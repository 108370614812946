// Types
import mixins from "vue-typed-mixins";
// Mixins
import { WithPartialInject } from '../../utils/withInject';
import ControllerProductListContainer from './ControllerProductListContainer';
const inject = WithPartialInject('category');
const ControllerCategory = mixins(inject, ControllerProductListContainer).extend({
  name: 'ControllerCategory',
  data() {
    return {
      mandatoryFilters: [{
        key: 'archived',
        value: false
      }, {
        key: 'categories',
        value: [this.category.id]
      }],
      mandatoryParams: {
        mainCategory: this.category.id
      }
    };
  },
  computed: {
    computedSortOptions() {
      // This overrides all sort options array from controller product list container
      const categorySortOptionIds = this.category.sortTypes || [];
      if (categorySortOptionIds.length) {
        return this.allSortOptions.filter(option => categorySortOptionIds.includes(option.id));
      } else {
        return this.allSortOptions;
      }
    }
  },
  methods: {
    getDefaultSortOption() {
      const defaultSortOptionId = this.category.sortDefaultType;
      let defaultSort = null;
      if (defaultSortOptionId) {
        var _this$computedSortOpt;
        defaultSort = (_this$computedSortOpt = this.computedSortOptions.find(option => option.id === defaultSortOptionId)) === null || _this$computedSortOpt === void 0 ? void 0 : _this$computedSortOpt.name;
      }
      if (!defaultSort) {
        defaultSort = this.computedSortOptions[0].name;
      }
      return defaultSort || '';
    }
  },
  render() {
    const {
      products: items,
      computedOptions: options,
      computedSortOptions: sortOptions,
      itemsPerPageOptions,
      pageNumbers,
      category
    } = this;
    const slotProps = {
      ...this.paginatableSlotProps,
      items: items,
      options,
      sortOptions: sortOptions === null || sortOptions === void 0 ? void 0 : sortOptions.map(option => option.name),
      itemsPerPageOptions,
      pageNumbers,
      category
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerCategory;