// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../../mixins/ControllerBase';
import Paginatable from '../../../mixins/Paginatable';
const ControllerOrderHistory = mixins(ControllerBase, Paginatable).extend({
  name: 'ControllerOrderHistory',
  pagination() {
    return {
      request: (params, api) =>
      // TODO: put into service
      api.document.web.filter({
        filters: [{
          key: 'archived',
          value: false
        }, {
          key: 'itemImages',
          value: true
        }],
        ...params
      }),
      handleItems: items => {
        this.createExtendedDocuments(items);
      }
    };
  },
  data() {
    return {
      //Since each document contains very little valueable display data, we have to fetch each document single details and map items to extendedItems with additionalProperty extendedDetails
      extendedItems: []
    };
  },
  methods: {
    async createExtendedDocuments(documents) {
      this.extendedItems.splice(0, this.extendedItems.length);
      if (!this.$application.language) return;
      const documentDetailsRequests = [];
      documents.forEach(document => {
        const req = this.$api.document.web.get({
          id: document.id,
          language: this.$application.language.code,
          options: 'cms'
        });
        documentDetailsRequests.push(req);
      });
      const documentDetailsResponses = await Promise.all(documentDetailsRequests);
      const extendedDocuments = documents.filter((document, index) => !documentDetailsResponses[index].errored).map((document, index) => ({
        ...document,
        extendedDetails: documentDetailsResponses[index].data
      }));
      const e = this.extendedItems;
      e.splice(0, e.length, ...extendedDocuments);
      this.$emit('extended-documents-fetched');
    }
  },
  render() {
    const slotProps = {
      ...this.paginatableSlotProps,
      items: this.extendedItems
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerOrderHistory;