// Types
import mixins from "vue-typed-mixins";
// Utils
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// import Plyr from 'plyr'
import './CmsImage.css';

// Mixins
import { WithRefs } from '../../utils/withRefs';
import { WithPartialInject } from '../../utils/withInject';
import { MediaFormat } from '../../enums/Media';
const dummyInject = WithPartialInject('dummyMode');
const isInstagramMedia = media => {
  return media && 'media_type' in media && 'caption' in media;
};

// TODO: CIJELU KOMPONENTU RADI IZNOVA, NE MOZE OVAKO OSTAT NIKAKO
const CmsImage = mixins(WithRefs(), dummyInject).extend({
  props: {
    media: {
      type: [Array, Object],
      default: null
    },
    customSize: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    recommendedSize: {
      type: String,
      default: null
    },
    disableLazyLoad: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: undefined
    },
    height: {
      type: [String, Number],
      default: undefined
    },
    playsinline: {
      type: Boolean,
      default: false
    },
    preload: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      player: null
      // player: null as Plyr | null,
    };
  },

  computed: {
    returnLink() {
      if (this.dummyMode) {
        return this.returnPlaceholderImage;
      } else if (this.link) {
        return this.link;
      } else if (isInstagramMedia(this.media)) {
        return this.media.media_url;
      } else {
        return this.$cmsMedia({
          media: this.media,
          customSize: this.customSize
        });
      }
    },
    returnAlt() {
      if (this.link) {
        return 'image';
      } else {
        return this.$cmsMedia({
          media: this.media,
          alt: true
        });
      }
    },
    returnPlaceholderImage() {
      return this.placeholder || this.$siteLogo || '/cms-public/placeholders/broken-link.svg';
    },
    isVideo() {
      const {
        media
      } = this;
      if (!media) return false;
      if (isInstagramMedia(media)) return media.media_type.includes('VIDEO');
      return 'mime' in media && media.mime.includes('video');
    }
  },
  mounted() {
    //   if (!this.isVideo || !this.$refs?.video) return
    //   this.player = new Plyr(this.$refs.video)
  },
  watch: {
    media: {
      handler(val) {
        if (!this.isVideo || !this.$refs.video) {
          // this.player = null
          return;
        }
        if (!this.$isInEditMode) return;

        // TODO: current version 0.107.0, this was some kind of legacy code we had to adjust few days ago according to new image format handling. Seems like it is never being triggered but lets keep it just in case. Test this and remove if possible.
        if (!this.disableLazyLoad && this.$refs.lazyLoad) {
          const newSrc = this.$cmsMedia({
            media: val,
            customSize: this.customSize
          });
          const imageSourceRefs = this.$refs.imageSource;
          const imageRef = this.$refs.lazyLoad;

          // Update image source on image change while in edit mode
          if (imageRef) {
            // If there is a picture tag
            if (Array.isArray(newSrc) && newSrc.length && Array.isArray(imageSourceRefs) && imageSourceRefs.length) {
              newSrc.forEach((src, index, array) => index < array.length - 1 ? imageSourceRefs[index].srcset = src : imageRef.src = src);
            } else {
              typeof newSrc === 'string' && (imageRef.src = newSrc);
            }
          }
        } else {
          if (this.player) {
            const newPoster = this.$cmsMedia({
              media: this.media.poster,
              customSize: this.customSize
            });
            //   this.player.source = {
            //     ...this.player.source,
            //     poster:
            //       typeof newPoster === 'string'
            //         ? newPoster
            //         : this.returnPlaceholderImage,
            //   }
            return;
          }
          // this.player = new Plyr(this.$refs.video)
        }
      }
    },

    '$route.query.editPage': {
      handler(val) {
        if (val) {
          // Trigger editMode and based on that if recommended size exist change image
          if ((!this.media || Array.isArray(this.media) && !this.media.length) && !this.link) {
            this.returnPlaceholder();
          }
        }
      }
    }
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
    createImageSource(src) {
      const extension = src.split('.').pop();
      const allowedExtenstions = [MediaFormat.Avif, MediaFormat.Webp, MediaFormat.Jpeg, MediaFormat.Png];
      let type = null;
      if (extension && allowedExtenstions.includes(extension)) type = `image/${extension}`;
      return this.$createElement('source', {
        ref: 'imageSource',
        attrs: {
          srcset: src,
          type
        },
        refInFor: true
      });
    },
    createVideoSource(src) {
      return this.$createElement('source', {
        ref: 'videoSource',
        attrs: {
          src,
          type: this.media.mime
        }
      });
    },
    createImage(forcedSrc = '') {
      // forcedSrc means that image is inside picture tag since there is a media source for each media format
      return this.$createElement('img', {
        ...(!this.disableLazyLoad && {
          ref: 'lazyLoad'
        }),
        attrs: {
          width: this.width,
          height: this.height,
          alt: this.returnAlt,
          src: forcedSrc || this.returnLink
        },
        class: this.disableLazyLoad ? 'staticloaded' : 'lazyLoad',
        on: {
          load: this.onSuccess,
          // error: this.onError,
          click: this.emitClick
        }
      });
    },
    createPicture() {
      if (!Array.isArray(this.returnLink)) {
        return this.createImage();
      }
      return this.$createElement('picture', {}, [this.returnLink.map((src, index, array) => index < array.length - 1 ? this.createImageSource(src) : this.createImage(src))]);
    },
    createVideo() {
      return this.$createElement('div', {
        class: 'video-wrapper h-full'
      }, [this.$createElement('video', {
        ref: 'video',
        domProps: {
          poster: this.$cmsMedia({
            media: this.media.poster,
            customSize: this.customSize
          }),
          'data-poster': this.$cmsMedia({
            media: this.media.poster,
            customSize: this.customSize
          })
        },
        attrs: {
          controls: this.controls,
          width: this.width,
          height: this.height,
          playsinline: this.playsinline,
          preload: this.preload || undefined
        },
        class: 'h-full'
      },
      // Video element will always still use old gauss-gcr redirects, there are no alternative paths for videos
      [this.createVideoSource(this.returnLink)])]);
    },
    createContent() {
      if (this.isVideo) {
        return this.createVideo();
      } else if (Array.isArray(this.returnLink) && this.returnLink.length) {
        return this.createPicture();
      } else return this.createImage();
    },
    onError(e) {
      this.returnPlaceholder(e);
    },
    onSuccess(e) {
      var _element;
      let element = this.$refs.lazyLoad;
      if (e) {
        element = e.target;
      }
      if (!element) return;
      const imageUrl = ((_element = element) === null || _element === void 0 ? void 0 : _element.src) || '';
      if (imageUrl.search(this.returnPlaceholderImage) === -1) {
        var _element2;
        if ((_element2 = element) !== null && _element2 !== void 0 && _element2.style.removeProperty) {
          var _element3;
          (_element3 = element) === null || _element3 === void 0 ? void 0 : _element3.style.removeProperty('object-fit');
        } else {
          var _element4;
          (_element4 = element) === null || _element4 === void 0 ? void 0 : _element4.removeAttribute('object-fit');
        }
      }
    },
    returnPlaceholder(e) {
      let placeholder = this.returnPlaceholderImage;
      if (this.$isInEditMode && this.recommendedSize) {
        placeholder = `https://via.placeholder.com/${this.recommendedSize}`;
      }
      this.setPlaceholdersToImageSources(placeholder);
      let element = this.$refs.lazyLoad;
      if (e) {
        element = e.target;
      }
      if (element) {
        element.src = placeholder;
        element.setAttribute('style', 'object-fit: none');
      }
    },
    setPlaceholdersToImageSources(placeholder) {
      var _this$$refs;
      const imageSourceRefs = (_this$$refs = this.$refs) === null || _this$$refs === void 0 ? void 0 : _this$$refs.imageSource;
      if (Array.isArray(imageSourceRefs) && imageSourceRefs.length) {
        imageSourceRefs.forEach(imageSource => imageSource.srcset = placeholder);
      }
    }
  },
  render() {
    return this.createContent();
  }
});
export default CmsImage;