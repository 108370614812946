// Types
import mixins from "vue-typed-mixins";
import LoginWithRefreshHelper from '../../../mixins/LoginWithRefreshHelper';
import VuelidateHelper from '../../../mixins/VuelidateHelper';
const inject = WithPartialInject('isLoginPage');

// Utils
import { forEachKey } from '../../../utils/hasKey';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { WithPartialInject } from '../../../utils/withInject';
const ControllerLogin = mixins(LoginWithRefreshHelper, inject, VuelidateHelper).extend({
  name: 'ControllerLogin',
  props: {
    disableHomePageRedirect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    };
  },
  validations: {
    form: {
      username: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      const {
        username,
        password
      } = this.form;
      const loginSuccess = await this.performLogin(username, password);
      if (!loginSuccess) return;
      this.$emit("submitted");
      this.resetForm();
      if (!this.disableHomePageRedirect && this.isLoginPage) {
        this.$router.push('/');
      }
    },
    resetForm() {
      this.$v.form.$reset();
      forEachKey(this.form, key => this.form[key] = '');
    }
  },
  render() {
    const {
      form,
      submit,
      status,
      $v,
      generateErrorMessage
    } = this;
    const slotProps = {
      form,
      submit,
      status,
      $v,
      generateErrorMessage
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerLogin;