function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import BaseService from './BaseService';
import { defineProperty } from '../utils/defineProperty';
import ApiService from './ApiService';
import languages from '../languages';
import domainConfig from '../domainConfig';
export default class Application extends BaseService {
  constructor(ctx, i18n) {
    super('application', {
      domainConfig: null,
      languages: [],
      cmsOptions: [],
      checkoutSettings: {},
      currencySettings: {},
      mediaSizes: [],
      countries: [],
      siteReviewOptions: {},
      backofficeUserLanguage: ''
    }, ctx instanceof ApiService ? undefined : ctx);
    _defineProperty(this, "api", void 0);
    _defineProperty(this, "i18n", void 0);
    if (ctx instanceof ApiService) {
      this.api = ctx;
      this.i18n = i18n;
    } else {
      this.api = ctx.$api;
      this.i18n = ctx.app.i18n;
    }
  }
  get backofficeUserLanguage() {
    return this.storage.backofficeUserLanguage || '';
  }
  get domain() {
    return domainConfig;
  }
  get isEcommerce() {
    const {
      domain
    } = this;
    if (!domain || !domain.stores) return false;
    return !!domain.stores.length;
  }
  get cartAdditionBehaviour() {
    var _this$storage$checkou, _this$storage$checkou2;
    return (_this$storage$checkou = this.storage.checkoutSettings) === null || _this$storage$checkou === void 0 ? void 0 : (_this$storage$checkou2 = _this$storage$checkou.cart_type) === null || _this$storage$checkou2 === void 0 ? void 0 : _this$storage$checkou2.value;
  }
  get defaultLanguage() {
    const {
      cmsOptions
    } = this.storage;
    if (!cmsOptions) return;
    const option = cmsOptions.find(option => option.key === 'DefaultLanguage');
    return option ? option.value : undefined;
  }
  get languages() {
    return languages;
  }
  get language() {
    const {
      i18n,
      languages
    } = this;
    if (!languages) return;
    const currentLanguage = i18n && languages.find(language => language.code2 === i18n.locale);
    if (currentLanguage) return currentLanguage;
    const defaultLanguage = this.defaultLanguage;
    if (!defaultLanguage) {
      return;
    }
    return languages.find(language => language.code === defaultLanguage);

    // todo: further checks
  }

  get store() {
    const {
      language,
      domain
    } = this;
    if (!domain || !domain.stores || !language) return;
    return domain.stores.find(store => store.language === language.code);
  }
  get currency() {
    const {
      currencySettings
    } = this.storage;
    if (!currencySettings) return;
    const {
      allowedCurrencies,
      mainCurrencyId
    } = currencySettings;
    return allowedCurrencies.find(currency => currency.id === mainCurrencyId);
  }
  get countries() {
    const countries = [...(this.storage.countries || [])];
    defineProperty(countries, 'getById', {
      enumerable: false,
      value: id => countries.find(c => c.id === id)
    });
    defineProperty(countries, 'getByIso', {
      enumerable: false,
      value: iso => countries.find(c => c.iso === iso)
    });
    return countries;
  }
  findCmsOption(key) {
    var _this$storage$cmsOpti;
    return (_this$storage$cmsOpti = this.storage.cmsOptions) === null || _this$storage$cmsOpti === void 0 ? void 0 : _this$storage$cmsOpti.find(option => option.key === key);
  }

  /** Base site options, first api call without which site doesn't work */
  async detectDomain(domain) {
    const res = await this.api.master.domainDetect(domain);
    if (!res.errored) {
      this.api.setHeaders({
        apiKey: res.data.apiKeyToken,
        site: res.data.siteID
      });
      this.setState('domainConfig', res.data);
    }
    return res;
  }

  /** List of all languages */
  async fetchLanguages() {
    const res = await this.api.master.commons('languages');
    !res.errored && this.setState('languages', res.data.languages);
    return res;
  }

  /** List of any kind of options */
  async fetchCmsOptions() {
    const res = await this.api.cms.option.filter();
    !res.errored && this.setState('cmsOptions', res.data);
    return res;
  }

  /** Object containing objects relating to checkout settings... */
  async fetchCheckoutSettings() {
    const res = await this.api.ecommerce.settings.checkout();
    !res.errored && this.setState('checkoutSettings', res.data.config);
    return res;
  }

  /** Currency config */
  async fetchCurrencyConfig() {
    const res = await this.api.ecommerce.settings.currency();
    !res.errored && this.setState('currencySettings', res.data.responseData);
    return res;
  }

  /** List of media sizes */
  async fetchMediaSizes() {
    // Do not fetch media sizes which are disabled inside BackOffice
    const res = await this.api.media.option.filter({
      autoResizeOnly: false
    });
    !res.errored && this.setState('mediaSizes', res.data);
    return res;
  }

  /** List of countries */
  async fetchCountries() {
    const {
      language
    } = this;
    const res = await this.api.master.commons('countries', language === null || language === void 0 ? void 0 : language.code);
    !res.errored && this.setState('countries', res.data.countries);
    return res;
  }

  /** Object containing various comment options */
  async fetchSiteReviewOptions() {
    var _this$store;
    const res = await this.api.cms.comment.options({
      store_id: (_this$store = this.store) === null || _this$store === void 0 ? void 0 : _this$store.storeID
    });
    !res.errored && this.setState('siteReviewOptions', res.data);
    return res;
  }
  async fetchBackofficeUserLanguage() {
    const res = await this.api.settings.general.user({
      group: 'global',
      key: 'language'
    });
    if (res.errored) return res;
    try {
      this.setState('backofficeUserLanguage', res.data.global[0].value.code2);
    } catch (e) {
      throw new Error('settings/general/user has no "global language" setting');
    }
    return res;
  }
  async loadAdminLocale() {
    if (!this.i18n) {
      throw new Error('i18n not loaded');
      return;
    }
    await this.fetchBackofficeUserLanguage();
    const i18nUtils = await get18nUtils('utils.js');
    await i18nUtils.loadLanguageAsync({
      app: {
        i18n: this.i18n
      }
    }, this.backofficeUserLanguage);
    async function import18nUtils(filename) {
      return await import('~/.nuxt/nuxt-i18n/' + filename);
    }
    async function get18nUtils(filename) {
      try {
        return await import18nUtils(filename);
      } catch (e) {
        console.log('Another i18n utils.js file has to be used');
        return await import18nUtils('plugin.' + filename);
      }
    }
  }
}