// Types
import mixins from "vue-typed-mixins";
// Enums
import { AddressType, CheckoutSteps } from '../../../enums/Checkout';
import { FormWithConsent } from '../../../enums/Gdpr';

// Mixins
import ControllerBase from '../../../mixins/ControllerBase';
import { WithPartialInject } from '../../../utils/withInject';
import Consentable from '../../../mixins/Consentable';
import VuelidateHelper from '../../../mixins/VuelidateHelper';

// Utils
import { convertAddressCountryToId } from '../../../transformators/address';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { genEmptyAddress } from '../../../utils/address';
const pageCheckoutInject = WithPartialInject('delivery', 'setDelivery', 'billing', 'setBilling', 'goToNextStep', 'stepState', 'setIsCompany');
const ControllerAddress = mixins(ControllerBase, pageCheckoutInject, Consentable(FormWithConsent.Address), VuelidateHelper).extend({
  name: 'ControllerAddress',
  data() {
    return {
      form: {
        deliveryForm: {
          ...(this.delivery || genEmptyAddress(AddressType.Shipping))
        },
        billingForm: {
          ...(this.billing || genEmptyAddress(AddressType.Main))
        }
      },
      options: {
        useDifferentBillingAddress: false
      }
    };
  },
  props: {
    asCompany: {
      type: Boolean,
      default: false
    },
    disableConsentValidation: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    '$auth.loggedIn'() {
      this.fetchUserData();
    },
    '$auth.user'() {
      this.fetchUserData();
    },
    'form.deliveryForm': {
      deep: true,
      handler(newValue) {
        var _this$setDelivery;
        (_this$setDelivery = this.setDelivery) === null || _this$setDelivery === void 0 ? void 0 : _this$setDelivery.call(this, newValue);
      }
    },
    'form.billingForm': {
      deep: true,
      handler(newValue) {
        var _this$setBilling;
        (_this$setBilling = this.setBilling) === null || _this$setBilling === void 0 ? void 0 : _this$setBilling.call(this, newValue);
      }
    }
  },
  computed: {
    isStepActive() {
      if (!this.stepState) return true;
      return this.stepState.activeStep === CheckoutSteps.Address;
    },
    allowedCountries() {
      var _this$$application$st, _this$$application$st2;
      const allowedInSettings = (_this$$application$st = this.$application.storage.checkoutSettings) === null || _this$$application$st === void 0 ? void 0 : (_this$$application$st2 = _this$$application$st.allowedCountries) === null || _this$$application$st2 === void 0 ? void 0 : _this$$application$st2.value;
      const {
        countries
      } = this.$application.storage;
      if (!countries) return [];
      const includeAll = Array.isArray(allowedInSettings) ? allowedInSettings.includes('ALL') : allowedInSettings === 'ALL';
      const callback = country => Array.isArray(allowedInSettings) ? allowedInSettings.includes(country.code) : country.code === allowedInSettings;
      return includeAll ? countries : countries.filter(callback);
    }
  },
  created() {
    var _this$setIsCompany;
    (_this$setIsCompany = this.setIsCompany) === null || _this$setIsCompany === void 0 ? void 0 : _this$setIsCompany.call(this, this.asCompany);
  },
  async mounted() {
    var _this$$application$st3;
    if (!this.$application.countries.length) {
      await this.$application.fetchCountries();
    }
    this.fetchUserData();
    if (!((_this$$application$st3 = this.$application.storage.checkoutSettings) !== null && _this$$application$st3 !== void 0 && _this$$application$st3.allowedCountries)) {
      await this.$application.fetchCheckoutSettings();
    }
  },
  methods: {
    async fetchUserData() {
      var _user$addresses, _user$addresses2, _user$details, _user$details2, _this$$application$co, _user$company;
      const user = this.$auth.loggedIn ? this.$auth.user : await this.fetchGuestUser();
      if (!user) return;
      const deliveryAddress = (_user$addresses = user.addresses) === null || _user$addresses === void 0 ? void 0 : _user$addresses.find(address => address.type === AddressType.Shipping);
      const billingAddress = (_user$addresses2 = user.addresses) === null || _user$addresses2 === void 0 ? void 0 : _user$addresses2.find(address => address.type === AddressType.Main);
      this.form.deliveryForm = {
        contactEmail: (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.contactEmail) || user.email || '',
        contactPhone: (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.contactPhone) || '',
        city: (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.city) || '',
        firstName: (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.firstName) || ((_user$details = user.details) === null || _user$details === void 0 ? void 0 : _user$details.firstName) || '',
        lastName: (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.lastName) || ((_user$details2 = user.details) === null || _user$details2 === void 0 ? void 0 : _user$details2.lastName) || '',
        houseNumber: (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.houseNumber) || '',
        street: (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.street) || '',
        type: (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.type) || AddressType.Shipping,
        zipCode: (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.zipCode) || '',
        country: deliveryAddress ? ((_this$$application$co = this.$application.countries.getById(deliveryAddress.country)) === null || _this$$application$co === void 0 ? void 0 : _this$$application$co.iso) || '' : '',
        companyName: ((_user$company = user.company) === null || _user$company === void 0 ? void 0 : _user$company.name) || '',
        companyOIB: ''
      };
      if (billingAddress !== null && billingAddress !== void 0 && billingAddress.id) {
        var _user$details3, _user$details4, _this$$application$co2, _user$company2;
        this.form.billingForm = {
          contactEmail: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.contactEmail) || user.email || '',
          contactPhone: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.contactPhone) || '',
          city: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.city) || '',
          firstName: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.firstName) || ((_user$details3 = user.details) === null || _user$details3 === void 0 ? void 0 : _user$details3.firstName) || '',
          lastName: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.lastName) || ((_user$details4 = user.details) === null || _user$details4 === void 0 ? void 0 : _user$details4.lastName) || '',
          houseNumber: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.houseNumber) || '',
          street: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.street) || '',
          type: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.type) || AddressType.Main,
          zipCode: (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.zipCode) || '',
          country: billingAddress ? ((_this$$application$co2 = this.$application.countries.getById(billingAddress.country)) === null || _this$$application$co2 === void 0 ? void 0 : _this$$application$co2.iso) || '' : '',
          companyName: ((_user$company2 = user.company) === null || _user$company2 === void 0 ? void 0 : _user$company2.name) || '',
          companyOIB: ''
        };
      }
    },
    async fetchGuestUser() {
      const guestEmail = this.$getCookie('guestUser');
      if (!guestEmail) return;
      const res = await this.$cart.updateGuestUser({
        email: guestEmail
      });
      if (!res.errored) return res.data;
    },
    async submit() {
      // Email used for submitting consent log
      const acceptorEmail = this.form.billingForm.contactEmail || this.form.deliveryForm.contactEmail || '';
      this.$v.form.$touch();
      const consentsValid = this.disableConsentValidation || (await this.validateConsents(acceptorEmail));
      if (this.$v.form.$invalid || !consentsValid) return;
      const deliveryAddress = convertAddressCountryToId(this.form.deliveryForm, this.$application);
      const billingAddress = this.options.useDifferentBillingAddress ? convertAddressCountryToId(this.form.billingForm, this.$application) : {
        ...deliveryAddress,
        type: AddressType.Main
      };

      // We need to set billingForm to deliveryForm if useDifferentBillingAddress is false
      // because companyName and companyOIB cannot be saved, and we need them injected inside ControllerPayment if asCompany is being used
      if (!this.options.useDifferentBillingAddress) {
        Object.assign(this.form.billingForm, this.form.deliveryForm);
      }
      if (this.$auth.loggedIn) {
        await this.setUserAddresses(deliveryAddress, billingAddress);
      } else {
        await this.setGuestUser(deliveryAddress, billingAddress);
      }
    },
    async setGuestUser(deliveryAddress, billingAddress) {
      var _this$goToNextStep;
      const res = await this.$cart.updateGuestUser({
        addresses: [deliveryAddress, billingAddress],
        details: {
          firstName: billingAddress.firstName,
          lastName: billingAddress.lastName
        },
        email: billingAddress.contactEmail,
        company: this.asCompany ? {
          name: this.form.deliveryForm.companyName,
          address: {
            houseNumber: billingAddress.houseNumber,
            country: billingAddress.country,
            zipCode: billingAddress.zipCode,
            street: billingAddress.street,
            city: billingAddress.city,
            region: billingAddress.region,
            type: billingAddress.type
          }
        } : undefined
      });
      if (res.errored) return;
      (_this$goToNextStep = this.goToNextStep) === null || _this$goToNextStep === void 0 ? void 0 : _this$goToNextStep.call(this);
      this.$emit("submitted");
    },
    async setUserAddresses(deliveryAddress, billingAddress) {
      var _this$$auth$user$addr, _this$$auth$user$addr2, _this$goToNextStep2;
      const existingBillingAddress = (_this$$auth$user$addr = this.$auth.user.addresses) === null || _this$$auth$user$addr === void 0 ? void 0 : _this$$auth$user$addr.find(address => address.type === AddressType.Main);
      const existingDeliveryAddress = (_this$$auth$user$addr2 = this.$auth.user.addresses) === null || _this$$auth$user$addr2 === void 0 ? void 0 : _this$$auth$user$addr2.find(address => address.type === AddressType.Shipping);
      const billingRes = existingBillingAddress !== null && existingBillingAddress !== void 0 && existingBillingAddress.id ? await this.$api.user.web.address.update(existingBillingAddress.id, billingAddress) : await this.$api.user.web.address.create(billingAddress);
      if (billingRes.errored) return;
      const deliveryRes = existingDeliveryAddress !== null && existingDeliveryAddress !== void 0 && existingDeliveryAddress.id ? await this.$api.user.web.address.update(existingDeliveryAddress.id, deliveryAddress) : await this.$api.user.web.address.create(deliveryAddress);
      await this.$auth.fetchUser();
      if (deliveryRes.errored) return;
      (_this$goToNextStep2 = this.goToNextStep) === null || _this$goToNextStep2 === void 0 ? void 0 : _this$goToNextStep2.call(this);
      this.$emit("submitted");
    }
  },
  validations() {
    const emptyAddressValidation = {
      firstName: {},
      lastName: {},
      contactEmail: {},
      contactPhone: {},
      country: {},
      street: {},
      houseNumber: {},
      city: {},
      zipCode: {}
    };
    const emptyCompanyValidation = {
      companyName: {},
      companyOIB: {}
    };
    const addressValidation = {
      firstName: {
        required
      },
      lastName: {
        required
      },
      contactEmail: {
        required,
        email
      },
      contactPhone: {
        required
      },
      country: {
        required,
        maxLength: maxLength(75)
      },
      street: {
        required,
        maxLength: maxLength(75)
      },
      houseNumber: {
        required,
        maxLength: maxLength(15)
      },
      city: {
        required,
        maxLength: maxLength(75)
      },
      zipCode: {
        required,
        maxLength: maxLength(25)
      }
    };
    const companyValidation = {
      companyName: {
        required
      },
      companyOIB: {
        required
      }
    };
    const deliveryFormValidations = this.asCompany ? {
      ...addressValidation,
      ...companyValidation
    } : {
      ...addressValidation,
      ...emptyCompanyValidation
    };

    // @ts-ignore
    const billingFormValidations = this.options.useDifferentBillingAddress ? this.asCompany ? {
      ...addressValidation,
      ...companyValidation
    } : {
      ...addressValidation,
      ...emptyCompanyValidation
    } : {
      ...emptyAddressValidation,
      ...emptyCompanyValidation
    };
    return {
      form: {
        deliveryForm: {
          ...deliveryFormValidations
        },
        billingForm: {
          ...billingFormValidations
        }
      }
    };
  },
  render() {
    const {
      form,
      options,
      allowedCountries,
      submit,
      isStepActive,
      assignedConsents: consents,
      $v,
      generateErrorMessage
    } = this;
    const slotProps = {
      form,
      options,
      allowedCountries,
      submit,
      isStepActive,
      consents,
      $v,
      generateErrorMessage
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerAddress;