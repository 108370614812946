// Types

// Mixins
import { WithPartialInject } from '../utils/withInject';
import mixins from 'vue-typed-mixins';
const bundleListProviderInject = WithPartialInject('bundleListProvider');
const BundleListInjector = mixins(bundleListProviderInject).extend({
  computed: {
    computedBundle() {
      var _this$bundleListProvi;
      if ((_this$bundleListProvi = this.bundleListProvider) !== null && _this$bundleListProvi !== void 0 && _this$bundleListProvi.bundles) {
        var _this$bundleListProvi2, _this$bundleListProvi3;
        // @ts-expect-error _uid is not given in interface, but it does exist
        const uid = this._uid;
        const i = (_this$bundleListProvi2 = this.bundleListProvider) === null || _this$bundleListProvi2 === void 0 ? void 0 : (_this$bundleListProvi3 = _this$bundleListProvi2.tickets) === null || _this$bundleListProvi3 === void 0 ? void 0 : _this$bundleListProvi3[uid];
        if (i === undefined) return null;
        return this.bundleListProvider.bundles[i];
      }
      return null;
    }
  },
  created() {
    var _this$bundleListProvi4;
    (_this$bundleListProvi4 = this.bundleListProvider) === null || _this$bundleListProvi4 === void 0 ? void 0 : _this$bundleListProvi4.registerController(this);
  },
  beforeDestroy() {
    var _this$bundleListProvi5;
    (_this$bundleListProvi5 = this.bundleListProvider) === null || _this$bundleListProvi5 === void 0 ? void 0 : _this$bundleListProvi5.unregisterController(this);
  }
});
export default BundleListInjector;