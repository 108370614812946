// Types
import mixins from "vue-typed-mixins";
// Mixins
import ProductListProvider from '../../mixins/ProductListProvider';
import ControllerBase from '../../mixins/ControllerBase';
import Paginatable from '../../mixins/Paginatable';
import ProductFilterFieldSelector from '../../mixins/ProductFilterFieldSelector';

// Utils
import { getProductPrices, getRecordAttribute } from '../../utils/productHelpers';
import { LayeredNavigationType } from '../../enums/LayeredNavigation';
const ControllerProductListContainer = mixins(ControllerBase, Paginatable, ProductListProvider, ProductFilterFieldSelector).extend({
  name: 'ControllerProductListContainer',
  provide() {
    return {
      setFilter: this.setFilter,
      clearFilter: this.clearFilter,
      setFilters: this.setFilters,
      layeredFilters: this.layeredFilters
    };
  },
  pagination() {
    const featuredFilters = this.$route.query.featured ? [{
      key: 'featured',
      value: true
    }] : [];
    return {
      request: (params, api) => api.product.web.filter({
        ...params,
        ...this.mandatoryParams,
        limit: this.computedOptions.itemsPerPage,
        sort: this.getSortParam(),
        filters: [...this.mandatoryFilters, ...this.filters, ...featuredFilters],
        select: this.computedSelectParam
      }),
      handleItems: items => {
        this.setProducts(items);
      }
    };
  },
  watch: {
    '$route.query.featured'() {
      this.setPage(1, true);
    }
  },
  // Override Paginatable because we also need layered nav
  async fetch() {
    this.options.sort = this.getDefaultSortOption();
    await this.fetchLayeredNavigation();
    await this.fetchData();
  },
  data() {
    // These are default sort options and will be shown if custom sort options are not set in Backoffice
    const allSortOptions = [{
      id: 1,
      name: this.$t('core.input.newestFirst'),
      param: 'id',
      order: 'desc',
      default: true
    }, {
      id: 2,
      name: this.$t('core.input.oldestFirst'),
      param: 'id',
      order: 'asc'
    }, {
      id: 3,
      name: this.$t('core.input.cheapestFirst'),
      param: 'finalPrice',
      order: 'asc'
    }, {
      id: 4,
      name: this.$t('core.input.mostExpensiveFirst'),
      param: 'finalPrice',
      order: 'desc'
    }, {
      id: 5,
      name: this.$t('core.input.nameAToZ'),
      param: 'name',
      order: 'asc'
    }, {
      id: 6,
      name: this.$t('core.input.nameZToA'),
      param: 'name',
      order: 'desc'
    }, {
      id: 7,
      name: this.$t('core.input.lowPriorityFirst'),
      param: 'categoryPriority',
      order: 'asc'
    }, {
      id: 8,
      name: this.$t('core.input.highPriorityFirst'),
      param: 'categoryPriority',
      order: 'desc'
    }];
    return {
      allSortOptions,
      itemsPerPageOptions: [12, 24],
      filters: [],
      options: {
        itemsPerPage: 12,
        sort: ''
      },
      mandatoryFilters: [{
        key: 'archived',
        value: false
      }],
      layeredFilters: [],
      // Overriden inside ControllerCategory, do not remove
      mandatoryParams: {}
    };
  },
  computed: {
    computedOptions() {
      const {
        options,
        setPage,
        paginationState
      } = this;
      return {
        get page() {
          return options.page;
        },
        set page(newPage) {
          if (newPage < 1 || newPage > paginationState.totalPages) return;
          setPage(newPage);
        },
        get itemsPerPage() {
          return options.itemsPerPage;
        },
        set itemsPerPage(value) {
          options.itemsPerPage = value;
          setPage(1, true);
        },
        get sort() {
          return options.sort;
        },
        set sort(value) {
          options.sort = value;
          setPage(1, true);
        }
      };
    },
    // Overridden inside ControllerCategory
    computedSortOptions() {
      return this.allSortOptions;
    },
    activeSortOption() {
      const {
        sort
      } = this.computedOptions;
      return this.computedSortOptions.find(option => option.name === sort);
    }
  },
  methods: {
    getDefaultSortOption() {
      var _this$computedSortOpt;
      let defaultSort = (_this$computedSortOpt = this.computedSortOptions.find(option => option.default)) === null || _this$computedSortOpt === void 0 ? void 0 : _this$computedSortOpt.name;
      if (!defaultSort) {
        defaultSort = this.computedSortOptions[0].name;
      }
      return defaultSort || '';
    },
    async fetchLayeredNavigation() {
      const filters = this.mandatoryFilters;
      const response = await this.$api.product.layered.navigation(filters);
      if (response.errored) return;
      // TODO: whyyyyyyyyyyyyyyyyy
      if (!Array.isArray(response.data)) return;
      const f = this.layeredFilters;
      f.splice(0, f.length, ...response.data);
      this.assignStartingFilters();
    },
    assignStartingFilters() {
      this.layeredFilters.forEach(filter => {
        const pushFilter = value => {
          this.filters.push({
            key: filter.attributeCode,
            value
          });
        };
        const queryValue = this.getValueFromQuery(filter);
        if (queryValue) {
          pushFilter(queryValue);
        } else if (filter.type === LayeredNavigationType.Number) {
          if (!filter.values || !filter.values.length) return;
          const {
            minValue,
            maxValue
          } = filter.values[0];
          pushFilter([minValue, maxValue]);
        } else {
          const defaultValue = filter.values.find(value => value.isDefault);
          defaultValue && pushFilter([defaultValue.attributeID]);
        }
      });
    },
    getValueFromQuery(filter) {
      const qValue = this.$route.query[filter.attributeCode];
      if (!qValue) return;
      return qValue.split(',').map(Number);
    },
    getSortParam() {
      const option = this.activeSortOption;
      if (!option) return;
      const {
        order,
        param
      } = option;
      return {
        order,
        param
      };
    },
    setFilters(filters) {
      this.filters = filters;
      this.fetchDataDebounced();
    },
    setFilter(key, value) {
      const index = this.filters.findIndex(f => f.key === key);
      if (index < 0) {
        this.filters.push({
          key,
          value
        });
      } else {
        this.filters.splice(index, 1, {
          key,
          value
        });
      }
      this.fetchDataDebounced();
    },
    clearFilter(key) {
      const index = this.filters.findIndex(f => f.key === key);
      if (index < 0) return;
      this.filters.splice(index, 1);
      this.fetchDataDebounced();
    },
    getRecordAttribute,
    getProductPrices
  },
  render() {
    const {
      products: items,
      computedOptions: options,
      computedSortOptions: sortOptions,
      itemsPerPageOptions,
      pageNumbers
    } = this;
    const slotProps = {
      ...this.paginatableSlotProps,
      items: items,
      options,
      sortOptions: sortOptions === null || sortOptions === void 0 ? void 0 : sortOptions.map(option => option.name),
      itemsPerPageOptions,
      pageNumbers
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerProductListContainer;