// Types
import mixins from "vue-typed-mixins";
// Mixins
import StatusUpdateable from './StatusUpdateable';
import ControllerBase from './ControllerBase';
import Debouncable from './Debouncable';

/**
 * @author Dominik Đamić
 * A mixin providing options for fetching paginated data and handling pagination
 */
const Paginatable = mixins(StatusUpdateable, ControllerBase, Debouncable({
  items: 500
})).extend({
  props: {
    limit: {
      type: [String, Number],
      default: 10
    },
    pageNumbersLength: {
      type: [String, Number],
      default: 9
    }
  },
  data() {
    return {
      shouldLoadMore: false,
      isLoadingMore: false,
      items: [],
      // TODO: dodaj tip itema da se prima kroz paginatable ili šta god
      options: {
        page: 1,
        keywords: ''
      },
      paginationData: {
        totalPages: 1,
        totalItems: 0
      }
    };
  },
  watch: {
    'options.page'() {
      this.fetchDataDebounced();
    },
    'options.keywords'() {
      if (this.options.page === 1) {
        this.fetchDataDebounced();
      } else {
        this.options.page = 1;
      }
    }
  },
  computed: {
    paginationState() {
      const {
        totalPages,
        totalItems
      } = this.paginationData;
      const {
        page
      } = this.options;
      return {
        totalPages: totalPages,
        totalItems: totalItems,
        onFirstPage: page === 1,
        onLastPage: page >= totalPages
      };
    },
    pageNumbersBeforeCurrent() {
      const numberOfPagesBefore = Math.max(this.options.page - 1, 0);
      return Array.from(Array(numberOfPagesBefore), (_, i) => i + 1);
    },
    pageNumbersAfterCurrent() {
      const {
        page
      } = this.options;
      const {
        totalPages
      } = this.paginationState;
      const numberOfPagesAfter = Math.max(totalPages - page, 0);
      return Array.from(Array(numberOfPagesAfter), (_, i) => page + i + 1);
    },
    pageNumbers() {
      const {
        page
      } = this.options;
      const onEachSide = Math.floor(Number(this.pageNumbersLength) / 2);
      const leftOffset = page - 1 - onEachSide;
      const left = this.pageNumbersBeforeCurrent.slice(Math.max(leftOffset, 0));
      const right = this.pageNumbersAfterCurrent.slice(0, onEachSide);
      return [...left, page, ...right];
    },
    paginatableSlotProps() {
      const {
        items,
        options,
        paginationState,
        setPage,
        nextPage,
        previousPage,
        firstPage,
        lastPage,
        loadMore,
        status
      } = this;
      return {
        items,
        options,
        paginationState,
        setPage,
        nextPage,
        previousPage,
        firstPage,
        lastPage,
        loadMore,
        status
      };
    }
  },
  async fetch() {
    await this.fetchData();
  },
  methods: {
    fetchDataDebounced() {
      this.debounce('items', this.fetchData);
    },
    async fetchData() {
      var _this$$options$pagina;
      if (this.shouldLoadMore && this.isLoadingMore) return;
      this.isLoadingMore = this.shouldLoadMore;
      const defaultHandleItems = items => {
        var _this$items, _this$items$splice;
        return (_this$items = this.items) === null || _this$items === void 0 ? void 0 : (_this$items$splice = _this$items.splice) === null || _this$items$splice === void 0 ? void 0 : _this$items$splice.call(_this$items, 0, this.items.length, ...items);
      };
      // @ts-ignore jebo te bog
      const resolvedPagination = (_this$$options$pagina = this.$options.pagination) === null || _this$$options$pagina === void 0 ? void 0 : _this$$options$pagina.call(this);
      if (!resolvedPagination) return;
      const {
        request,
        handleItems
      } = 'then' in resolvedPagination ? await resolvedPagination : resolvedPagination || {};
      if (!request) return;
      const {
        page,
        keywords
      } = this.options;
      const query = request({
        page,
        keywords,
        limit: Number(this.limit)
      }, this.$api);
      const res = await this.callWithStatusUpdate(query);
      if (res.errored) {
        this.isLoadingMore = false;
        this.shouldLoadMore = false;
        return;
      }
      const isWithPagination = data => data && typeof data === 'object' && 'pagination' in data;
      const isPaginated = data => data && typeof data === 'object' && 'totalPages' in data;
      const appendItems = data => [...this.items, ...data];
      const setItems = data => {
        const newItems = this.shouldLoadMore ? appendItems(data) : data;
        defaultHandleItems(newItems);
        handleItems === null || handleItems === void 0 ? void 0 : handleItems(newItems);
      };
      const {
        data
      } = res;
      const {
        setPagination
      } = this;
      if (isWithPagination(data)) {
        setPagination(data.pagination);
        setItems(data.data);
      } else if (isPaginated(data)) {
        setPagination(data);
        setItems(data.records);
      }
      this.isLoadingMore = false;
      this.shouldLoadMore = false;
    },
    // TODO: refactor: accept WithPagination instead of Pagination
    setPagination(data) {
      const isShortPagination = pagination => 'lastPage' in pagination;
      const p = this.paginationData;
      if (isShortPagination(data)) {
        p.totalPages = data.lastPage;
        p.totalItems = data.total;
      } else {
        p.totalPages = data.totalPages;
        p.totalItems = data.totalRecords;
      }
    },
    setPage(number, forceRefetch = false) {
      if (forceRefetch && this.options.page === number) {
        this.fetchDataDebounced();
      } else {
        this.options.page = number;
      }
    },
    nextPage() {
      if (this.paginationState.onLastPage) return;
      this.options.page++;
    },
    previousPage() {
      if (this.paginationState.onFirstPage) return;
      this.options.page--;
    },
    firstPage() {
      this.options.page = 1;
    },
    lastPage() {
      this.paginationState.totalPages;
      this.options.page = this.paginationState.totalPages;
    },
    loadMore() {
      if (this.paginationState.onLastPage || this.isLoadingMore) return;
      this.shouldLoadMore = true;
      this.options.page++;
    }
  },
  render() {
    const slotProps = this.paginatableSlotProps;
    return this.renderContainer(slotProps);
  }
});
export default Paginatable;