// Types
import mixins from "vue-typed-mixins";
// Mixins
import BundleListInjector from '../../mixins/BundleListInjector';
import ControllerBase from '../../mixins/ControllerBase';
import ProductListProvider from '../../mixins/ProductListProvider';
import ProductListInjector from '../../mixins/ProductListInjector';
const ControllerProductList = mixins(ControllerBase, BundleListInjector, ProductListProvider, ProductListInjector).extend({
  name: 'ControllerProductList',
  watch: {
    computedBundle: {
      immediate: true,
      handler(v) {
        v && this.setProducts(v.bundles);
      }
    },
    // TODO: insensible...
    'productListProvider.products': {
      immediate: true,
      handler(v) {
        v && this.setProducts(v);
      }
    }
  },
  render() {
    const slotProps = this.products.map(product => ({
      item: product
    }));
    return this.renderContainer(slotProps);
  }
});
export default ControllerProductList;