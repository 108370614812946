function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import BaseService from './BaseService';
import { StaticElements } from '../enums/StaticElements';
export default class Components extends BaseService {
  constructor(ctx) {
    super('components', {
      elements: [],
      navigationItems: []
    }, ctx);
    _defineProperty(this, "api", void 0);
    _defineProperty(this, "application", void 0);
    _defineProperty(this, "i18n", void 0);
    this.i18n = ctx.app.i18n;
    this.api = ctx.$api;
    this.application = ctx.$application;
  }
  get elements() {
    const elements = {};
    const storedElements = this.storage.elements;
    if (!storedElements) return elements;
    storedElements.forEach(storedElement => elements[storedElement.handler] = storedElement);
    return elements;
  }

  // List of all elements configuration
  // parts, id, title, handler, type, created_at, language_code, meta, mobileMeta, options, mobileOptions
  async fetchElementsConfig() {
    const handles = [StaticElements.Header, StaticElements.Footer, StaticElements.Cookie
    // 'newsletter-section',
    ];

    const response = await this.api.cms.elements.filter(...handles);
    if (response.errored) return response;
    const elements = response.data.elements.map((el, i) => {
      var _this$application$lan;
      return el ? {
        ...el,
        linkMeta: el.meta
      } : {
        linkMeta: {},
        handler: handles[i],
        language_code: (_this$application$lan = this.application.language) === null || _this$application$lan === void 0 ? void 0 : _this$application$lan.code,
        type: 'static-element',
        meta: {}
      };
    });

    // TODO: typing is hc here
    this.setState('elements', elements);
    return response;
  }
  async saveElementsConfig() {
    var _this$storage$element;
    const promises = ((_this$storage$element = this.storage.elements) === null || _this$storage$element === void 0 ? void 0 : _this$storage$element.map(element => this.api.cms.element.create(element)
    // element?.id
    //   ? this.api.cms.element.update(element)
    //   : this.api.cms.element.create(element)
    )) || [];
    const res = await Promise.all(promises);
    this.setState('elements', res.map(r => r.errored ? null : r.data).filter(r => r !== null));
  }
  async fetchMainNavigation() {
    const data = await this.api.cms.navigation.tree('main');
    if (data.errored) return data;
    const n = this.storage.navigationItems;
    n.splice(0, n.length, ...data.data);
    //  this.setState('navigationItems', data.data)
    return data;
  }

  // Fetch dynamic page data
  async fetchPageData(props = {}) {
    const paramList = Object.values(props.params || {}).filter(Boolean);
    const additionalParams = [];
    paramList.filter(param => {
      additionalParams.push(String(param));
    });
    const config = {
      ...props,
      params: additionalParams
    };
    return await this.api.cms.finder.decide(config);
  }
}