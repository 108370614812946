
export default {
  data() {
    return {}
  },
  props: {
    text: {
      type: String,
      default: 'Button',
    },
    type: {
      type: String,
      default: 'text',
    },
    button1: {
      type: Boolean,
      default: true,
    },
  },
}
