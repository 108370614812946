
import Vue from 'vue'
import * as vClickOutside from 'v-click-outside-x'

Vue.use(vClickOutside)
export default {
  data() {
    return {
      langDropdown: false,
      navOpen: false,
      currentBorderPos: 15,
      currentBorderWidth: 115,
    }
  },
  watch: {
    '$route.path': {
      handler: async function (path) {
        if (path === '/') {
          await this.$nextTick()
          if (this.$refs.navigation) {
            const navigation = this.$refs.navigation.$el
            const nodes = Array.from(navigation.querySelectorAll('a'))
            nodes.forEach((node) => {
              if (node.innerText === 'NASLOVNICA') {
                node.classList.add('nuxt-link-active', 'nuxt-link-exact-active')
              }
            })
          }
        } else {
          if (this.$refs.navigation) {
            const navigation = this.$refs.navigation.$el
            const nodes = Array.from(navigation.querySelectorAll('a'))
            nodes.forEach((node) => {
              if (node.innerText === 'NASLOVNICA') {
                node.classList.remove(
                  'nuxt-link-active',
                  'nuxt-link-exact-active'
                )
              }
            })
          }
        }

        if (process.client) {
          setTimeout(() => {
            const activeLink = document.querySelector(
              '.asdasdasdas .nuxt-link-exact-active'
            )
            if (activeLink) {
              const offsetX = activeLink.offsetLeft + 15
              const width = activeLink.offsetWidth - 30
              this.currentBorderWidth = width
              this.currentBorderPos = offsetX
            }

            const nav = document.querySelectorAll('.asdasdasdas a')
            if (nav) {
              nav.forEach((elm) => {
                elm.addEventListener('click', () => {
                  const target = elm
                  const offsetX = target.offsetLeft + 24
                  const width = target.offsetWidth - 50
                  this.currentBorderWidth = width
                  this.currentBorderPos = offsetX
                })
              })
            }
          }, 100)
        }
      },
      immediate: true,
    },
  },
  methods: {
    openLangDropdown() {
      this.langDropdown = !this.langDropdown
    },
    closeNav() {
      this.navOpen = false
      const cb = document.querySelector('#accept')
      cb.checked = false
      document.body.style.overflowY = 'auto'
    },
    onClickOutside() {
      if (window.innerWidth > 1320) {
        this.langDropdown = false
      }
    },
    ifCheckboxChecked() {
      const cb = document.querySelector('#accept')
      if (cb.checked === true) {
        this.navOpen = true
        document.body.style.overflowY = 'hidden'
      } else {
        this.navOpen = false
        document.body.style.overflowY = 'auto'
      }
    },
  },
}
