// Types
import mixins from "vue-typed-mixins";
// Mixins
import ProductsFromIDs from '../../mixins/ProductsFromIDs';
const ControllerCrossSell = mixins(ProductsFromIDs).extend({
  name: 'ControllerCrossSell',
  computed: {
    computedIDs() {
      var _this$productProvider;
      const activeProduct = (_this$productProvider = this.productProvider) === null || _this$productProvider === void 0 ? void 0 : _this$productProvider.activeProduct;
      if (activeProduct && Array.isArray(activeProduct.crossSellProducts)) {
        //@ts-ignore
        return activeProduct.crossSellProducts.map(crossSelledRecord => {
          return typeof crossSelledRecord === 'number' ? crossSelledRecord : crossSelledRecord.id;
        });
      } else if (this.isCartView) {
        return this.reduceIDs();
      } else return [];
    }
  },
  methods: {
    reduceIDs() {
      return this.cartProductRecords.reduce((crossSelledIDs, productRecord) => {
        var _productRecord$crossS;
        (_productRecord$crossS = productRecord.crossSellProducts) === null || _productRecord$crossS === void 0 ? void 0 : _productRecord$crossS.forEach(crossSelledRecord => {
          if (typeof crossSelledRecord === 'number') {
            !crossSelledIDs.includes(crossSelledRecord) && crossSelledIDs.push(crossSelledRecord);
          } else {
            !crossSelledIDs.includes(crossSelledRecord.id) && crossSelledIDs.push(crossSelledRecord.id);
          }
        });
        return crossSelledIDs;
      }, []);
    }
  }
});
export default ControllerCrossSell;