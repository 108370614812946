function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import Vue from 'vue';
import { forEachKey, hasKey } from '../utils/hasKey';
import { animationFrameThrottle } from '../utils/throttling';
class Display {
  constructor() {
    _defineProperty(this, "layout", Vue.observable({
      top: 0,
      bottom: 0
    }));
    _defineProperty(this, "elements", {
      top: [],
      bottom: []
    });
    _defineProperty(this, "recalculate", animationFrameThrottle(() => {
      forEachKey(this.elements, side => {
        this.layout[side] = this.elements[side].reduce((prev, curr) => prev + curr.getBoundingClientRect().height, 0);
      });
    }));
    if (process.browser) {
      window.addEventListener('resize', this.recalculate);
    }
  }
  addToCalculation(props = {}) {
    Object.keys(props).forEach(side => {
      if (!hasKey(this.elements, side)) return;
      const element = props[side];
      element && this.elements[side].push(element);
    });
    this.recalculate();
  }
  removeFromCalculation(props = {}) {
    Object.keys(props).forEach(side => {
      if (!hasKey(this.elements, side)) return;
      const element = props[side];
      const a = this.elements[side];
      element && a.splice(0, a.length, ...a.filter(el => el !== element));
    });
    this.recalculate();
  }
}
export default Display;