function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
// Types

import { formatError } from '../utils/error';
import BaseService from './BaseService';
// Utils
import { bulk } from '../transformators/pageData';
const apiPrefix = 'api';
const stableVersion = 'v1';
const modulePrefix = {
  product: 'product',
  cms: 'cms',
  ecommerce: 'ecommerce',
  media: 'media',
  user: 'user',
  document: 'document',
  settings: 'settings',
  gdpr: 'gdpr',
  marketing: 'marketing',
  calendar: 'calendar'
};

// TODO: I'm not happy with this
const prefixed = url => [apiPrefix, url].join('/');
const versioned = (url, version) => [prefixed(version || stableVersion), url].join('/');
export const moduled = (module, url, version) => [versioned(modulePrefix[module], version), url].join('/');
const isContext = ctx => '$axios' in ctx;
class ApiService extends BaseService {
  constructor(ctx) {
    super('api', {
      headers: {}
    }, isContext(ctx) ? ctx : undefined);
    _defineProperty(this, "axios", void 0);
    _defineProperty(this, "ctx", void 0);
    _defineProperty(this, "master", {
      commons: (type, language) => this.buildRequest({
        url: versioned('commons'),
        method: 'get',
        params: {
          language,
          type
        }
      }),
      domainDetect: Referer => this.buildRequest({
        url: versioned('domainDetect', 'v2'),
        method: 'post',
        headers: {
          Referer,
          customreferer: Referer
        }
      })
    });
    _defineProperty(this, "product", {
      layered: {
        navigation: filters => this.buildRequest({
          url: moduled('product', 'layered/navigation'),
          method: 'post',
          data: {
            filters
          }
        })
      },
      web: {
        get: props => this.buildRequest({
          url: moduled('product', 'web/get'),
          method: 'get',
          params: {
            slug: props.slug,
            id: props.id,
            store: props.storeId,
            currency: props.currency
          }
        }),
        filter: (params = {}) => this.buildRequest({
          url: moduled('product', 'web/filter'),
          method: 'post',
          data: params
        }),
        category: {
          get: slugOrId => this.buildRequest({
            url: moduled('product', `web/category/get/${slugOrId}`),
            method: 'get'
          }),
          filter: params => this.buildRequest({
            url: moduled('product', 'web/category/filter'),
            method: 'post',
            data: params
          })
        },
        tag: {
          filter: params => this.buildRequest({
            url: moduled('product', 'web/tag/filter'),
            method: 'post',
            data: params
          })
        }
      },
      checkBundles: product => this.buildRequest({
        url: moduled('product', 'checkBundles'),
        method: 'get',
        params: {
          product
        }
      })
    });
    _defineProperty(this, "cms", {
      term: {
        // todo: typing
        filter: (props = {}) => this.buildRequest({
          url: moduled('cms', 'term/filter'),
          method: 'get',
          params: {
            taxonomySlugs: props.taxonomySlugs,
            originalTranslation: true,
            limit: props.limit,
            keyword: props.keyword
          }
        }),
        filterStandard: data => this.buildRequest({
          url: moduled('cms', 'term/filterStandard'),
          method: 'post',
          data
        }),
        get: slug => this.buildRequest({
          url: moduled('cms', `term/get/${slug}`),
          method: 'get'
        })
      },
      post: {
        create: data => this.buildRequest({
          url: moduled('cms', 'post/create'),
          method: 'post',
          data
        }),
        filterStandard: data => this.buildRequest({
          url: moduled('cms', 'post/filterStandard'),
          method: 'post',
          data
        })
      },
      postType: {
        create: data => this.buildRequest({
          url: moduled('cms', 'postType/create'),
          method: 'post',
          data
        }),
        filterStandard: (data, params) => this.buildRequest({
          url: moduled('cms', 'postType/filterStandard'),
          method: 'post',
          data,
          params
        }),
        delete: postTypeId => this.buildRequest({
          url: moduled('cms', `postType/delete/${postTypeId}`),
          method: 'delete'
        })
      },
      media: {
        filterStandard: data => this.buildRequest({
          url: moduled('cms', 'media/filterStandard'),
          method: 'post',
          data
        }),
        create: driveId => this.buildRequest({
          url: moduled('cms', 'media/create'),
          method: 'post',
          data: {
            driveId
          }
        }),
        delete: id => this.buildRequest({
          url: moduled('cms', 'media/delete/' + id),
          method: 'delete'
        })
      },
      redirect: {
        addFailedRequest: path => this.buildRequest({
          url: moduled('cms', 'redirect/addFailedRequest'),
          method: 'post',
          data: {
            path
          }
        })
      },
      option: {
        filter: () => this.buildRequest({
          url: moduled('cms', 'option/filter'),
          method: 'get'
        })
      },
      comment: {
        filterStandard: data => this.buildRequest({
          url: moduled('cms', 'comment/filterStandard'),
          method: 'post',
          data
        }),
        create: data => this.buildRequest({
          url: moduled('cms', 'comment/create'),
          method: 'post',
          data
        }),
        update: (commentId, data) => this.buildRequest({
          url: moduled('cms', `comment/update/${commentId}`),
          method: 'patch',
          data
        }),
        options: data => this.buildRequest({
          url: moduled('cms', 'comment/getOptions'),
          method: 'post',
          data
        })
      },
      elements: {
        filter: (...handlers) => this.buildRequest({
          url: moduled('cms', 'element/getByHandlers'),
          method: 'post',
          data: {
            handlers
          }
        })
      },
      element: {
        create: element => this.buildRequest({
          url: moduled('cms', 'element/create'),
          method: 'post',
          data: element
        }),
        update: element => this.buildRequest({
          url: moduled('cms', `element/update/${element.id}`),
          method: 'patch',
          data: element
        })
      },
      navigation: {
        tree: type => this.buildRequest({
          url: moduled('cms', `navigation/tree/${type}`),
          method: 'get'
        })
      },
      finder: {
        decide: props => this.buildRequest({
          url: moduled('cms', 'finder/decide'),
          method: 'get',
          params: {
            originalTranslation: true,
            storeId: props.storeId,
            productId: props.productId,
            categoryId: props.categoryId,
            eventId: props.eventId,
            params: props.params
          }
        }),
        searchSlug: searchString => this.buildRequest({
          url: moduled('cms', 'finder/searchSlugs'),
          method: 'get',
          params: {
            groupByType: true,
            loadProducts: true,
            prefixTranslation: true,
            twoLetterLanguagePrefix: true,
            keyword: searchString
          }
        }),
        getUrlOnLanguage: data => this.buildRequest({
          url: moduled('cms', 'finder/getUrlOnLanguage'),
          method: 'post',
          data
        })
      },
      link: {
        /**
         * Submit page changes for specific language
         * @param props
         * slugMain - page's slugMain;
         * components - list of sections;
         * language_code - three-letter;
         * onlyExisting - bulkExisting or bulk;
         */
        postTranslation: props => this.buildRequest({
          url: moduled('cms', `link/postTranslation/${props.slugMain}/${props.onlyExisting ? 'bulkExisting' : 'bulk'}`),
          method: 'put',
          data: {
            data: bulk(props.components),
            language_code: props.language_code
          }
        }),
        createTranslationFromOriginal: (oldSlug, data) => this.buildRequest({
          url: moduled('cms', `link/${oldSlug}/createTranslationFromOriginal`),
          method: 'post',
          data
        }),
        postType: {
          bulk: (postTypeId, data) => this.buildRequest({
            url: moduled('cms', `link/postType/${postTypeId}/bulk`),
            method: 'put',
            data
          })
        }
      },
      items: {
        /*
        This route is used both for adding and removing from wishlist.
        In case item is added, response will return newly created wishlist item,
        otherwise you just get an empty object. GaussBox for the win.
         */
        create: product_id => this.buildRequest({
          url: moduled('cms', 'wishlist/create'),
          method: 'post',
          data: {
            product_id
          }
        }),
        filter: params => this.buildRequest({
          url: moduled('cms', 'wishlist/filter'),
          method: 'get',
          params
        })
      },
      form: {
        filter: perPage => this.buildRequest({
          url: moduled('cms', 'form/filter'),
          method: 'post',
          params: {
            perPage
          }
        }),
        // todo: deprecate id
        get: slugOrId => this.buildRequest({
          url: moduled('cms', `form/get/${slugOrId}`),
          method: 'get'
        }),
        storeData: data => {
          const {
            id,
            form,
            recaptchaToken
          } = data;
          const body = {
            id,
            data: form
          };
          if (recaptchaToken) {
            body['g-recaptcha-response'] = recaptchaToken;
          }
          return this.buildRequest({
            url: moduled('cms', 'form/storeData'),
            method: 'post',
            data: body
          });
        },
        confirmEmail: token => this.buildRequest({
          url: moduled('cms', `form/confirmEmail/${token}`),
          method: 'get'
        })
      },
      contact: {
        contactAdmin: data => this.buildRequest({
          url: moduled('cms', 'contact/contactAdmin'),
          method: 'post',
          data: {
            ...data,
            'g-recaptcha-response': data.recaptchaToken
          }
        })
      },
      consent: {
        create: data => this.buildRequest({
          url: moduled('cms', 'consent/create'),
          method: 'post',
          data
        }),
        filter: params => this.buildRequest({
          url: moduled('cms', 'consent/filter'),
          method: 'get',
          params
        })
      },
      socialGetters: {
        getIgByHashtags: data => this.buildRequest({
          url: moduled('cms', 'socialGetters/getIgByHashtags'),
          method: 'post',
          data
        })
      },
      pipeline: {
        hasRunningPipeline: () => this.buildRequest({
          url: moduled('cms', 'pipeline/hasRunningPipeline'),
          method: 'get'
        })
      }
    });
    _defineProperty(this, "settings", {
      domain: {
        filter: () => this.buildRequest({
          url: moduled('settings', 'domain/filter'),
          method: 'post'
        })
      },
      general: {
        user: params => this.buildRequest({
          url: moduled('settings', 'general/user'),
          method: 'get',
          params
        })
      }
    });
    _defineProperty(this, "ecommerce", {
      settings: {
        checkout: () => this.buildRequest({
          url: moduled('ecommerce', 'settings/getCheckoutSettings'),
          method: 'get'
        }),
        currency: () => this.buildRequest({
          url: moduled('ecommerce', 'settings/getCurrencyConfig', 'v2'),
          method: 'get'
        })
      },
      checkout: {
        delivery: {
          getApplicableDeliveryMethods: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/delivery/getApplicableDeliveryMethods', 'v2'),
            method: 'post',
            data
          }),
          saveDeliveryMethod: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/delivery/saveDeliveryMethod', 'v2'),
            method: 'put',
            data
          })
        },
        payment: {
          getApplicablePaymentMethods: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/payment/getApplicablePaymentMethods', 'v2'),
            method: 'post',
            data
          }),
          savePaymentMethod: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/payment/savePaymentMethod', 'v2'),
            method: 'put',
            data
          })
        },
        order: {
          create: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/order/create', 'v2'),
            method: 'post',
            data
          }),
          pgSuccess: (orderId, data) => this.buildRequest({
            url: moduled('ecommerce', `checkout/order/pgSuccess/${orderId}`, 'v2'),
            method: 'post',
            data
          })
        },
        cart: {
          getCart: (cartId, protectCode, currencyId) => this.buildRequest({
            url: moduled('ecommerce', 'checkout/cart/getCart', 'v2'),
            method: 'post',
            data: {
              cartId,
              protectCode,
              currencyId
            }
          }),
          getNewCartSession: () => this.buildRequest({
            url: moduled('ecommerce', 'checkout/cart/getNewCartSession', 'v2'),
            method: 'get'
          }),
          getAbandoned: () => this.buildRequest({
            url: moduled('ecommerce', 'checkout/cart/getAbandoned', 'v2'),
            method: 'post'
          }),
          addItem: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/cart/addItem', 'v2'),
            method: 'post',
            data
          }),
          updateItem: props => this.buildRequest({
            url: moduled('ecommerce', 'checkout/cart/updateItem', 'v2'),
            method: 'patch',
            data: {
              ...props,
              cancelToken: undefined
            },
            cancelToken: props.cancelToken
          }),
          deleteItem: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/cart/deleteItem', 'v2'),
            method: 'delete',
            data
          }),
          clearCart: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/cart/clearCart', 'v2'),
            method: 'post',
            data
          }),
          applyCoupon: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/cart/applyCoupon', 'v2'),
            method: 'post',
            data
          }),
          removeCoupon: data => this.buildRequest({
            url: moduled('ecommerce', 'checkout/cart/removeCoupon', 'v2'),
            method: 'post',
            data
          })
        }
      }
    });
    _defineProperty(this, "media", {
      option: {
        filter: params => this.buildRequest({
          url: moduled('media', 'option/filter'),
          method: 'get',
          params
        })
      },
      upload: data => {
        const formData = new FormData();
        formData.append('customData', JSON.stringify([data.customData]));
        formData.append('file', data.file);
        return this.buildRequest({
          url: 'v2/media/upload',
          method: 'post',
          data: formData,
          headers: {
            apiKey: data.apiKey,
            store: undefined
          },
          onUploadProgress: data.onUploadProgress
        });
      },
      directoryItem: {
        delete: driveIds => this.buildRequest({
          url: moduled('media', 'directoryItem/delete'),
          method: 'delete',
          data: {
            targetIds: driveIds
          }
        })
      },
      file: {
        // Same route as getMultipleUploadUrls but here we need to send body for only one media, so response will be one media upload url
        getSingleUploadUrl: data => this.buildRequest({
          url: moduled('media', 'file/getUploadUrl'),
          method: 'post',
          data
        }),
        // Same route as getSingleUploadUrls but here we need to send body for multiple media, so response will be multiple media upload urls
        getMultipleUploadUrls: data => this.buildRequest({
          url: moduled('media', 'file/getUploadUrl'),
          method: 'post',
          data
        }),
        addFromBucketPublic: (filename, path) => this.buildRequest({
          url: moduled('media', 'file/addFromBucketPublic'),
          method: 'post',
          data: {
            filename,
            originalName: filename,
            path,
            directoryId: 'public'
          }
        }),
        // Same route as addFromBucketMultiple, but this one is for single file
        addFromBucketSingle: data => this.buildRequest({
          url: moduled('media', 'file/addFromBucket'),
          method: 'post',
          data: {
            filename: data.filename,
            originalName: data.filename,
            isPublic: data.isPublic,
            path: data.path,
            ...data.customData
          }
        }),
        // Same route as addFromBucketSingle, but this one is for multiple files
        addFromBucketMultiple: data => {
          const body = data.map(addFromBucketReq => ({
            filename: addFromBucketReq.filename,
            originalName: addFromBucketReq.filename,
            isPublic: addFromBucketReq.isPublic,
            path: addFromBucketReq.path,
            ...addFromBucketReq.customData
          }));
          return this.buildRequest({
            url: moduled('media', 'file/addFromBucket'),
            method: 'post',
            data: {
              files: body
            }
          });
        }
      }
    });
    _defineProperty(this, "user", {
      web: {
        register: data => this.buildRequest({
          url: moduled('user', 'web/register'),
          method: 'post',
          data
        }),
        update: data => this.buildRequest({
          url: moduled('user', 'web/update'),
          method: 'patch',
          data
        }),
        address: {
          create: data => this.buildRequest({
            url: moduled('user', 'web/address/create'),
            method: 'post',
            data
          }),
          update: (id, data) => this.buildRequest({
            url: moduled('user', 'web/address/update/' + id),
            method: 'patch',
            data
          })
        }
      },
      password: {
        reset: data => this.buildRequest({
          url: moduled('user', 'password/reset'),
          method: 'post',
          data
        }),
        forgot: data => this.buildRequest({
          url: moduled('user', 'password/forgot'),
          method: 'post',
          data
        }),
        set: data => this.buildRequest({
          url: moduled('user', 'password/set'),
          method: 'post',
          data
        })
      },
      social: {
        redirectLogin: params => this.buildRequest({
          url: moduled('user', 'social/redirect-login'),
          method: 'get',
          params
        }),
        oauth: data => this.buildRequest({
          url: moduled('user', 'social/oauth'),
          method: 'post',
          data
        })
      }
    });
    _defineProperty(this, "document", {
      web: {
        // TODO: typing
        filter: data => this.buildRequest({
          url: moduled('document', 'web/filter'),
          method: 'post',
          data
        }),
        get: params => this.buildRequest({
          url: moduled('document', 'web/get'),
          method: 'get',
          params
        })
      }
    });
    _defineProperty(this, "gdpr", {
      web: {
        consent: {
          filter: data => this.buildRequest({
            url: moduled('gdpr', 'web/consent/filter'),
            method: 'post',
            data
          }),
          log: {
            create: data => this.buildRequest({
              url: moduled('gdpr', 'web/consent/log/create'),
              method: 'post',
              data
            })
          }
        }
      }
    });
    _defineProperty(this, "marketing", {
      newsletter: {
        subscribe: data => this.buildRequest({
          url: moduled('marketing', 'newsletter/subscribe'),
          method: 'post',
          data
        })
      }
    });
    _defineProperty(this, "calendar", {
      event: {
        filter: data => this.buildRequest({
          url: moduled('calendar', 'event/public/filter'),
          method: 'post',
          data
        }),
        get: params => this.buildRequest({
          url: moduled('calendar', 'event/public/get'),
          method: 'get',
          params
        })
      }
    });
    this.axios = isContext(ctx) ? ctx.$axios : ctx;
    this.ctx = isContext(ctx) ? ctx : undefined;

    // If reinitialising service on client, we have to put headers into that new axios instance
    this.setHeaders(this.storage.headers || {});
  }
  extractEndpoint(url) {
    const versionSubstring = url.includes('/v1/') ? '/v1/' : '/v2/';
    const index = url.indexOf(versionSubstring);
    return index !== -1 ? url.substring(index + versionSubstring.length) : url;
  }
  logRequestDuration(reqStartTime, url, success = true) {
    if (!url) return;
    const endpoint = this.extractEndpoint(url);
    const duration = (new Date().getTime() - reqStartTime) / 1000;
    console.log(`${endpoint}: ${duration}s --> ${success ? 'SUCCESS' : 'FAIL'}\n `);
  }
  async buildRequest(config) {
    var _this$ctx;
    const shouldLogRequests =
    // used on client and server
    ((_this$ctx = this.ctx) === null || _this$ctx === void 0 ? void 0 : _this$ctx.$config.logEveryRequest) ||
    // used on server for 3 initial requests before extending plugins with plugin.ts
    process.env.LOG_EVERY_REQUEST === 'true';
    const reqStartTime = new Date().getTime();
    try {
      const res = await this.axios.request(config);
      shouldLogRequests && this.logRequestDuration(reqStartTime, config.url);
      return {
        data: res.data.data,
        errored: false
      };
    } catch (e) {
      shouldLogRequests && this.logRequestDuration(reqStartTime, config.url, false);
      return {
        ...formatError(e),
        errored: true
      };
    }
  }
  setHeaders(newHeaders) {
    this.setState('headers', newHeaders);
    const {
      headers
    } = this.axios.defaults;
    headers.common = headers.common || {};
    Object.assign(headers.common, newHeaders);
  }
}
export default ApiService;