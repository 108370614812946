// Types
import mixins from "vue-typed-mixins";
import { isProductBundleProduct, isProductRecordBundleProduct } from './ControllerBundleCustomization';

// Mixins
import ControllerBase from '../../mixins/ControllerBase';
import { WithPartialInject } from '../../utils/withInject';
import ProductListInjector from '../../mixins/ProductListInjector';
import BaseProvider from '../../mixins/BaseProvider';
import CartModalEvents from '../modals/CartModalEvents';
import BundleCustomizationModalEvents from '../modals/BundleCustomizationModalEvents';

// Enums
import { Modals } from '../../enums/Modals';
import ControllerProductProviderData from '../../providerData/ControllerProductProviderData';
import { CartType } from '../../enums/Cart';
import { ProductFormat } from '../../enums/Product';

// Utils
import consola from 'consola';
import { forEachKey } from '../../utils/hasKey';
const inject = WithPartialInject('product');
const editorInject = WithPartialInject('productListProvider');
const bundleCustomizationInject = WithPartialInject('refreshBundleConfiguration', 'refreshBundleCustomOption');
const ControllerProduct = mixins(ControllerBase, inject, editorInject, ProductListInjector, BaseProvider(ControllerProductProviderData), bundleCustomizationInject, CartModalEvents, BundleCustomizationModalEvents).extend({
  name: 'ControllerProduct',
  provide() {
    return {
      // Provided to ControllerProductConfiguration
      switchProduct: id => {
        this.activeProductId = id || 0;
      },
      // Provided to ControllerProductCustomOptions
      customOptionsForm: this.customOptionsForm,
      setCustomOption: this.setCustomOption
    };
  },
  data() {
    return {
      options: {
        // We are going to allow string here to be lenient, we've seen people
        // use v-model without .number modifier so we actually do get a string
        quantity: 1
      },
      // Used by ControllerProductConfiguration
      activeProductId: 0,
      // Used by ControllerProductCustomOptions
      customOptionsForm: {}
    };
  },
  watch: {
    activeProduct(v) {
      if (v && this.computedProduct) {
        var _this$refreshBundleCo;
        (_this$refreshBundleCo = this.refreshBundleConfiguration) === null || _this$refreshBundleCo === void 0 ? void 0 : _this$refreshBundleCo.call(this, this.computedProduct.id, v);
      }
    }
  },
  computed: {
    /**There is one specific case why we need this
     @param cartItemId: If user navigates from cart to product single page belonging to one product configuration which is NOT VISIBLE INDIVIDUALLY, we have to take him to its 'parent' product single page which is actually configurabile product.
     In such case there will be a query called 'cartItemId' which will allow us to preselect the configuration and custom options and also display proper cartState of that specific configuration
     But we also have to have in mind that this controller is also used out of product single page and also can be used inside product single page but inside lets say ControllerCrossSell.
     In such case, we cant watch cartItemId because those products will have nothing in common with the one to whom the page actually belongs**/
    cartItemId() {
      const {
        cartItemId
      } = this.$route.query;
      return +cartItemId || undefined;
    },
    cartState() {
      const empty = {
        qty: 0
      };
      if (!this.activeProduct) return empty;
      const cartItemFromActiveProduct = this.$cart.findProduct(this.activeProduct);
      if (this.cartItemId) {
        //We have to be careful if we are on product single page with cartItemId query, but we are using some completely other ControllerProduct,
        //lets say we are inside ControllerProduct nested inside ControllerCrossSell inside product page, we still have to watch our active product first instead of query
        const cartItemFromQuery = this.$cart.findItem(this.cartItemId);
        if (cartItemFromQuery && cartItemFromQuery.productId !== this.activeProduct.id) {
          return cartItemFromActiveProduct || empty;
        } else {
          return cartItemFromQuery || empty;
        }
      } else {
        return cartItemFromActiveProduct || empty;
      }
    },
    wishlistState() {
      const wishlisted = !!this.$wishlist.items.find(item => {
        var _this$activeProduct;
        return item.product_id === ((_this$activeProduct = this.activeProduct) === null || _this$activeProduct === void 0 ? void 0 : _this$activeProduct.id);
      });
      return {
        wishlisted
      };
    },
    activeProduct() {
      if (!this.activeProductId) {
        return this.computedProduct;
      } else {
        var _this$computedProduct, _this$computedProduct2;
        const activeProduct = (_this$computedProduct = this.computedProduct) === null || _this$computedProduct === void 0 ? void 0 : (_this$computedProduct2 = _this$computedProduct.associatedProducts) === null || _this$computedProduct2 === void 0 ? void 0 : _this$computedProduct2.find(p => p.id === this.activeProductId);
        return activeProduct || null;
      }
    },
    removableFromCart() {
      return !!(this.activeProduct && this.cartState.itemId && this.cartState.qty);
    },
    /** Only simple products, eProducts and simple bundles WITHOUT PERSONALISATION can immediately be added to cart.
     * If product is configurabile, selecting every single one of its 'associatedProductAttributes' inside ControllerBundleCustomization will set this.activeProduct to one of its 'associatedProducts' which are all simple.
     * However, if bundle contains only single products but some of them contain custom options, even though custom options are not obligatory we have to give user an option to enter them inside ControllerBundleCustomization.
     * Adding bundle to cart is handled inside ControllerBundles because bundle can also contain configurabile products which adds some new cases**/

    // TODO: for now, i cannot know if bundle contains any product with custom options before opening BundleCustomizationModal. So for now, this modal will be opened only in case there is a configurabile product inside Bundle. Modal has to be opened even if there are no configurabile products but there is a product with custom options.
    addableToCart() {
      if (!this.activeProduct) return false;
      const addablePerFormat = {
        [ProductFormat.Simple]: true,
        [ProductFormat.EProduct]: true,
        [ProductFormat.Configurable]: false,
        [ProductFormat.Bundle]: !this.isConfigurabileBundle
      };
      return addablePerFormat[this.activeProduct.format];
    },
    isConfigurabileBundle() {
      if (!this.activeProduct) return false;
      //@ts-ignore
      const {
        format = null,
        bundles = null
      } = this.activeProduct;
      // BundleProduct cant be another Bundle
      // BundleProduct type does not contain 'bundles' array and result for it will always be false, so BundleProduct should never have format of type 'Bundle'
      if (format !== ProductFormat.Bundle || !(bundles !== null && bundles !== void 0 && bundles.length)) return false;
      return !!bundles.find(bundleItem => {
        var _bundleItem$product;
        const bundleItemFormat =
        //@ts-ignore
        (bundleItem === null || bundleItem === void 0 ? void 0 : bundleItem.format) || (bundleItem === null || bundleItem === void 0 ? void 0 : (_bundleItem$product = bundleItem.product) === null || _bundleItem$product === void 0 ? void 0 : _bundleItem$product.format);
        return bundleItemFormat === ProductFormat.Configurable;
      });
    },
    toastEntity() {
      return this.activeProduct && this.activeProduct.format === ProductFormat.Bundle ? this.$t('core.entity.bundle') : this.$t('core.entity.product');
    }
  },
  created() {
    this.$watch('computedProduct', v => this.setItems('mainProduct', v), {
      immediate: true
    });
    // if (!this.computedProduct) return
    // TODO: check if initial quantity should ever be anything but 1
    // this.setInitialQuantity()

    // TODO: if we want to setInitialPersonalisation, that should be used in completely other part of code because personalisation should be initially set only if user clicked on cart item etc
    // this.setInitialPersonalisation()
  },

  methods: {
    setInitialQuantity() {
      if (!this.cartItemId) return;
      this.options.quantity = this.cartState.qty || 1;
    },
    setInitialPersonalisation() {
      if (!this.cartState.options) return;
      const {
        personalizations
      } = this.cartState.options;
      if (!personalizations) return;
      Object.keys(personalizations).forEach(key => {
        this.setCustomOption(key, personalizations[key]);
      });
    },
    parseQuantity(quantity) {
      const parsedQuantity = typeof quantity === 'string' ? parseInt(quantity) : quantity;
      if (isNaN(parsedQuantity) || parsedQuantity <= 0) {
        this.showErrorToast('error.invalidQuantity');
        return;
      }
      return parsedQuantity;
    },
    async addToCart() {
      if (!this.activeProduct) return;
      if (!this.addableToCart) {
        if (this.isConfigurabileBundle) {
          this.showBundleCustomizationModal();
        } else if (this.activeProduct.format === ProductFormat.Configurable) {
          this.showInfoToast('core.notifications.configurationNotSet');
        }
        return;
      }
      const options = {};
      const hasPersonalisation = !!Object.keys(this.customOptionsForm).length;
      if (hasPersonalisation) {
        options.personalizations = this.customOptionsForm;
      }
      const parsedQuantity = this.parseQuantity(this.options.quantity);
      if (!parsedQuantity) return;
      if (this.activeProduct.format === ProductFormat.Bundle) {
        // TODO: 16.11.2021 -> BundleCustomizationModal is not opening if there are no configurabile products. That is actually a bug (If there are only Simple products but one of them has custom options, it still wont open). But for now it has to be like that until backend is done.
        // At this point, there is no chance that this is configurabile bundle anymore, configurabile bundle will be handled by ControllerBundleCustomization inside BundleCustomizationModal

        // Remember, activeProduct can be Product | ProductRecord | BundleProduct.
        // Which means that we are left with Product | ProductRecord which are all Simple products or eProducts, so bundleOptions are filled with SimpleBundledProductOptions objects
        const {
          bundles = null
        } = this.activeProduct;
        if (bundles !== null && bundles !== void 0 && bundles.length) {
          const bundleOptions = [];
          bundles.forEach(bundledProduct => {
            if (isProductBundleProduct(bundledProduct)) {
              bundleOptions.push({
                id: bundledProduct.product,
                options: null
              });
            } else if (isProductRecordBundleProduct(bundledProduct)) {
              bundleOptions.push({
                id: bundledProduct.product.id,
                options: null
              });
            } else return;
          });
          if (bundleOptions.length) {
            options.bundleOptions = bundleOptions;
          }
        }
      }

      // TODO: needs to accept options
      const request = this.$cart.addItem(this.activeProduct, parsedQuantity, options);
      await this.sendRequest({
        request,
        eventName: 'added-to-cart',
        tPath: 'core.notifications.addedToCart'
      });
    },
    /**
     * @deprecated use addToCart instead
     */
    async commitChanges() {
      consola.warn('commitChanges is deprecated, use addToCart only!');
      await this.addToCart();
    },
    async removeFromCart() {
      if (!this.removableFromCart) {
        this.showInfoToast('core.notifications.itemNotInCart');
        return;
      }
      const request = this.$cart.deleteItem(this.cartState.itemId);
      await this.sendRequest({
        request,
        eventName: 'removed-from-cart',
        tPath: 'core.notifications.removedFromCart'
      });
    },
    async addToWishlist() {
      if (!this.activeProduct) return;
      const request = this.$api.cms.items.create(this.activeProduct.id);
      const tPath = this.wishlistState.wishlisted ? 'core.notifications.removedFromWishlist' : 'core.notifications.addedToWishlist';
      await this.sendRequest({
        request,
        eventName: 'added-to-wishlist',
        tPath,
        ignoreCartBehaviour: true,
        ignoreStatusUpdate: true,
        fetchWishlistOnSuccess: true
      });
    },
    async sendRequest(options) {
      const {
        request,
        eventName,
        tPath,
        ignoreStatusUpdate
      } = options;
      const response = await this.callWithStatusUpdate(request, eventName, this.activeProduct, ignoreStatusUpdate);
      if (response.errored) return;
      if (options.fetchWishlistOnSuccess) {
        const wishlist = await this.$wishlist.fetchWishlist({
          limit: 9999
        });
        if (wishlist.errored) return;
      }
      this.showSuccessToast(tPath);
      if (options.ignoreCartBehaviour) return;
      if (this.$application.cartAdditionBehaviour === undefined) {
        await this.$application.fetchCheckoutSettings();
      }
      this.handleCartBehaviour();
    },
    handleCartBehaviour() {
      const {
        cartAdditionBehaviour
      } = this.$application;
      if (cartAdditionBehaviour === CartType.ShowPopup) {
        this.showCartModal();
      } else if (cartAdditionBehaviour === CartType.RedirectToCart) {
        this.$router.push(this.localePath(this.$Page.Cart));
      }
    },
    showSuccessToast(tPath) {
      this.$toast.success(this.$t(tPath, {
        entity: this.toastEntity
      }));
    },
    showErrorToast(tPath) {
      this.$toast.error(this.$t(tPath));
    },
    showInfoToast(tPath) {
      this.$toast.info(this.$t(tPath, {
        entity: this.toastEntity
      }));
    },
    showBundleCustomizationModal() {
      this.$modal.show(Modals.BundleCustomization, {
        selectedBundle: this.activeProduct
      });
    },
    showCartModal() {
      this.$modal.show(Modals.Cart);
    },
    setCustomOption(optionLabel, value) {
      var _this$computedProduct3, _this$refreshBundleCu;
      const id = (_this$computedProduct3 = this.computedProduct) === null || _this$computedProduct3 === void 0 ? void 0 : _this$computedProduct3.id;
      this.$set(this.customOptionsForm, optionLabel, value);
      id && ((_this$refreshBundleCu = this.refreshBundleCustomOption) === null || _this$refreshBundleCu === void 0 ? void 0 : _this$refreshBundleCu.call(this, id, optionLabel, value));
    },
    resetCustomOptions() {
      var _this$computedProduct4;
      const id = (_this$computedProduct4 = this.computedProduct) === null || _this$computedProduct4 === void 0 ? void 0 : _this$computedProduct4.id;
      forEachKey(this.customOptionsForm, key => {
        var _this$refreshBundleCu2;
        this.$set(this.customOptionsForm, key, '');
        id && ((_this$refreshBundleCu2 = this.refreshBundleCustomOption) === null || _this$refreshBundleCu2 === void 0 ? void 0 : _this$refreshBundleCu2.call(this, id, key, ''));
      });
    }
  },
  render() {
    const {
      activeProduct: item,
      options,
      status,
      cartState,
      addToCart,
      commitChanges,
      removeFromCart,
      addToWishlist,
      wishlistState,
      customOptionsForm,
      removableFromCart,
      addableToCart,
      resetCustomOptions
    } = this;
    const slotProps = {
      item,
      options,
      status,
      cartState,
      addToCart,
      commitChanges,
      removeFromCart,
      addToWishlist,
      wishlistState,
      customOptionsForm,
      removableFromCart,
      addableToCart,
      resetCustomOptions
    };
    return this.renderContainer(slotProps, ['item']);
  }
});
export default ControllerProduct;