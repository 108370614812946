// Types
import Vue from 'vue';
// Enums
import { StaticElements } from '../enums/StaticElements';

// Utils
import consola from 'consola';

// Main
const LayoutElementProvider = elementName => Vue.extend({
  provide() {
    return {
      setComponentMeta: this.setComponentMeta,
      componentData: this.componentData,
      sectionName: elementName
    };
  },
  data() {
    return {
      // Computed property was used before, but it didn't work
      // with provide, or rather – it would break on language change
      componentData: {
        created_at: '',
        id: 0,
        linkMeta: {},
        mobileMeta: {},
        mobileOptions: {},
        options: {},
        parts: [],
        title: '',
        handler: StaticElements.Footer,
        language_code: '',
        type: 'static-element',
        meta: {}
      }
    };
  },
  watch: {
    // Refer to comment in data
    '$components.elements': {
      handler() {
        Object.assign(this.componentData, this.$components.elements[elementName]);
      },
      immediate: true
    }
  },
  methods: {
    setComponentMeta(component, key, value) {
      var _this$$application$la;
      const c = this.$components.elements[elementName];
      if (!c) {
        consola.error('Cannot find layout element:', elementName);
        return;
      }
      this.$set(c, 'meta', c.meta || {});
      // TODO: this should not be needed
      this.$set(c, 'language_code', (_this$$application$la = this.$application.language) === null || _this$$application$la === void 0 ? void 0 : _this$$application$la.code);
      this.$set(c.meta, key, value);
    }
  }
});
export default LayoutElementProvider;