// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../mixins/ControllerBase';
import Paginatable from '../../mixins/Paginatable';
import { InjectFrom } from '../../utils/withInject';
import ProductListProvider from '../../mixins/ProductListProvider';
import ProductFilterFieldSelector from '../../mixins/ProductFilterFieldSelector';
const CategoryInject = InjectFrom().keys('category');

// Main
const ControllerFeaturedProducts = mixins(ControllerBase, Paginatable, CategoryInject, ProductListProvider, ProductFilterFieldSelector).extend({
  name: 'ControllerFeaturedProducts',
  pagination() {
    const filters = [{
      key: 'featured',
      value: true
    }];
    if (this.category) {
      filters.push({
        key: 'categories',
        value: this.category.id
      });
    }
    return {
      request: (params, api) => api.product.web.filter({
        ...params,
        filters,
        select: this.computedSelectParam
      }),
      handleItems: items => {
        this.setProducts(items);
      }
    };
  },
  render() {
    const slotProps = this.products.map(product => ({
      item: product // TODO: god damn casting
    }));

    return this.renderContainer(slotProps);
  }
});
export default ControllerFeaturedProducts;