// Types
import Vue from 'vue';
const ProductListProvider = Vue.extend({
  provide() {
    return {
      productListProvider: this
    };
  },
  data() {
    return {
      products: [],
      registeredControllers: [],
      tickets: {}
    };
  },
  methods: {
    registerController(component) {
      const path = [];
      const calcPath = comp => {
        if (comp === this || !comp._uid) return;
        path.push(comp._uid);
        calcPath(comp.$parent);
      };
      calcPath(component);
      this.registeredControllers.push({
        component: component._uid || 0,
        path
      });
      this.calculateTickets();
    },
    unregisterController(component) {
      const {
        _uid
      } = component;
      if (!_uid) return;
      const i = this.registeredControllers.findIndex(controller => controller.component === _uid);
      this.registeredControllers.splice(i, 1);
      this.$delete(this.tickets, _uid);
      this.calculateTickets();
    },
    calculateTickets() {
      // todo: there are visual errors with editor-product-list, fix that once you have time
      // const allowedIds = this.registeredControllers.map((t) => t.component)
      // const filteredChildren = this.$children.filter(
      //   (child: WithUid) =>
      //     child._uid !== undefined && allowedIds.includes(child._uid)
      // )
      this.registeredControllers.forEach(controller => {
        const _uid = controller.component;
        if (!_uid) return;
        const {
          path
        } = controller;
        // const index = filteredChildren.findIndex().. caused undefined computedProduct inside controller-product if controller-product is not direct child of productListPrviders
        const index = this.$children.findIndex(child => child._uid === path[path.length - 1]);
        this.$set(this.tickets, _uid, index);
      });
    },
    setProducts(products, concatenate = false) {
      const p = this.products;
      concatenate ? p.push(...products) : p.splice(0, p.length, ...products);
    }
  }
});
export default ProductListProvider;