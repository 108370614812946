// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../mixins/ControllerBase';
import consola from 'consola';
import VuelidateHelper from '../../mixins/VuelidateHelper';
const AXIOS_CANCEL_MESSAGE = 'canceled';

//Utils
import { required } from 'vuelidate/lib/validators';
const ControllerCart = mixins(ControllerBase, VuelidateHelper).extend({
  name: 'ControllerCart',
  data() {
    return {
      cancelUpdate: null,
      _itemsToUpdate: {},
      form: {
        coupon: ''
      }
    };
  },
  validations: {
    form: {
      coupon: {
        required
      }
    }
  },
  computed: {
    cartItems() {
      return this.$cart.items.map((item, index) => {
        const obj = {
          ...item
        };
        Object.defineProperty(obj, '_qty', {
          value: item.qty,
          enumerable: false,
          writable: true
        });
        Object.defineProperty(obj, 'qty', {
          get() {
            return this._qty;
          },
          set: v => {
            const newVal = Number(v !== null && v !== void 0 ? v : 0);
            this.$cart.items.splice(index, 1, {
              ...obj,
              qty: newVal
            });
            this.initCartItemUpdate(item, newVal);
          }
        });
        return obj;
      });
    },
    priceSummary() {
      var _this$$cart$storage$c;
      const empty = {
        cartTotal: 0,
        coupon: 0,
        delivery: 0,
        itemsTotal: 0,
        loyalty: 0,
        orderTotal: 0,
        tax: {
          cartTotal: 0,
          coupon: 0,
          delivery: 0,
          itemsTotal: 0,
          loyalty: 0,
          orderTotal: 0
        }
      };
      return ((_this$$cart$storage$c = this.$cart.storage.cart) === null || _this$$cart$storage$c === void 0 ? void 0 : _this$$cart$storage$c.prices.converted) || empty;
    }
  },
  methods: {
    cancelCartItemUpdate(itemId) {
      var _itemToUpdate$cancelT;
      const iToU = this.$data._itemsToUpdate;
      const itemToUpdate = iToU[itemId];
      // If there is current timeout for this call, cancel it
      clearTimeout(itemToUpdate === null || itemToUpdate === void 0 ? void 0 : itemToUpdate.timeoutId);
      // If there is api call currently going for this item, cancel it
      itemToUpdate === null || itemToUpdate === void 0 ? void 0 : (_itemToUpdate$cancelT = itemToUpdate.cancelTokenSource) === null || _itemToUpdate$cancelT === void 0 ? void 0 : _itemToUpdate$cancelT.cancel(AXIOS_CANCEL_MESSAGE);
    },
    initCartItemUpdate(item, newQuantity) {
      const {
        itemId
      } = item;
      const iToU = this.$data._itemsToUpdate;
      this.cancelCartItemUpdate(itemId);
      const timeoutId = setTimeout(async () => {
        const returnedValue = await this.updateCartItem(item, newQuantity);
        // If we canceled the call manually, we don't want to remove it
        // from list of itemsToUpdate, because it would delete what we just set
        // for a new call
        returnedValue !== AXIOS_CANCEL_MESSAGE && this.$delete(iToU, itemId);
      }, 500);
      this.$set(iToU, itemId, {
        qty: newQuantity,
        timeoutId,
        cancelTokenSource: this.$axios.CancelToken.source()
      });
    },
    async updateCartItem(item, newQuantity) {
      const {
        itemId
      } = item;
      const iToU = this.$data._itemsToUpdate;
      try {
        var _iToU$itemId, _iToU$itemId$cancelTo;
        await this.$cart.updateItem(itemId, newQuantity, undefined, (_iToU$itemId = iToU[itemId]) === null || _iToU$itemId === void 0 ? void 0 : (_iToU$itemId$cancelTo = _iToU$itemId.cancelTokenSource) === null || _iToU$itemId$cancelTo === void 0 ? void 0 : _iToU$itemId$cancelTo.token, false);
        return '';
      } catch (e) {
        return e.message;
      }
    },
    async deleteCartItem(item) {
      const {
        itemId
      } = item;
      const iToU = this.$data._itemsToUpdate;
      this.cancelCartItemUpdate(itemId);
      this.$set(iToU, itemId, {
        ...iToU[itemId],
        isBeingDeleted: true
      });
      try {
        const response = await this.$cart.deleteItem(itemId);
        !response.errored && this.$toast.success(this.$t('core.notifications.itemRemovedFromCart'));
      } catch (e) {
        this.$toast.error(this.$t('error.removeFromCartFailed'));
      } finally {
        this.$delete(iToU, itemId);
      }
    },
    async applyCoupon() {
      if (!this.$cart.session) {
        consola.error('Cart session not initialised!');
        return;
      }
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      const request = this.$cart.applyCoupon(this.form.coupon);
      const res = await this.callWithStatusUpdate(request);
      if (res.errored || !res.data.coupon) {
        this.$emit('coupon-error');
        return;
      }
      this.$emit('coupon-applied');
    },
    async removeCoupon() {
      if (!this.$cart.session) {
        consola.error('Cart session not initialised!');
        return;
      }
      const request = this.$cart.removeCoupon();
      const res = await this.callWithStatusUpdate(request, 'coupon-removed');
      if (res.errored) return;
    }
  },
  render() {
    const {
      deleteCartItem,
      priceSummary,
      form,
      applyCoupon,
      removeCoupon,
      $v,
      generateErrorMessage
    } = this;
    return this.renderContainer({
      items: this.cartItems,
      deleteCartItem,
      priceSummary,
      form,
      applyCoupon,
      cartState: this.$cart.storage.cart || {},
      removeCoupon,
      $v,
      generateErrorMessage
    });
  }
});
export default ControllerCart;