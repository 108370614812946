// Types
import mixins from "vue-typed-mixins";
// Components
import { HeaderElement } from '../../overridableComponents';

// Mixins
import EditorContainer from '../../../mixins/EditorContainer';
import LayoutElementProvider from '../../../mixins/LayoutElementProvide';

// Enums
import { StaticElements } from '../../../enums/StaticElements';

// Main
const HeaderContainer = mixins(EditorContainer, LayoutElementProvider(StaticElements.Header)).extend({
  name: 'HeaderContainer',
  render() {
    const h = arguments[0];
    return h(HeaderElement);
  }
});
export default HeaderContainer;