import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
// Types
import mixins from "vue-typed-mixins"; // Enums
import { Modals } from '../../enums/Modals';

// Utils
import { typedModal } from '../../utils/typedModals';
import axios from 'axios';
import AdminGlassPanel from '../ui/AdminGlassPanel';
import AdminInsetGlassPanel from '../ui/AdminInsetGlassPanel';
export const ReleasesEvents = typedModal();
const Releases = mixins(ReleasesEvents).extend({
  data() {
    return {
      loading: false,
      releases: []
    };
  },
  methods: {
    async beforeOpen(props) {
      if (props) {
        this.releases = props.params.releases;
        return;
      }
      this.loading = true;
      await this.fetchReleases();
      this.loading = false;
    },
    async fetchReleases() {
      var _this$$application$fi;
      const gitlabProjectId = (_this$$application$fi = this.$application.findCmsOption('GitlabProjectId')) === null || _this$$application$fi === void 0 ? void 0 : _this$$application$fi.value;
      if (!gitlabProjectId) return;
      try {
        const {
          data: latestRelease
        } = await axios.request({
          method: 'post',
          url: '/_cli/get-latest-release',
          data: {
            gitlabProjectId
          }
        });
        if (!Array.isArray(latestRelease)) return;
        this.releases = latestRelease;
      } catch (e) {
        this.$toast.error(e.message);
      }
    }
  },
  render() {
    const h = arguments[0];
    const title = this.$isDevMode ? 'Ovo je vaše djelo' : this.$t('core.modals.changelog');
    return h("modal", _mergeJSXProps([{
      "attrs": {
        "name": Modals.Releases,
        "id": Modals.Releases,
        "height": '60%'
      }
    }, {
      "on": {
        'before-open': this.beforeOpen
      }
    }, {
      "attrs": {
        "styles": {
          background: 'transparent',
          borderRadius: '11px'
        }
      }
    }]), [h(AdminGlassPanel, {
      "attrs": {
        "light": true
      },
      "class": "h-full flex flex-col"
    }, [h("h3", {
      "class": 'mb-4'
    }, [title]), h(AdminInsetGlassPanel, {
      "attrs": {
        "contentTag": 'ul',
        "title": this.$application.domain.site.name,
        "contentClass": "overflow-auto divide-y",
        "contentPadding": 'px-2'
      },
      "class": 'flex-1 min-h-0'
    }, [this.loading ? h("li", {
      "class": 'h-full w-full flex items-center justify-center'
    }, ["Loading..."]) : this.releases.map(release => h("li", {
      "class": 'p-2',
      "domProps": {
        "innerHTML": release.description_html
      }
    }))])])]);
  }
});
export default Releases;