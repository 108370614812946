// Types
import mixins from "vue-typed-mixins"; // Mixins
import ControllerBase from '../../../mixins/ControllerBase';
import { WithPartialInject } from '../../../utils/withInject';
import Consentable from '../../../mixins/Consentable';
const checkoutInject = WithPartialInject('delivery', 'billing', 'stepState', 'goToPreviousStep', 'companyState');

// Utils
import consola from 'consola';

// Enums
import { AddressType, CheckoutSteps } from '../../../enums/Checkout';
import { FormWithConsent } from '../../../enums/Gdpr';
const ControllerPayment = mixins(ControllerBase, Consentable(FormWithConsent.Checkout), checkoutInject).extend({
  name: 'ControllerPayment',
  data() {
    var _this$$cart$storage$c;
    return {
      paymentMethods: [],
      form: {
        methodId: ((_this$$cart$storage$c = this.$cart.storage.cart) === null || _this$$cart$storage$c === void 0 ? void 0 : _this$$cart$storage$c.paymentMethodId) || 0
      },
      lastSavedMethod: 0
    };
  },
  // TODO: CELERO CUSTOMIZATION 1. For now, there is no backend for me to retrieve the PayPal planConfig for CeleroOne PayPal subscriptions, so i had to hardcode PayPal plan configuration if user selected Germany as his country and if prop asCelero is true. This prop is obviously not documented, and once backend for planConfigs is done, remove this.
  props: {
    asCelero: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isStepActive() {
      return this.stepState.activeStep === CheckoutSteps.Payment;
    },
    user() {
      return this.$auth.user || this.$cart.guestUser;
    },
    // If user saves his addresses, goes to next step and then closes checkout, his billing and delivery addresses will be saved, but companyOIB and companyName cannot be saved, they have to be set explicity every time user enters checkout page.
    // We have to stop saving payment method inside created() if as-company prop was used inside controller-address but companyName and companyOIB were not set
    companyDataValid() {
      var _this$companyState;
      if (!((_this$companyState = this.companyState) !== null && _this$companyState !== void 0 && _this$companyState.isCompany)) return true;
      // If useDifferentBillingAddress === false inside ControllerAddress, billingAddress was previously set to shipping address anyways, so we can safely destruct only from billing address
      const {
        companyName,
        companyOIB
      } = this.billing;
      return !!companyName && !!companyOIB;
    }
  },
  watch: {
    // 'form.methodId': 'savePaymentMethod',
    // '$cart.storage.cart.deliveryMethodId': 'getApplicablePaymentMethods',
    'user.id': 'getApplicablePaymentMethods',
    isStepActive: {
      async handler(v) {
        v && (await this.getApplicablePaymentMethods());
      }
    },
    consentsFetched: {
      async handler(v) {
        v && (await this.getApplicablePaymentMethods());
      }
    }
  },
  methods: {
    async getApplicablePaymentMethods() {
      var _this$$cart$storage$c2;
      if (!this.consentsFetched || !this.isStepActive) return;

      // TODO: CELERO CUSTOMIZATION 2. This condition for reseting lastSavedMethod was also done explicitly for Celero. Dont forget to remove this condition when plan configs are done on backend. Without this, if user submits his address data, he goes to payment and inside savePaymentMethod submit will be called immediately, order will be created and PayPal config will be set. The problem occurs if user goes back to addresses step, changes country and then goes to payment. His old paypal config will be used which is bad since i have to send planConfig if selected country is Germany.
      if (this.asCelero) {
        this.lastSavedMethod = 0;
      }
      if (!this.$cart.session) {
        consola.error('Cart session not initialised!');
        return;
      }
      if (!this.$cart.isEProductCart && !((_this$$cart$storage$c2 = this.$cart.storage.cart) !== null && _this$$cart$storage$c2 !== void 0 && _this$$cart$storage$c2.deliveryMethodId)) return;
      const {
        user
      } = this;
      if (!user || !user.id || !user.addresses) return;
      const findAddress = type => user.addresses.find(address => address.type === type);
      const billingAddress = findAddress(AddressType.Main);
      const shippingAddress = findAddress(AddressType.Shipping);
      if (!(billingAddress !== null && billingAddress !== void 0 && billingAddress.id) || !(shippingAddress !== null && shippingAddress !== void 0 && shippingAddress.id)) return;
      const body = {
        userId: user.id,
        cartId: this.$cart.session.id,
        protectCode: this.$cart.session.protectCode,
        userBillingAddressId: billingAddress.id
      };
      const {
        payment
      } = this.$api.ecommerce.checkout;
      const res = await payment.getApplicablePaymentMethods(body);
      const p = this.paymentMethods;
      if (res.errored) {
        p.splice(0);
        return;
      }
      p.splice(0, p.length, ...res.data);
      const skip = this.checkIfStepShouldBeSkipped();
      if (!skip) return;
      this.form.methodId = this.paymentMethods[0].id;
      await this.savePaymentMethod(this.form.methodId, this.lastSavedMethod);
    },
    checkIfStepShouldBeSkipped() {
      //Do not always instantly call savePaymentMethod because companyName and companyOIB have to be set explicitly each time user enters checkout page
      if (!this.companyDataValid) return false;
      if (this.paymentMethods.length !== 1) return false;
      return this.paymentMethods[0].paymentType === 'HPF';
    },
    async savePaymentMethod(newId = 0, oldId = 0) {
      var _this$user;
      if (newId === this.lastSavedMethod) return;
      if (!this.$cart.session) {
        consola.error('Cart session not initialised!');
        return;
      }
      const userId = (_this$user = this.user) === null || _this$user === void 0 ? void 0 : _this$user.id;
      if (!userId) return;
      const payload = {
        userId,
        cartId: this.$cart.session.id,
        protectCode: this.$cart.session.protectCode,
        paymentMethodId: newId
      };
      if (this.companyDataValid) {
        const {
          companyName,
          companyOIB: oib
        } = this.billing;
        // @ts-ignore
        payload.companyData = {
          companyName,
          oib
        };
      }
      const res = await this.callWithStatusUpdate(this.$api.ecommerce.checkout.payment.savePaymentMethod(payload));
      if (res.errored) {
        this.form.methodId = oldId;
        return;
      }
      this.lastSavedMethod = newId;
      const skip = this.checkIfStepShouldBeSkipped();
      if (!skip) return;
      await this.submit();
    },
    async submit() {
      var _this$user2, _this$user3;
      const consentsValid = await this.validateConsents((_this$user2 = this.user) === null || _this$user2 === void 0 ? void 0 : _this$user2.email);
      if (!consentsValid) return;
      if (!this.form.methodId) {
        this.$toast.error(this.$t('error.paymentMethodUndefined'));
        return;
      }
      await this.savePaymentMethod(this.form.methodId, this.lastSavedMethod);
      if (!this.$cart.session) {
        consola.error('Cart session not initialised!');
        return;
      }
      const userId = (_this$user3 = this.user) === null || _this$user3 === void 0 ? void 0 : _this$user3.id;
      if (!userId) return;
      const {
        origin
      } = window.location;
      const failureURL = origin + this.localePath('/checkout/failure');
      const successURL = origin + this.localePath(this.$Page.CheckoutSuccess);
      const res = await this.$api.ecommerce.checkout.order.create({
        userId,
        cartId: this.$cart.session.id,
        protectCode: this.$cart.session.protectCode,
        failureURL,
        successURL
      });
      if (res.errored) return;
      const activeMethod = this.paymentMethods.find(method => method.id === this.form.methodId);

      /*
      @Ivan Gašparović
      Imamo 3 vrste metoda:
        HPP -> Saznas kroz methodCode abriviacija znaci "Hosted payment page" znaci ide redirect na payment metodu i oni onda redirectaju na success
        HPF -> isto methodCode abriviacija je "Hosted payment form" znaci mi hostamo formu njihovu na nasoj stranici i mi redirectamo na success
        Bez online paymenta -> kupac placa gotovinom -> direktno ide na success page
       */

      const {
        html
      } = res.data;
      if (typeof html !== 'string') {
        this.handlePaypal(html);
      } else if (!(activeMethod !== null && activeMethod !== void 0 && activeMethod.paymentType)) {
        this.$emit('submitted');
        this.$cart.clearSession();
        this.$router.push({
          path: this.localePath(this.$Page.CheckoutSuccess),
          query: {
            orderSlug: res.data.orderSlug
          }
        });
      } else {
        const c = document.createElement('div');
        c.innerHTML = html;
        const form = c.querySelector('form');
        if (!form) return;
        document.body.appendChild(form);
        form.submit();
        // TODO: KAKO OVDJE UBACITI SUBMITTED?
      }
    },

    handlePaypal(html) {
      const options = html.isSubscription ? {
        // Set up the transaction
        createSubscription: (data, actions) => {
          const {
            plan_id,
            quantity
          } = html;
          const subscriptionOptions = {
            plan_id
          };
          if (quantity) {
            subscriptionOptions.quantity = quantity;
          }

          // TODO: CELERO CUSTOMIZATION 3.
          const temporaryCeleroOnePlan = {
            taxes: {
              percentage: '0',
              inclusive: false
            }
          };
          if (this.asCelero && this.billing.country !== 'DE') {
            subscriptionOptions.plan = temporaryCeleroOnePlan;
          }
          return actions.subscription.create(subscriptionOptions);
        },
        // Finalize the transaction
        onApprove: async data => {
          const res = await this.$api.ecommerce.checkout.order.pgSuccess(html.orderId, {
            details: data,
            internalRedirect: true
          });
          if (res.errored) return;
          this.$emit('submitted');
          this.$cart.clearSession();
          const {
            url
          } = res.data;
          const urlWithoutProtocol = url.replace(/https*:\/\//, '');
          const urlWithoutDomain = '/' + urlWithoutProtocol.split('/').splice(1).join('/');
          this.$router.push(urlWithoutDomain);
        }
      } : {
        // Set up the transaction
        createOrder: (data, actions) => actions.order.create({
          purchase_units: [{
            amount: {
              value: html.total
            }
          }]
        }),
        // Finalize the transaction
        onApprove: (data, actions) => actions.order.capture().then(async details => {
          const res = await this.$api.ecommerce.checkout.order.pgSuccess(html.orderId, {
            details,
            internalRedirect: true
          });
          if (res.errored) return;
          this.$emit('submitted');
          this.$cart.clearSession();
          const {
            url
          } = res.data;
          const urlWithoutProtocol = url.replace(/https*:\/\//, '');
          const urlWithoutDomain = '/' + urlWithoutProtocol.split('/').splice(1).join('/');
          this.$router.push(urlWithoutDomain);
        })
      };
      const script = document.createElement('script');
      document.body.appendChild(script);
      script.onload = () => {
        // Render the PayPal button into #paypal-button-container
        // @ts-ignore
        const pp = paypal;
        pp.Buttons(options).render('#paypal-button-container');
      };
      script.src = html.scriptUrl;
    }
  },
  render() {
    const {
      paymentMethods,
      form,
      status,
      submit,
      isStepActive,
      goToPreviousStep,
      assignedConsents: consents
    } = this;
    const slotProps = {
      paymentMethods,
      form,
      status,
      submit,
      isStepActive,
      goToPreviousStep,
      consents
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerPayment;