// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Sans/OpenSans-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Sans/OpenSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Sans/OpenSans-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Sans/OpenSans-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Sans/OpenSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./Sans/OpenSans-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./Cormorant/CormorantUpright-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./Cormorant/CormorantUpright-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./Cormorant/CormorantUpright-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./Cormorant/CormorantUpright-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./Cormorant/CormorantUpright-Bold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:300;font-display:swap;font-style:normal}@font-face{font-family:\"Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:400;font-display:swap;font-style:normal}@font-face{font-family:\"Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:500;font-display:swap;font-style:normal}@font-face{font-family:\"Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:600;font-display:swap;font-style:normal}@font-face{font-family:\"Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");font-weight:700;font-display:swap;font-style:normal}@font-face{font-family:\"Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:800;font-display:swap;font-style:normal}@font-face{font-family:\"Cormorant\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"truetype\");font-weight:300;font-display:swap;font-style:normal}@font-face{font-family:\"Cormorant\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"truetype\");font-weight:400;font-display:swap;font-style:normal}@font-face{font-family:\"Cormorant\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"truetype\");font-weight:500;font-display:swap;font-style:normal}@font-face{font-family:\"Cormorant\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"truetype\");font-weight:600;font-display:swap;font-style:normal}@font-face{font-family:\"Cormorant\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"truetype\");font-weight:700;font-display:swap;font-style:normal}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
