// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../mixins/ControllerBase';
import BaseInjector from '../../mixins/BaseInjector';
import ControllerProductProviderData from '../../providerData/ControllerProductProviderData';
const CustomOptionsFormInject = InjectFrom().keys('customOptionsForm', 'setCustomOption');

// Enums
import { ProductCustomOptionType } from '../../enums/Product';
import { InjectFrom } from '../../utils/withInject';

// Main
const ControllerProductCustomOptions = mixins(ControllerBase, BaseInjector(ControllerProductProviderData), CustomOptionsFormInject).extend({
  computed: {
    customOptions() {
      var _this$productProvider, _this$productProvider2;
      // activeProduct injected From ControllerProduct: Product | ProductRecord | BundleProduct | null
      // Ts is complaining since BundleProduct cannot contain custom options. This will work fine. Start begging backend to give you custom options inside BundleProduct too.
      // @ts-ignore
      return (_this$productProvider = this.productProvider) === null || _this$productProvider === void 0 ? void 0 : (_this$productProvider2 = _this$productProvider.activeProduct) === null || _this$productProvider2 === void 0 ? void 0 : _this$productProvider2.customOptions;
    }
  },
  methods: {
    genTextSlot(option) {
      const {
        text
      } = this.$scopedSlots;
      if (!text) return this.genUnusedSlot('text');
      const {
        customOptionsForm,
        setCustomOption
      } = this;
      if (!customOptionsForm || !setCustomOption) return;
      const getValue = optionLabel => customOptionsForm[optionLabel];
      const setValue = (optionLabel, value) => setCustomOption(optionLabel, value);
      const {
        label
      } = option.custom_option_values[0];
      return text({
        option: {
          label: option.custom_option_values[0].label,
          get value() {
            return getValue(label);
          },
          set value(v) {
            setValue(label, v);
          }
        }
      });
    },
    genNumberSlot(option) {
      const {
        number
      } = this.$scopedSlots;
      if (!number) return this.genUnusedSlot('number');
      const {
        customOptionsForm,
        setCustomOption
      } = this;
      if (!customOptionsForm || !setCustomOption) return;
      const getValue = optionLabel => customOptionsForm[optionLabel];
      const setValue = (optionLabel, value) => setCustomOption(optionLabel, value);
      const {
        label
      } = option.custom_option_values[0];
      return number({
        option: {
          label: option.custom_option_values[0].label,
          get value() {
            return getValue(label);
          },
          set value(v) {
            setValue(label, v);
          }
        }
      });
    },
    genUnusedSlot(type) {
      const h = this.$createElement;
      return [h("div", {
        "class": 'bg-red-600'
      }, ["Nije postavljen slot \"", type, "\""])];
    }
  },
  render() {
    const h = arguments[0];
    const {
      customOptions
    } = this;
    const slotProps = {
      items: customOptions
    };
    return this.$scopedSlots.default || !customOptions ? this.renderContainer(slotProps, ['items']) : this.createModularContainer(customOptions.map(option => {
      const errorContainer = this.$isDevMode ? h("div", {
        "class": 'bg-red-600'
      }, ["Nije prepoznata opcija tipa \"", option.option_type, "\""]) : undefined;
      switch (option.option_type) {
        case ProductCustomOptionType.Text:
          return this.genTextSlot(option);
        case ProductCustomOptionType.Number:
          return this.genNumberSlot(option);
        default:
          return errorContainer;
      }
    }));
  }
});
export default ControllerProductCustomOptions;