// @ts-ignore
const dummyProduct = {
  name: 'Dummy Product'
};

// @ts-ignore
const dummyProductRecord = {
  name: 'Dummy Product'
};

// @ts-ignore
const dummyBundleProduct = {
  name: 'Dummy Bundle Product'
};
export { dummyProduct, dummyProductRecord, dummyBundleProduct };