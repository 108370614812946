// Types
import mixins from "vue-typed-mixins";
import { Page } from '@gauss/cms-shared';
// Enums
import { Terms } from '../../enums/Terms';

// Mixins
import Paginatable from '../../mixins/Paginatable';
import { WithPartialInject } from '../../utils/withInject';
import ProductListProvider from '../../mixins/ProductListProvider';
import ProductFilterFieldSelector from '../../mixins/ProductFilterFieldSelector';
import Debouncable from '../../mixins/Debouncable';
const SearchInject = WithPartialInject('isInsideSearchPage');
const PERFORM_SEARCH_EVENT = 'perform-search';
function genCategories() {
  var _this$$moduleOptions, _this$$moduleOptions$;
  if ((_this$$moduleOptions = this.$moduleOptions) !== null && _this$$moduleOptions !== void 0 && (_this$$moduleOptions$ = _this$$moduleOptions.searchCategories) !== null && _this$$moduleOptions$ !== void 0 && _this$$moduleOptions$.length) {
    return this.$moduleOptions.searchCategories.map(category => ({
      slug: category,
      name: this.$t('core.term.' + category)
    }));
  } else {
    return [{
      slug: Terms.Pages,
      name: this.$t('core.term.pages')
    }, {
      slug: Terms.Products,
      name: this.$t('core.term.products')
    }, {
      slug: Terms.Posts,
      name: this.$t('core.term.posts')
    }, {
      slug: Terms.ProductCategories,
      name: this.$t('core.term.productCategories')
    }];
  }
}
// Main
const ControllerSearch = mixins(Paginatable, SearchInject, ProductListProvider, ProductFilterFieldSelector, Debouncable({
  items: 500,
  query: 500,
  activeCategory: 500
})).extend({
  name: 'ControllerSearch',
  data() {
    const categories = genCategories.call(this);
    const {
      query
    } = this.$route;
    const keywords = query.search || '';
    const page = query.page || 1;
    const activeCategory = query.category || categories[0].slug;
    return {
      categories,
      options: {
        page,
        activeCategory,
        keywords
      }
    };
  },
  created() {
    if (!this.computedForm.activeCategory) {
      throw this.$t('error.missingSearchCategories');
    }
  },
  mounted() {
    if (this.isInsideSearchPage && this.searchPageOpened) {
      this.$nuxt.$on(PERFORM_SEARCH_EVENT, this.onPerformSearch);
    }
  },
  beforeDestroy() {
    if (this.isInsideSearchPage && this.searchPageOpened) {
      this.$nuxt.$off(PERFORM_SEARCH_EVENT, this.onPerformSearch);
    }
  },
  pagination() {
    const {
      activeCategory
    } = this.computedForm;
    return {
      request: (params, api) => this.genRequest(activeCategory, params),
      handleItems: items => {
        if (activeCategory === Terms.Products) {
          this.setProducts(items);
        } else {
          this.setProducts([]);
        }
      }
    };
  },
  computed: {
    computedForm() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const {
        options,
        status,
        refreshQueryParamsDebounced,
        fetchDataDebounced,
        debounce
      } = this;
      return {
        get page() {
          return options.page;
        },
        set page(page) {
          if (options.page === page) return;
          options.page = page;
          // Setting page inside Paginatable results in data fetching, that line is not missing
          refreshQueryParamsDebounced();
        },
        get search() {
          return options.keywords;
        },
        set search(search) {
          if (options.keywords === search) {
            return;
          } else {
            options.keywords = search;
            // Watcher inside Paginatable resets page automatically, that line is not missing
            // Setting page inside Paginatable results in data fetching, so that is not missing either
            refreshQueryParamsDebounced();
          }
        },
        get activeCategory() {
          return options.activeCategory;
        },
        set activeCategory(category) {
          debounce('activeCategory', () => {
            if (status.pending || options.activeCategory === category) {
              return;
            } else {
              options.activeCategory = category;
              options.page = 1;
              fetchDataDebounced();
              refreshQueryParamsDebounced();
            }
          });
        }
      };
    },
    // ControllerSearch can be used both inside PageSearch and HeaderElement,
    // that is why we need to make 2 ControllerSearch components work together on SearchPage
    // which affects submit function
    searchPageOpened() {
      var _this$$route$name;
      return ((_this$$route$name = this.$route.name) === null || _this$$route$name === void 0 ? void 0 : _this$$route$name.split('_')[0]) === this.$Page.Search;
    }
  },
  methods: {
    setActiveCategory(category) {
      if (!category) return;
      this.computedForm.activeCategory = category.slug;
    },
    onPerformSearch({
      search,
      category,
      page
    }) {
      this.options.activeCategory = category;
      this.options.keywords = search || '';
      this.options.page = page;
      this.fetchDataDebounced();
      this.refreshQueryParamsDebounced();
    },
    genRequest(activeCategory, params) {
      const requestPerActiveCategory = {
        [Terms.Pages]: this.fetchPages,
        [Terms.Products]: this.fetchProducts,
        [Terms.Posts]: this.fetchPosts,
        [Terms.ProductCategories]: this.fetchProductCategories
      };
      const request = requestPerActiveCategory[activeCategory];
      if (!request) {
        throw "requestPerActiveCategory didn't find appropriate request";
      } else {
        return request(params);
      }
    },
    fetchPages(params) {
      const promise = this.$api.cms.post.filterStandard({
        filters: [{
          key: 'display',
          value: 'components'
        }],
        ...params
      });
      return promise;
    },
    fetchProducts(params) {
      const promise = this.$api.product.web.filter({
        filters: [{
          key: 'archived',
          value: false,
          default: true
        }],
        select: this.computedSelectParam,
        ...params
      });
      return promise;
    },
    fetchPosts(params) {
      const promise = this.$api.cms.post.filterStandard({
        filters: [{
          key: 'display',
          value: 'legacy'
        }, {
          key: 'postTypeSlugs',
          value: ['post']
        }],
        ...params
      });
      return promise;
    },
    fetchProductCategories(params) {
      const promise = this.$api.product.web.category.filter({
        filters: [],
        sort: {
          param: 'id',
          order: 'asc'
        },
        tree: true,
        ...params
      });
      return promise;
    },
    refreshQueryParamsDebounced() {
      this.debounce('query', this.refreshQueryParams);
    },
    async refreshQueryParams() {
      if (!this.isInsideSearchPage) return;
      const {
        search,
        activeCategory: category,
        page
      } = this.computedForm;
      const query = {
        category,
        page
      };
      search && (query.search = search);
      try {
        await this.$router.replace({
          // @ts-ignore
          query
        });
      } catch (e) {
        console.error(this.$t('error.redundantNavigationSkipped'));
      }
    },
    async submit() {
      if (this.isInsideSearchPage) return;
      const {
        search,
        activeCategory: category
      } = this.computedForm;
      const page = this.options.page;
      const searchParams = {
        category,
        page
      };
      search && (searchParams.search = search);
      if (this.searchPageOpened) {
        this.$nuxt.$emit(PERFORM_SEARCH_EVENT, searchParams);
      } else {
        try {
          await this.$router.push(this.localePath({
            name: Page.Search,
            // @ts-ignore
            query: searchParams
          }));
        } catch (e) {
          console.error(this.$t('error.redundantNavigationSkipped'));
        }
      }
    }
  },
  render() {
    const {
      computedForm: form,
      pageNumbers,
      categories,
      setActiveCategory,
      submit
    } = this;
    const slotProps = {
      ...this.paginatableSlotProps,
      form,
      pageNumbers,
      categories,
      setActiveCategory,
      submit
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerSearch;