import Vue from 'vue';
import mixins from "vue-typed-mixins";
function InjectFrom() {
  function keys(...keys) {
    const fakeData = () => ({});
    return mixins().extend({
      data() {
        return fakeData();
      },
      inject: keys
    });
  }
  return {
    keys
  };
}
function InjectFromTwo() {
  function keys(...keys) {
    const fakeData = () => ({});
    return mixins().extend({
      data() {
        return fakeData();
      },
      inject: keys
    });
  }
  return {
    keys
  };
}

// TODO: typing is not great
const WithPartialInject = (...inject) => {
  const obj = {};
  const def = {
    default: null
  };
  inject.forEach(i => obj[i] = def);
  return Vue.extend({
    inject: obj
  });
};
export { InjectFrom, InjectFromTwo, WithPartialInject };