import { hasKey, mapEachKey } from './utils/hasKey';
import { formatError } from './utils/error';
import consola from 'consola';

// Services
import Components from './services/Components';
import Application from './services/Application';
import CartService from './services/Cart';
import ApiService from './services/ApiService';
import Display from './services/cmsDisplay';
import Wishlist from './services/Wishlist';

// Types

const CorePlugin = async (ctx, inject) => {
  // if (ctx.route.name === 'admin-login') {
  //   return
  // }
  if (!ctx.$config.forceDomain && ctx.isDev) {
    inject('api', new ApiService(ctx));
    ctx.redirect('/adminLogin');
    return;
  }
  inject('isDevMode', ctx.isDev);
  const {
    gitlabBranch
  } = ctx.$config;
  inject('isProduction', !!gitlabBranch && !['debug', 'test'].includes(gitlabBranch));
  const services = {
    api: ApiService,
    // Has to be first, other services use it
    display: Display,
    application: Application,
    components: Components,
    cart: CartService,
    wishlist: Wishlist
  };
  injectServices(services, inject, ctx);
  setAxiosInterceptor(ctx);
  await fetchAllOptions(ctx);
  if (ctx.$auth.loggedIn && ctx.$auth.user.isBackofficeUser) {
    await ctx.$application.loadAdminLocale();
  }
  ctx.app.i18n.onLanguageSwitched = () => {
    ctx.$components.fetchElementsConfig();
    ctx.$application.fetchCurrencyConfig();
    ctx.$application.fetchCountries(), ctx.$cart.clearSession();
  };
  const tag = ctx.$application.findCmsOption('GoogleTagManagerIdentifier');
  // @ts-expect-error $gtm nije definiran
  (tag === null || tag === void 0 ? void 0 : tag.value) && ctx.$gtm.init(tag.value);
};
export default CorePlugin;
function injectServices(services, inject, ctx) {
  Object.keys(services).forEach(storeName => {
    hasKey(services, storeName) && inject(storeName, new services[storeName](ctx));
  });
}
function setAxiosInterceptor(ctx) {
  ctx.$axios.onRequest(config => {
    var _ctx$store$state, _ctx$store$state$auth;
    const {
      domain
    } = ctx.$application;
    if (!domain) return;
    const {
      store
    } = ctx.$application;
    if (!config.headers.common) config.headers.common = {};
    const {
      languages
    } = ctx.$application;
    const {
      locale
    } = ctx.app.i18n;
    const language = languages === null || languages === void 0 ? void 0 : languages.find(language => language.code2 === locale);
    const {
      common
    } = config.headers;
    if (language) {
      common['x-gaussbox-language'] = language === null || language === void 0 ? void 0 : language.code;
    }
    if (store !== null && store !== void 0 && store.storeID) {
      common.store = store.storeID;
    }
    common.apiKey = domain.apiKeyToken;
    common.site = domain.siteID;
    if ((_ctx$store$state = ctx.store.state) !== null && _ctx$store$state !== void 0 && (_ctx$store$state$auth = _ctx$store$state.auth) !== null && _ctx$store$state$auth !== void 0 && _ctx$store$state$auth['token.refresh']) {
      common.accessToken = ctx.store.state.auth['token.refresh'];
    }
  });
  let triedAgain = false;
  ctx.$axios.onError(async error => {
    var _ctx$$toast;
    const e = formatError(error);
    const translation = ctx.app.i18n.t(e.message);
    const message = typeof translation === 'string' ? translation : e.message;
    const duration = message.length * 50;
    // Seems like toast is not initialised on server (on time?)
    (_ctx$$toast = ctx.$toast) === null || _ctx$$toast === void 0 ? void 0 : _ctx$$toast.error(message.replace('`validateLogin` failed (\"timeouts\").  Login froze\nAdditional data:\n\n', ''), {
      duration
    });
    if (e.statusCode !== 403 || triedAgain) throw error;
    consola.error('Wrong admin accessKey (probably changed domain while logged in as admin on old domain), logging you out.');

    // This is mostly handler for error that occurs if we stay logged in as
    // admin on one domain and then we switch the domain.
    await ctx.$auth.logout();
    // @ts-ignore
    const authEndpoints = ctx.$auth.strategies.admin.options.endpoints;
    const k = mapEachKey(authEndpoints, key => {
      var _authEndpoints$key;
      return (_authEndpoints$key = authEndpoints[key]) === null || _authEndpoints$key === void 0 ? void 0 : _authEndpoints$key.url;
    });
    const requestedRouteIsAuth = k.filter(Boolean).includes(error.config.url);
    // If auth route request failed, we don't care because we logged out anyway
    if (requestedRouteIsAuth) return;
    triedAgain = true;
    delete error.config.headers.accessToken;
    await ctx.$axios.request(error.config);
  });
}
async function fetchAllOptions(ctx) {
  if (!process.server) return;
  const {
    $application,
    $components,
    $cart,
    $wishlist,
    $auth
  } = ctx;
  const promises = [$application.fetchCmsOptions(),
  // $application.fetchSiteReviewOptions(),
  $components.fetchElementsConfig()];
  $application.isEcommerce && promises.push(...[$application.fetchCurrencyConfig(), $cart.checkSessionOnInit()]);
  $auth.loggedIn && !$auth.user.isBackofficeUser && promises.push($wishlist.fetchWishlist());
  await Promise.all(promises);
}