// Types
import mixins from "vue-typed-mixins";
// Components
import { FooterElement } from '../../overridableComponents';

// Mixins
import EditorContainer from '../../../mixins/EditorContainer';
import LayoutElementProvider from '../../../mixins/LayoutElementProvide';

// Enums
import { StaticElements } from '../../../enums/StaticElements';

// Main
const FooterContainer = mixins(EditorContainer, LayoutElementProvider(StaticElements.Footer)).extend({
  name: 'FooterContainer',
  render() {
    const h = arguments[0];
    return h(FooterElement);
  }
});
export default FooterContainer;