// Types
import Vue from 'vue';
// Mixins
import { validationMixin } from 'vuelidate';

// Utils
import get from 'lodash/get';
const VuelidateHelper = Vue.extend({
  mixins: [validationMixin],
  methods: {
    generateErrorMessage(inputPath) {
      const inputValidation = get(this.$v, inputPath);
      if (!inputValidation) return null;
      const {
        $error,
        $params,
        required,
        email,
        minLength,
        maxLength,
        sameAs,
        differentPassword,
        repeatPassword
      } = inputValidation;
      const tPathPrefix = 'core.validation';
      if (!$error) {
        return null;
      } else if (required === false) {
        return this.$t(`${tPathPrefix}.required`);
      } else if (email === false) {
        return this.$t(`${tPathPrefix}.email`);
      } else if (minLength === false) {
        var _$params$minLength;
        const length = $params === null || $params === void 0 ? void 0 : (_$params$minLength = $params.minLength) === null || _$params$minLength === void 0 ? void 0 : _$params$minLength.min;
        return this.$t(`${tPathPrefix}.minLength`, {
          length
        });
      } else if (maxLength === false) {
        var _$params$maxLength;
        const length = $params === null || $params === void 0 ? void 0 : (_$params$maxLength = $params.maxLength) === null || _$params$maxLength === void 0 ? void 0 : _$params$maxLength.max;
        return this.$t(`${tPathPrefix}.maxLength`, {
          length
        });
      } else if (sameAs === false) {
        var _$params$sameAs, _$params$sameAs$eq;
        const value = $params === null || $params === void 0 ? void 0 : (_$params$sameAs = $params.sameAs) === null || _$params$sameAs === void 0 ? void 0 : (_$params$sameAs$eq = _$params$sameAs.eq) === null || _$params$sameAs$eq === void 0 ? void 0 : _$params$sameAs$eq.call(_$params$sameAs);
        return this.$t(`${tPathPrefix}.sameAs`, {
          value
        });
      } else if (differentPassword === false) {
        return this.$t(`${tPathPrefix}.differentPassword`);
      } else if (repeatPassword === false) {
        return this.$t(`${tPathPrefix}.repeatPassword`);
      } else {
        return 'Novi tip errora, (message dodati locales, a case VuelidateHelper.ts)';
      }
    }
  }
});
export default VuelidateHelper;