export let Modals;
(function (Modals) {
  Modals["Media"] = "gbox-media";
  Modals["MediaUpload"] = "gbox-media-upload";
  Modals["NewPage"] = "new-page";
  Modals["Icon"] = "icon-list";
  Modals["LinkOptions"] = "link-options";
  Modals["Sections"] = "section-manager";
  Modals["DomainList"] = "domain-list";
  Modals["Cart"] = "cart-modal";
  Modals["Posts"] = "post-list";
  Modals["Products"] = "product-list";
  Modals["Releases"] = "project-releases";
  Modals["ProductCategories"] = "product-categories";
  Modals["BundleCustomization"] = "bundle-customization";
})(Modals || (Modals = {}));