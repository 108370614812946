import mixins from "vue-typed-mixins";
//Mixins
import ControllerBase from '../../mixins/ControllerBase';
const ControllerPostCategoryList = mixins(ControllerBase).extend({
  name: 'ControllerPostCategoryList',
  data() {
    return {
      postCategories: []
    };
  },
  props: {
    blacklist: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  async fetch() {
    const response = await this.$api.cms.term.filter({});
    if (response.errored) return;
    this.postCategories = this.filterPostCategories(response.data.data.categories);
  },
  methods: {
    filterPostCategories(categories) {
      return categories.filter(category => !this.blacklist.includes(category.slug));
    }
  },
  render() {
    const slotProps = this.postCategories.map((category, index) => ({
      item: category,
      index: index
    }));
    return this.renderContainer(slotProps);
  }
});
export default ControllerPostCategoryList;