//Types
import mixins from "vue-typed-mixins";
//Mixins
import ControllerBase from '../../mixins/ControllerBase';
import Paginatable from '../../mixins/Paginatable';

//Utils
import { WithPartialInject } from '../../utils/withInject';

//When inside ControllerPostCategory, inject selected category slug. (this can only happen inside PostCategory View)
const categoryInject = WithPartialInject('categorySlug');
const ControllerPostList = mixins(ControllerBase, Paginatable, categoryInject).extend({
  name: 'ControllerPostList',
  props: {
    onlyPages: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    categories: {
      deep: true,
      handler() {
        this.fetchDataDebounced();
      }
    }
  },
  async pagination() {
    const desiredCategories = [...this.categories];
    if (this.categorySlug) {
      const category = await this.$api.cms.term.get(this.categorySlug);
      if (!category.errored) desiredCategories.push(category.data.slugMain);
    }
    const filters = [{
      key: 'display',
      value: this.onlyPages ? 'components' : 'legacy'
    }];
    desiredCategories.length && filters.push({
      key: 'termSlugs',
      value: desiredCategories
    });
    !this.onlyPages && filters.push({
      key: 'postTypeSlugs',
      value: ['post']
    });
    return {
      request: (params, api) => api.cms.post.filterStandard({
        filters,
        sort: {
          param: 'published_at',
          order: 'desc'
        },
        ...params
      })
    };
  }
});
export default ControllerPostList;