// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../mixins/ControllerBase';
import { WithPartialInject } from '../../utils/withInject';
const inject = WithPartialInject('pageData');
const ControllerPost = mixins(ControllerBase, inject).extend({
  name: 'ControllerPost',
  render() {
    const {
      pageData: item
    } = this;
    const slotProps = {
      item
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerPost;