// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../../mixins/ControllerBase';
import Paginatable from '../../../mixins/Paginatable';
import ProductListProvider from '../../../mixins/ProductListProvider';
const ControllerWishlist = mixins(ControllerBase, Paginatable, ProductListProvider).extend({
  name: 'ControllerWishlist',
  pagination() {
    return {
      request: params => {
        return this.$wishlist.fetchWishlist(params, this.shouldLoadMore);
      }
    };
  },
  created() {
    this.$watch('$wishlist.pagination', () => this.setPagination(this.$wishlist.pagination), {
      immediate: true
    });
    this.$watch('$wishlist.items', () => {
      const {
        items
      } = this.$wishlist;
      this.items.splice(0, this.items.length, ...items);
      this.setProducts(items.map(p => p.product));
    }, {
      immediate: true
    });
  }
});
export default ControllerWishlist;