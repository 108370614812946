import './AdminGlassPanel.css';

// Types
import mixins from "vue-typed-mixins";
// Main
const AdminGlassPanel = mixins().extend({
  name: 'AdminGlassPanel',
  props: {
    light: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: 'p-5'
    }
  },
  render() {
    const h = arguments[0];
    return h("div", {
      "class": ['core-admin-glass-panel', {
        light: this.light
      }, this.padding]
    }, [this.$slots.default]);
  }
});
export default AdminGlassPanel;