//Types
import mixins from "vue-typed-mixins";
// Enums
import { Page } from '@gauss/cms-shared';
import { ProductFormat } from '../../enums/Product';

// Utils
import isEmpty from 'lodash/isEmpty';
import consola from 'consola';
import { genNavigationItemPath, genTermPath } from '../../utils/cmsLinkHelpers';
const isTerm = to => typeof to !== 'string' && 'typeSlug' in to;
const isFromEditorList = to => {
  //Since default value is null, we need this safety check because 'in' operator will break our page if 'to' prop is null
  if (!to) return false;else return typeof to !== 'string' && 'formattedPath' in to;
};
const isNavigationItem = to => {
  return typeof to !== 'string' && 'externalLink' in to && 'children' in to && 'parent_id' in to;
};
function isCartItem(to) {
  return !!to && typeof to !== 'string' && 'productData' in to;
}
const isCmsEvent = to => {
  if (!to) return false;else return typeof to !== 'string' && 'startEvent' in to && 'endEvent' in to && 'startEvent' in to && 'eventAttendees' in to;
};
const isBundle = to => {
  if (!to) return false;else return typeof to !== 'string' && 'format' in to && to.format === ProductFormat.Bundle && 'bundles' in to && Array.isArray(to.bundles) && !!to.bundles.length && !('type' in to) && !('resourceType' in to);
};
const CmsLink = mixins().extend({
  name: 'CmsLink',
  props: {
    serverNavigation: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      default: () => ({})
    },
    hideDefaultName: {
      type: Boolean,
      default: false
    },
    dropdownLink: {
      type: Boolean,
      default: false
    },
    to: {
      type: [Object, String],
      default: null,
      validator(v) {
        if (isCmsEvent(v)) return true;
        if (isFromEditorList(v)) return true;
        if (isBundle(v)) return true;
        if (typeof v === 'string') return true;
        if (!v || typeof v !== 'object') return false;
        if (isCartItem(v)) return true;
        if ('linkV2' in v) return true;
        const type = typeof v.type === 'string' || !v.type ? v.type : v.type.slug;
        if (type === 'product') return true;
        if (type === 'service') return true;
        if (type === 'post') return true;
        if ('typeSlug' in v) return true;
        const taxonomy = typeof v.taxonomy === 'string' || !v.taxonomy ? v.taxonomy : v.taxonomy.slug;
        if (taxonomy === 'category') return true;
        return false;
      }
    },
    query: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: String,
      default: ''
    }
  },
  computed: {
    toIsFromEnum() {
      return Object.values(this.$Page).includes(this.to);
    },
    isExternal() {
      const {
        to
      } = this;
      if (isFromEditorList(to)) {
        var _to$formattedPath;
        return ((_to$formattedPath = to.formattedPath) === null || _to$formattedPath === void 0 ? void 0 : _to$formattedPath[0]) !== '/';
      } else {
        var _to$externalLink;
        const isExternalLink = typeof to === 'string' && to[0] !== '/' && !this.toIsFromEnum;
        if (isExternalLink) return true;
        if (!to || typeof to === 'string' || isTerm(to) || isCmsEvent(to)) return false;
        if (isNavigationItem(to)) return to.isExternal && !((_to$externalLink = to.externalLink) !== null && _to$externalLink !== void 0 && _to$externalLink.isStatic);
        return false;
      }
    },
    validateServerLink() {
      const {
        link
      } = this;
      return !link.externalLink && !link.prodcategory && !link.link && !link.term_id && !link.post_id && !link.prod_category_id || this.dropdownLink;
    },
    validateNuxtLink() {
      return !isEmpty(this.link) && !this.link.protocol;
    },
    returnLinkRel() {
      let rel = this.link.rel;
      if (!rel) {
        rel = 'dofollow';
      }
      return rel;
    },
    returnLinkTarget() {
      let target = this.link.target;
      if (!target) {
        target = '_blank';
      }
      return target;
    },
    returnLinkName() {
      if (!this.link) return '';
      return this.link.name || this.link.title || '';
    },
    returnNuxtLink() {
      let link = this.link.formattedPath || this.link.linkV2 || this.link.link;
      if (!link) {
        link = '/';
      } else if (link.charAt(0) !== '/') {
        link = `/${link}`;
      }
      return link;
    },
    localeListAsObject() {
      const obj = {};
      const locales = this.$i18n.locales;
      locales === null || locales === void 0 ? void 0 : locales.forEach(locale => obj[locale.code] = locale);
      return obj;
    },
    localisedTo() {
      const {
        to,
        query
      } = this;
      if (!to) return '/';
      if (isFromEditorList(to)) {
        return to.formattedPath || '/';
      } else if (isCmsEvent(to)) {
        return this.localePath({
          name: Page.Event,
          params: {
            id: to.id.toString()
          }
        });
      } else if (isTerm(to)) {
        return genTermPath(to, this.localePath, query);
      } else if (typeof to === 'string') {
        return this.genPathFromString(to);
      } else if (isNavigationItem(to)) {
        const path = genNavigationItemPath(to, this.localePath);
        if (this.isExternal) return path;
        return {
          path,
          query
        };
      } else if (isCartItem(to)) {
        const {
          mainProductSlug,
          slug,
          isVisibleIndividually
        } = to.productData;
        // If there is mainProductSlug, that means user clicked on one simple product configuration
        // If user clicks on cart item which is one simple product configuration which is not visible individually, then we should take him to configurabile product single with additional query so that we can know which configuration and custom options were set
        // If clicked product is just a simple product which is not some other products configuration, or is product configuration which is visible individually, dont use query
        const {
          slug: finalSlug,
          query: finalQuery = null
        } = mainProductSlug ? isVisibleIndividually ? {
          slug
        } : {
          slug: mainProductSlug,
          query: {
            ...query,
            cartItemId: to.itemId
          }
        } : {
          slug
        };
        return this.localePath({
          path: '/product/' + finalSlug,
          query: finalQuery ? finalQuery : query
        });
      } else if (isBundle(to)) {
        return this.localePath({
          path: '/product/' + to.slug,
          query
        });
      } else if (to.type === 'product') {
        return this.localePath({
          path: '/product/' + to.slug,
          query
        });
      } else if (to.type === 'service') {
        return this.localePath({
          path: '/product/' + to.slug,
          query
        });
      } else if (to.resourceType === 'post') {
        return this.localePath({
          path: '/' + to.slug,
          query
        });
      } else if (to.resourceType === 'term') {
        return this.localePath({
          name: this.$Page.Blog,
          params: {
            slug: to.slug
          },
          query
        });
      } else {
        if (process.browser) {
          consola.error(`Cannot recognise "to" prop on CmsLink:`, to);
        }
        return '/';
      }
    }
  },
  methods: {
    genPathFromString(to) {
      const {
        query
      } = this;
      if (this.isExternal) return to;
      if (this.toIsFromEnum) return this.localePath({
        name: to,
        query
      });

      // If "to" is equal to "/en", "/hr" or something along those lines,
      // it means we just need to switch the locale
      const pathIsLocaleName = this.localeListAsObject[to];
      if (pathIsLocaleName) return this.switchLocalePath(to);
      const resolved = this.$router.resolve(to);
      const {
        name,
        params,
        matched
      } = resolved.route;
      if (!matched.length) return {
        path: to,
        query
      };

      // i18n creates multiple page components out of one page's component - one for each language.
      // It names them {componentName}___{locale}, e.g. page-cart___en, page-cart___hr etc.
      const nameWithoutLocale = name === null || name === void 0 ? void 0 : name.replace(/___\w+/, '');
      return this.localePath({
        name: nameWithoutLocale,
        params,
        query
      });
    },
    chooseLinkType() {
      if (this.to !== null && !this.isExternal) {
        var _this$$scopedSlots$de, _this$$scopedSlots;
        return this.$createElement('nuxt-link', {
          props: {
            to: this.localisedTo
          },
          attrs: {
            // @ts-ignore
            rel: this.to.rel,
            // @ts-ignore
            target: this.to.target || this.target
          },
          on: {
            click: () => this.$emit('click')
          }
        }, (_this$$scopedSlots$de = (_this$$scopedSlots = this.$scopedSlots).default) === null || _this$$scopedSlots$de === void 0 ? void 0 : _this$$scopedSlots$de.call(_this$$scopedSlots, {}));
      } else if (this.isExternal) {
        var _this$$scopedSlots$de2, _this$$scopedSlots2;
        return this.$createElement('a', {
          attrs: {
            href: this.localisedTo,
            target: '_blank'
          },
          on: {
            click: () => this.$emit('click')
          }
        }, (_this$$scopedSlots$de2 = (_this$$scopedSlots2 = this.$scopedSlots).default) === null || _this$$scopedSlots$de2 === void 0 ? void 0 : _this$$scopedSlots$de2.call(_this$$scopedSlots2, {}));
      } else if (this.link && this.serverNavigation && this.validateServerLink) {
        var _this$$scopedSlots$de3, _this$$scopedSlots3;
        return this.$createElement('a', {
          attrs: {
            role: 'button'
          },
          class: 'static',
          on: {
            click: () => this.$emit('click')
          }
        }, [!this.hideDefaultName ? this.returnLinkName : undefined, (_this$$scopedSlots$de3 = (_this$$scopedSlots3 = this.$scopedSlots).default) === null || _this$$scopedSlots$de3 === void 0 ? void 0 : _this$$scopedSlots$de3.call(_this$$scopedSlots3, {})]);
      } else if ((!this.returnLinkName || this.returnLinkName === '') && this.$isInEditMode && !this.hideDefaultName) {
        return this.$createElement('a', 'LinkPlaceholder');
      } else if (this.link && this.link.externalLink) {
        var _this$$scopedSlots$de4, _this$$scopedSlots4;
        return this.$createElement('a', {
          attrs: {
            href: this.link.link,
            rel: this.returnLinkRel,
            target: this.returnLinkTarget
          }
        }, [!this.hideDefaultName ? this.returnLinkName : undefined, (_this$$scopedSlots$de4 = (_this$$scopedSlots4 = this.$scopedSlots).default) === null || _this$$scopedSlots$de4 === void 0 ? void 0 : _this$$scopedSlots$de4.call(_this$$scopedSlots4, {})]);
      } else if (this.link && this.validateNuxtLink) {
        var _this$$scopedSlots$de5, _this$$scopedSlots5;
        return this.$createElement('nuxt-link', {
          props: {
            to: this.returnNuxtLink
          },
          slot: 'default'
        }, [!this.hideDefaultName ? this.returnLinkName : undefined, (_this$$scopedSlots$de5 = (_this$$scopedSlots5 = this.$scopedSlots).default) === null || _this$$scopedSlots$de5 === void 0 ? void 0 : _this$$scopedSlots$de5.call(_this$$scopedSlots5, {})]);
      } else {
        var _this$$scopedSlots$de6, _this$$scopedSlots6;
        return this.$createElement('a', [(_this$$scopedSlots$de6 = (_this$$scopedSlots6 = this.$scopedSlots).default) === null || _this$$scopedSlots$de6 === void 0 ? void 0 : _this$$scopedSlots$de6.call(_this$$scopedSlots6, {})]);
      }
    }
  },
  render() {
    return this.chooseLinkType();
  }
});
export default CmsLink;