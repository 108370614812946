// Types
import mixins from "vue-typed-mixins"; // Utils
import startCase from 'lodash/startCase';
import kebabCase from 'lodash/kebabCase';

// Mixins
import StatusUpdateable from './StatusUpdateable';
const ControllerBase = mixins(StatusUpdateable).extend({
  props: {
    tag: {
      type: String,
      default: 'div'
    }
  },
  methods: {
    createModularContainer(children, data = {
      domProps: {}
    }, labelText = '') {
      // Semantics
      const {
        name
      } = this.$options;
      const dataCms = this.$config.isProduction ? undefined : name;
      data.domProps = data.domProps || {};
      data.class = Array.isArray(data.class) ? data.class : [data.class];
      const isLabelShown = labelText && this.$isInEditMode;
      data.class.push(isLabelShown ? 'relative bg-blue-200 border border-dashed border-blue-600' : '');
      const labelData = {
        class: 'absolute top-0 left-0 bg-blue-600 text-sm text-white px-1'
      };
      const containerLabel = isLabelShown ? this.$createElement('div', labelData, labelText) : undefined;
      children = children ? [...children, containerLabel] : [containerLabel];
      Object.assign(data.domProps, {
        'data-cms': dataCms
      });
      return this.$createElement(this.tag, data, children);
    },
    // All properties in slot props have to be required to avoid having null
    // instead of object and causing errors and crashes on package user's side
    /**
     * Returns universal container for controller; If array is passed,
     * it will automatically v-for each item in array and provide slot for it
     * @param slotProps object or array that will be provided in slot
     * @param requiredProps names of props whose values not be empty, if
     * any of them are empty container will show error instead of content
     * @param labelText text to be shown as label on controller's container
     */
    renderContainer(slotProps, requiredProps = [], labelText = '') {
      var _this$$scopedSlots$li, _this$$scopedSlots, _this$$scopedSlots$de3, _this$$scopedSlots3;
      const h = this.$createElement;
      const {
        name
      } = this.$options || {};
      const slotPropsObj = Array.isArray(slotProps) ? {
        ...Object(slotProps[0]),
        index: 0
      } : slotProps;
      for (const prop of requiredProps) {
        var _slotPropsObj$prop;
        const propIsFilled = ((_slotPropsObj$prop = slotPropsObj[prop]) !== null && _slotPropsObj$prop !== void 0 ? _slotPropsObj$prop : null) !== null;
        if (propIsFilled) continue;
        if (!this.$isInEditMode) return this.createModularContainer();
        return this.createModularContainer([h("div", {
          "class": 'bg-red-600'
        }, ["Missing required data (\"", prop, "\"). Check if section is placed on proper page."])]);
      }
      const slotPropsKeys = slotPropsObj ? Object.keys(slotPropsObj) : [];
      const placeholder = h("div", {
        "class": "bg-gray-200 text-center border border-gray-400 px-4"
      }, [h("b", [startCase(name)]), h("pre", [slotPropsKeys.length ? `v-slot="{ ${slotPropsKeys.join(', ')} }"` : 'Nothing loaded...'])]);
      // @ts-ignore
      const uid = this._uid;
      const defaultSlot = Array.isArray(slotProps) ? ((_this$$scopedSlots$li = (_this$$scopedSlots = this.$scopedSlots).list) === null || _this$$scopedSlots$li === void 0 ? void 0 : _this$$scopedSlots$li.call(_this$$scopedSlots, {
        items: slotProps
      })) || slotProps.map((props, index) => {
        var _this$$scopedSlots$de, _this$$scopedSlots2, _this$$scopedSlots$de2;
        return (_this$$scopedSlots$de = (_this$$scopedSlots2 = this.$scopedSlots).default) === null || _this$$scopedSlots$de === void 0 ? void 0 : (_this$$scopedSlots$de2 = _this$$scopedSlots$de.call(_this$$scopedSlots2, {
          ...props,
          index
        })) === null || _this$$scopedSlots$de2 === void 0 ? void 0 : _this$$scopedSlots$de2.map(slot => ({
          ...slot,
          key: `${kebabCase(name)}-${index}-${uid}`
        }));
      }) : (_this$$scopedSlots$de3 = (_this$$scopedSlots3 = this.$scopedSlots).default) === null || _this$$scopedSlots$de3 === void 0 ? void 0 : _this$$scopedSlots$de3.call(_this$$scopedSlots3, slotProps);
      const slotIsEmpty = !this.$scopedSlots.list && !this.$scopedSlots.default;
      return this.createModularContainer(slotIsEmpty ? [placeholder] : defaultSlot, undefined, labelText);
    }
  }
});
export default ControllerBase;