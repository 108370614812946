import Vue from 'vue';
const sectionView = Vue.extend({
  props: {
    componentData: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    totalComponents: {
      type: Number,
      default: null
    }
    // product: {
    //   type: Object as PropType<Product | null>,
    //   default: null,
    // },
  },

  computed: {
    isFromMobile() {
      // TODO: !this.$ua.isFromPc()
      return (/*!this.$ua.isFromPc() ||*/this.$route.query.mobileView === 'true'
      );
    },
    isEditPage() {
      return this.$route.query.editPage === 'true';
    },
    contentEditableView() {
      // @ts-ignore
      const dummyMode = this.dummyMode;
      return !dummyMode && this.isEditPage && this.isBackofficeUser;
    },
    isBackofficeUser() {
      var _this$$auth$user;
      return !!((_this$$auth$user = this.$auth.user) !== null && _this$$auth$user !== void 0 && _this$$auth$user.isBackofficeUser); // Later make validation based on user permissions
    }
  }
});

export default sectionView;