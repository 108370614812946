// Types
import Vue from 'vue';

// Components
import ControllerProductCategories from '../controllers/ControllerProductCategories';

// Main
const CategoryList = Vue.extend({
  name: 'CategoryList',
  render() {
    const h = arguments[0];
    return h("div", [h("h2", {
      "class": "text-center text-4xl"
    }, ["Kategorije"]), h("hr", {
      "class": "h-1 w-20 mb-10 mt-5 mx-auto bg-gray-300 rounded"
    }), h(ControllerProductCategories, {
      "class": "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4",
      "scopedSlots": {
        default: props => h("cms-link", {
          "class": "bg-gray-200 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105",
          "attrs": {
            "to": `/shop/${props.item.slug}`
          }
        }, [h("cms-image", {
          "attrs": {
            "media": props.item.image
          },
          "class": "m-auto"
        }), h("h5", {
          "class": "text-center py-4 bg-gray-300 uppercase"
        }, [props.item.name])])
      }
    })]);
  }
});
export default CategoryList;