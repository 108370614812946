// Types
import mixins from "vue-typed-mixins";
// Main
const AdminInsetGlassPanel = mixins().extend({
  name: 'AdminInsetGlassPanel',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    title: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    contentTag: {
      type: String,
      default: 'div'
    },
    contentPadding: {
      type: String,
      default: 'p-2'
    },
    darken: {
      type: Boolean,
      default: false
    }
  },
  render() {
    const h = arguments[0];
    const Tag = this.tag;
    const ContentTag = this.contentTag;
    return h(Tag, {
      "class": 'flex flex-col rounded-xl border border-white border-opacity-35'
    }, [h("div", {
      "class": 'space-y-2 border-b border-black border-opacity-25 px-2 py-1 rounded-t-xl text-center relative',
      "style": {
        background: 'linear-gradient(180deg, rgba(166, 178, 199, 0.18) 0%, rgba(180, 194, 208, 0.11) 100%)'
      }
    }, [h("div", {
      "class": ['absolute top-0 right-0 bottom-0 left-0 bg-black rounded-t-xl -z-1 transition-colors duration-150', this.darken ? 'bg-opacity-60' : 'bg-opacity-0']
    }), this.title]), h(ContentTag, {
      "class": ['flex-grow border-t border-white border-opacity-35 bg-black rounded-b-xl transition-colors duration-150', this.contentClass, this.contentPadding, this.darken ? 'bg-opacity-30' : 'bg-opacity-10'],
      "style": 'box-shadow: inset 0 2px 11px 5px rgba(0, 0, 0, 0.06)'
    }, [this.$slots.default])]);
  }
});
export default AdminInsetGlassPanel;