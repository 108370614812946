function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import BaseService from './BaseService';
export default class Wishlist extends BaseService {
  constructor(ctx) {
    super('wishlist', {
      wishlist: [],
      pagination: {}
    }, ctx);
    _defineProperty(this, "api", void 0);
    this.api = ctx.$api;
  }
  get items() {
    return this.storage.wishlist || [];
  }
  get pagination() {
    return this.storage.pagination || {
      lastPage: 1,
      page: 1,
      limit: 0,
      total: 0,
      perPage: 0
    };
  }

  /** List of all languages */
  async fetchWishlist(params, append = false) {
    const res = await this.api.cms.items.filter(params);
    if (!res.errored) {
      const items = append ? [...this.items, ...res.data.data] : res.data.data;
      this.setState('wishlist', items);
      this.setState('pagination', res.data.pagination);
    }
    return res;
  }
}