// Types
import Vue from 'vue';

// Components
import ControllerPost from '../controllers/ControllerPost';
const PostView = Vue.extend({
  name: 'PostView',
  render() {
    const h = arguments[0];
    return h(ControllerPost, {
      "scopedSlots": {
        default: ({
          item
        }) => h("div", [h("div", [item === null || item === void 0 ? void 0 : item.title]), h("div", {
          "domProps": {
            "innerHTML": item === null || item === void 0 ? void 0 : item.body
          }
        })])
      }
    });
  }
});
export default PostView;