// Types
import Vue from 'vue';
import { forEachKey } from '../utils/hasKey';
const BaseProvider = pd => Vue.extend({
  provide() {
    return {
      [pd.providerName]: this
    };
  },
  data() {
    const dataItems = {};
    const registeredControllers = {};
    const tickets = {};
    forEachKey(pd.items, item => dataItems[item] = Array.isArray(pd.items[item]) ? [] : {});
    forEachKey(pd.items, item => tickets[item] = {});
    forEachKey(pd.items, item => registeredControllers[item] = []);
    return {
      ...dataItems,
      registeredControllers,
      tickets
    };
  },
  methods: {
    registerController(item, component) {
      const path = [];
      const calcPath = comp => {
        if (comp === this || !comp._uid) return;
        path.push(comp._uid);
        calcPath(comp.$parent);
      };
      calcPath(component);
      this.registeredControllers[item].push({
        component: component._uid || 0,
        path
      });
      this.calculateTickets(item);
    },
    unregisterController(item, component) {
      const {
        _uid
      } = component;
      if (!_uid) return;
      const i = this.registeredControllers[item].findIndex(controller => controller.component === _uid);
      this.registeredControllers[item].splice(i, 1);
      this.$delete(this.tickets[item], _uid);
    },
    calculateTickets(item) {
      this.registeredControllers[item].forEach(controller => {
        const _uid = controller.component;
        if (!_uid) return;
        const {
          path
        } = controller;
        const index = this.$children.findIndex(child => child._uid === path[path.length - 1]);
        this.$set(this.tickets[item], _uid, index);
      });
    },
    setItems(item, value) {
      const p = this[item];
      if (Array.isArray(p) && Array.isArray(value)) {
        p.splice(0, p.length, ...value);
      } else {
        Object.assign(p, value);
      }
    }
  }
});
export default BaseProvider;
// // Types
// import Vue from 'vue'
// import { forEachKey } from '../utils/hasKey'
//
// export type BaseProviderProvide<V> = InstanceType<
//   ReturnType<typeof BaseProvider>
// > &
//   V
//
// // export interface BaseProviderProvide<N extends string> {
// //   [key: N]: InstanceType<ReturnType<typeof BaseProvider>>
// // }
//
// type WithUid = Vue & { _uid?: number }
//
// const BaseProvider = <N extends string, V extends Record<string, unknown>>(
//   providerName: N,
//   items: V
// ) =>
//   (Vue as Vue.VueConstructor<Vue & V>).extend({
//     provide(): Record<string, unknown> {
//       return {
//         [providerName]: this,
//       }
//     },
//     data() {
//       const dataItems = {} as V
//       const registeredControllers = {} as Record<
//         keyof V,
//         {
//           component: number
//           path: number[]
//         }[]
//       >
//       const tickets = {} as Record<keyof V, Record<number, number>>
//       forEachKey(
//         items,
//         (item) =>
//           (dataItems[item] = Array.isArray(items[item])
//             ? ([] as V[keyof V])
//             : ({} as V[keyof V]))
//       )
//       forEachKey(
//         items,
//         (item) => (tickets[item] = {} as Record<number, number>)
//       )
//       forEachKey(
//         items,
//         (item) =>
//           (registeredControllers[item] = [] as {
//             component: number
//             path: number[]
//           }[])
//       )
//       return {
//         ...dataItems,
//         registeredControllers,
//         tickets,
//       }
//     },
//     methods: {
//       registerController(item: keyof V, component: WithUid) {
//         const path = [] as number[]
//         const calcPath = (comp: WithUid) => {
//           if (comp === this || !comp._uid) return
//           path.push(comp._uid)
//           calcPath(comp.$parent)
//         }
//         calcPath(component)
//         this.registeredControllers[item].push({
//           component: component._uid || 0,
//           path,
//         })
//         this.calculateTickets(item)
//       },
//       unregisterController(item: keyof V, component: WithUid) {
//         const { _uid } = component
//         if (!_uid) return
//         const i = this.registeredControllers[item].findIndex(
//           (controller) => controller.component === _uid
//         )
//         this.registeredControllers[item].splice(i, 1)
//         this.$delete(this.tickets, _uid)
//       },
//       calculateTickets(item: keyof V) {
//         this.registeredControllers[item].forEach((controller) => {
//           const _uid = controller.component
//           if (!_uid) return
//           const { path } = controller
//           const index = this.$children.findIndex(
//             (child: WithUid) => child._uid === path[path.length - 1]
//           )
//           this.$set(this.tickets, _uid, index)
//         })
//       },
//       setItems(item: keyof V, value: V[keyof V]) {
//         const p = this[item]
//         if (Array.isArray(p) && Array.isArray(value)) {
//           p.splice(0, p.length, ...value)
//         } else {
//           Object.assign(p, value)
//         }
//       },
//     },
//   })
//
// export default BaseProvider