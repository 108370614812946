import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
// Types
import mixins from "vue-typed-mixins";
import ResizeObserver from 'resize-observer-polyfill';
// @ts-ignore
import { FadeTransition } from 'vue2-transitions';
// @ts-ignore
import { directive } from 'v-click-outside';

// Mixins
import { WithRefs } from '../../utils/withRefs';
const CmsMenu = mixins(WithRefs()).extend({
  name: 'CmsMenu',
  directives: {
    'click-outside': directive
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: undefined
    },
    clickOutsideWhitelist: {
      type: String,
      default: ''
    },
    zIndex: {
      type: String,
      default: 'z-core-menu'
    },
    contentClass: {
      type: String,
      default: ''
    },
    padding: {
      type: String,
      default: 'p-2'
    }
  },
  data() {
    return {
      renderContent: false,
      showContent: false,
      activator: null,
      popper: null,
      resizeObserver: null
    };
  },
  async mounted() {
    var _this$$slots$activato;
    const activator = (_this$$slots$activato = this.$slots.activator) === null || _this$$slots$activato === void 0 ? void 0 : _this$$slots$activato[0];
    const elm = activator === null || activator === void 0 ? void 0 : activator.elm;
    if (!elm || elm.nodeType !== Node.ELEMENT_NODE) return;
    this.activator = elm;
    this.activator.addEventListener('click', this.handleActivatorClick);
  },
  beforeDestroy() {
    var _this$resizeObserver, _this$activator;
    this.$refs.content && ((_this$resizeObserver = this.resizeObserver) === null || _this$resizeObserver === void 0 ? void 0 : _this$resizeObserver.unobserve(this.$refs.content));
    (_this$activator = this.activator) === null || _this$activator === void 0 ? void 0 : _this$activator.removeEventListener('click', this.handleActivatorClick);
  },
  methods: {
    async initPopper() {
      const content = this.$refs.content;
      if (!this.activator || !content || process.server) return;
      const {
        createPopper
      } = await import('@popperjs/core/lib/popper-lite');
      const {
        default: preventOverflow
      } = await import('@popperjs/core/lib/modifiers/preventOverflow.js');
      const {
        default: flip
      } = await import('@popperjs/core/lib/modifiers/flip');
      const {
        top,
        bottom
      } = this.$display.layout;
      const options = {
        altAxis: true,
        padding: {
          top,
          bottom
        }
      };
      const popperOptions = {
        modifiers: [{
          ...preventOverflow,
          options
        }, flip]
      };
      this.placement && (popperOptions.placement = this.placement);
      this.renderContent = true;
      // TODO: need to destroy it after hiding
      this.popper = createPopper(this.activator, content, popperOptions);
      // TODO: bug with vue I guess. When you open another menu while current
      // is opened, image will teleport over and popper will get wrong calculations
      this.resizeObserver = new ResizeObserver(() => {
        var _this$popper;
        return (_this$popper = this.popper) === null || _this$popper === void 0 ? void 0 : _this$popper.update();
      });
      this.resizeObserver.observe(content);
    },
    async handleActivatorClick() {
      if (this.disabled) return;
      this.showContent = !this.showContent;
      if (this.showContent && this.renderContent) {
        var _this$popper2;
        (_this$popper2 = this.popper) === null || _this$popper2 === void 0 ? void 0 : _this$popper2.update();
        await this.$nextTick();
        this.$emit("opened");
      }
      if (this.renderContent) return;
      await this.$nextTick();
      await this.initPopper();
      this.$emit("opened");
    },
    handleClickOutside() {
      this.showContent = false;
    },
    checkIfClickedActivator(e) {
      var _this$clickOutsideWhi;
      const whiteListed = (_this$clickOutsideWhi = this.clickOutsideWhitelist) === null || _this$clickOutsideWhi === void 0 ? void 0 : _this$clickOutsideWhi.split(' ').filter(Boolean);
      const wlElement = whiteListed === null || whiteListed === void 0 ? void 0 : whiteListed.map(selector => document.querySelector(selector));
      let target = e.target;
      while (target) {
        if ([this.activator, ...wlElement].filter(Boolean).includes(target)) return false;
        target = target.parentElement;
      }
      return true;
    },
    handleContentClick(e) {
      const el = e.target;
      el.tagName === 'A' && (this.showContent = false);
    }
  },
  render() {
    var _this$$scopedSlots$de, _this$$scopedSlots;
    const h = arguments[0];
    return h("span", [this.$slots.activator, h("span", {
      "ref": "content",
      "on": {
        "click": this.handleContentClick
      },
      "class": [this.zIndex, this.contentClass]
    }, [h(FadeTransition, [this.renderContent && h("div", _mergeJSXProps([{
      "class": ['bg-white rounded-md shadow-lg', this.padding]
    }, {
      directives: [{
        name: 'show',
        value: this.showContent
      }, {
        name: 'click-outside',
        value: {
          handler: this.handleClickOutside,
          middleware: this.checkIfClickedActivator
        }
      }]
    }]), [(_this$$scopedSlots$de = (_this$$scopedSlots = this.$scopedSlots).default) === null || _this$$scopedSlots$de === void 0 ? void 0 : _this$$scopedSlots$de.call(_this$$scopedSlots, {
      close: () => this.showContent = false
    })])])])]);
  }
});
export default CmsMenu;