// Types

import get from 'lodash/get';
function getProductPrices(product, hideCurrency = false) {
  var _this$$application$cu;
  const label = (_this$$application$cu = this.$application.currency) === null || _this$$application$cu === void 0 ? void 0 : _this$$application$cu.label;
  const discount = product.discount;
  const labeled = number => number.toString() + (hideCurrency ? '' : ' ' + label);
  const price = product.retailPrice;
  const discountPrice = (discount === null || discount === void 0 ? void 0 : discount.retailPrice) || 0;
  const calculatePrice = (price - discountPrice) / price;
  const calculatePercent = calculatePrice * 100;
  return {
    discounted: discount,
    price: labeled(price),
    discountPercent: discount ? calculatePercent.toFixed() : null,
    discountPrice: discount ? labeled(discountPrice) : null,
    activePrice: labeled(discount ? discountPrice : price)
  };
}

/**
 * Returns passed or instance's product's attribute of given attributeCode.
 * @param record product from product list
 * @param attributeCode name of attributeCode
 * @param options additional options: getAll - return all found attributes; getProperty - path to attribute object's property to return
 */
function getRecordAttribute(record, attributeCode, options = {
  getAll: false,
  getProperty: 'value.value'
}) {
  var _record$attributeSet;
  const oneOrMany = array => {
    if (options.getAll) {
      return Array.isArray(array) ? array : [];
    } else {
      return Array.isArray(array) ? array[0] : array;
    }
  };
  const attributes = record === null || record === void 0 ? void 0 : (_record$attributeSet = record.attributeSet) === null || _record$attributeSet === void 0 ? void 0 : _record$attributeSet.attributes;
  if (!attributes) return oneOrMany([]);
  if (!attributeCode) return oneOrMany(attributes);
  const filtered = attributes.filter(attribute => attribute.attributeCode === attributeCode);
  if (options.getProperty) {
    const mapped = filtered.map(attribute => get(attribute, options.getProperty));
    return oneOrMany(mapped);
  }
  return oneOrMany(attributes);
}
export { getProductPrices, getRecordAttribute };