// Types
import mixins from "vue-typed-mixins"; // Mixins
import ControllerBase from './ControllerBase';

/**
 * returns success boolean
 */

const LoginWithRefreshHelper = mixins(ControllerBase).extend({
  methods: {
    // Accepts arguments because we will validate email and password before performing login
    async performLogin(username, password) {
      this.status.pending = true;
      try {
        var _this$$application$do;
        await this.$auth.loginWith('refresh', {
          data: {
            username,
            password,
            apiKey: (_this$$application$do = this.$application.domain) === null || _this$$application$do === void 0 ? void 0 : _this$$application$do.apiKeyToken
          }
        });
        await this.$auth.fetchUser();
        await this.$cart.fetchAbandonedSession();
        this.status.errored = false;
        this.$wishlist.fetchWishlist();
        this.$toast.success(this.$t('core.login.success'));
        this.status.pending = false;
        return true;
      } catch (e) {
        // axios interceptor will log error
        this.status.errored = true;
        this.status.pending = false;
        return false;
      }
    }
  }
});
export default LoginWithRefreshHelper;