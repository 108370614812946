export const genEmptyAddress = addressType => ({
  firstName: '',
  // Max 40
  lastName: '',
  // Max 40
  contactEmail: '',
  // Max 100
  contactPhone: '',
  // Max 20
  country: '',
  // integer
  city: '',
  // Max 75
  street: '',
  // Max 75
  houseNumber: '',
  // Max 15
  zipCode: '',
  // Max 25
  companyName: '',
  companyOIB: '',
  type: addressType // main, billing, shipping, other
});