// Types
import Vue from 'vue';

// Components
import ControllerConfirmEmail from '../controllers/ControllerConfirmEmail';
const ConfirmEmailView = Vue.extend({
  name: 'ConfirmEmailView',
  render() {
    const h = arguments[0];
    return h("div", [h("h2", ["Email confirmation view"]), h(ControllerConfirmEmail, {
      "class": "bg-purple-300",
      "scopedSlots": {
        default: ({
          message
        }) => h("p", [message])
      }
    })]);
  }
});
export default ConfirmEmailView;