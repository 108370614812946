// Types

// Utils
import { dummyProduct } from '../dummies/product';

// Mixins
import { WithPartialInject } from '../utils/withInject';
import mixins from 'vue-typed-mixins';
const dummyModeInject = WithPartialInject('dummyMode');
const productPageInject = WithPartialInject('product');
const productListProviderInject = WithPartialInject('productListProvider');
const ProductListInjector = mixins(productPageInject, productListProviderInject, dummyModeInject).extend({
  computed: {
    computedProduct() {
      var _this$productListProv;
      if (this.dummyMode) return dummyProduct;
      if ((_this$productListProv = this.productListProvider) !== null && _this$productListProv !== void 0 && _this$productListProv.products) {
        var _this$productListProv2, _this$productListProv3;
        // @ts-expect-error _uid is not given in interface, but it does exist
        const uid = this._uid;
        const i = (_this$productListProv2 = this.productListProvider) === null || _this$productListProv2 === void 0 ? void 0 : (_this$productListProv3 = _this$productListProv2.tickets) === null || _this$productListProv3 === void 0 ? void 0 : _this$productListProv3[uid];
        if (i === undefined) return null;
        return this.productListProvider.products[i];
      }
      return this.product || null;
    }
  },
  created() {
    var _this$productListProv4;
    (_this$productListProv4 = this.productListProvider) === null || _this$productListProv4 === void 0 ? void 0 : _this$productListProv4.registerController(this);
  },
  destroyed() {
    var _this$productListProv5;
    (_this$productListProv5 = this.productListProvider) === null || _this$productListProv5 === void 0 ? void 0 : _this$productListProv5.unregisterController(this);
  }
});
export default ProductListInjector;