// Types
import mixins from "vue-typed-mixins";
import { dummyProductRecord } from '../dummies/product';
import ControllerProductProviderData from '../providerData/ControllerProductProviderData';

// Mixins
import ControllerBase from './ControllerBase';
import BaseInjector from './BaseInjector';
import ProductListProvider from './ProductListProvider';
import ProductFilterFieldSelector from './ProductFilterFieldSelector';
const dummyInject = WithPartialInject('dummyMode');
const pageCartInject = WithPartialInject('isCartView');

//Utils
import { WithPartialInject } from '../utils/withInject';
// If we use ControllerUpSell/CrossSell/RelatedProducts inside CartView or CartModalView,
// We need to read each cart item which is by default in CartItem type,
// then fetch its data in ProductRecord type so we can access upSell/crossSell/relatedProducts array,
// then we need to calculate computedIDs while taking care about duplicates.
// the goal is to return each upSelled/crossSelled/related product from each cart item if we are currently inside CartView or CartModalView

const ProductsFromIDs = mixins(ControllerBase, BaseInjector(ControllerProductProviderData), dummyInject, ProductListProvider, pageCartInject, ProductFilterFieldSelector).extend({
  data() {
    return {
      products: [],
      // Used only if isCartView is true
      cartProductRecords: []
    };
  },
  watch: {
    computedIDs: {
      async handler() {
        await this.$fetch();
      }
    }
  },
  computed: {
    computedIDs() {
      throw new Error('Nisi definirao computedIDs');
      return [];
    },
    // Used only if isCartView is true
    cartProductIDs() {
      return this.$cart.items.map(cartItem => cartItem.productId);
    }
  },
  async fetch() {
    var _this$productProvider;
    if (this.isCartView && !((_this$productProvider = this.productProvider) !== null && _this$productProvider !== void 0 && _this$productProvider.activeProduct)) await this.fetchProductRecordsFromCart();
    await this.fetchProductsFromIDs();
  },
  methods: {
    clearProducts() {
      this.products.splice(0, this.products.length);
    },
    async fetchProductRecordsFromCart() {
      if (!this.cartProductIDs.length) return;
      const filters = [{
        key: 'id',
        value: this.cartProductIDs
      }];
      const fetchedProducts = await this.$api.product.web.filter({
        filters,
        select: this.computedSelectParam
      });
      if (fetchedProducts.errored) return;
      this.cartProductRecords.splice(0, this.cartProductRecords.length, ...fetchedProducts.data.records);
    },
    async fetchProductsFromIDs() {
      var _this$computedIDs;
      if (this.dummyMode) {
        //Creating dummy products
        const dummyProducts = Array.from(Array(6)).map(() => dummyProductRecord);
        const p = this.products;
        p.splice(0, p.length, ...dummyProducts);
        return;
      }
      if (!((_this$computedIDs = this.computedIDs) !== null && _this$computedIDs !== void 0 && _this$computedIDs.length)) {
        this.clearProducts();
        return;
      }
      const filters = [{
        key: 'id',
        value: this.computedIDs
      }];
      const fetchedProducts = await this.$api.product.web.filter({
        filters,
        select: this.computedSelectParam
      });
      if (fetchedProducts.errored) return;
      this.products.splice(0, this.products.length, ...fetchedProducts.data.records);
    }
  },
  render() {
    const {
      status
    } = this;
    const slotProps = this.products.map(product => ({
      item: product,
      status
    }));
    return this.renderContainer(slotProps);
  }
});
export default ProductsFromIDs;