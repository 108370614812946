// Types
import mixins from "vue-typed-mixins";
import Consentable from '../../../mixins/Consentable';
import { FormWithConsent } from '../../../enums/Gdpr';
import LoginWithRefreshHelper from '../../../mixins/LoginWithRefreshHelper';
import VuelidateHelper from '../../../mixins/VuelidateHelper';

// Utils
import { email, maxLength, minLength, required, sameAs } from 'vuelidate/lib/validators';
import { forEachKey } from '../../../utils/hasKey';
import { genEmptyAddress } from '../../../utils/address';
import { convertAddressCountryToId } from '../../../transformators/address';

// Enums
import { AddressType } from '../../../enums/Checkout';
const ControllerRegister = mixins(LoginWithRefreshHelper, Consentable(FormWithConsent.Register), VuelidateHelper).extend({
  name: 'ControllerRegister',
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmedPassword: '',
        deliveryForm: genEmptyAddress(AddressType.Shipping),
        billingForm: genEmptyAddress(AddressType.Main)
      },
      options: {
        useDifferentBillingAddress: false
      }
    };
  },
  props: {
    disableLoginRedirect: {
      type: Boolean,
      default: false
    },
    autoLogin: {
      type: Boolean,
      default: false
    },
    useAddresses: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // This || will be used only if useAddresses is true but without 'useDifferentBillingAddress' option,
    // in that case deliveryAddress will be copied into billingAddress, so both contactEmail properties will be the same.
    registrationEmail() {
      const {
        email,
        billingForm,
        deliveryForm
      } = this.form;
      return this.useAddresses ? billingForm.contactEmail || deliveryForm.contactEmail : email;
    },
    allowedCountries() {
      var _this$$application$st, _this$$application$st2;
      if (!this.useAddresses) return [];
      const allowedInSettings = (_this$$application$st = this.$application.storage.checkoutSettings) === null || _this$$application$st === void 0 ? void 0 : (_this$$application$st2 = _this$$application$st.allowedCountries) === null || _this$$application$st2 === void 0 ? void 0 : _this$$application$st2.value;
      const {
        countries
      } = this.$application.storage;
      if (!countries) return [];
      const includeAll = Array.isArray(allowedInSettings) ? allowedInSettings.includes('ALL') : allowedInSettings === 'ALL';
      const callback = country => Array.isArray(allowedInSettings) ? allowedInSettings.includes(country.code) : country.code === allowedInSettings;
      return includeAll ? countries : countries.filter(callback);
    }
  },
  async mounted() {
    if (this.useAddresses) {
      var _this$$application$st3;
      if (!this.$application.countries.length) {
        await this.$application.fetchCountries();
      }
      if (!((_this$$application$st3 = this.$application.storage.checkoutSettings) !== null && _this$$application$st3 !== void 0 && _this$$application$st3.allowedCountries)) {
        await this.$application.fetchCheckoutSettings();
      }
    }
  },
  methods: {
    genNewUser() {
      const {
        password
      } = this.form;
      const convertedDeliveryAddress = convertAddressCountryToId(this.form.deliveryForm, this.$application);
      const convertedBillingAddress = this.options.useDifferentBillingAddress ? convertAddressCountryToId(this.form.billingForm, this.$application) : {
        ...convertedDeliveryAddress,
        type: AddressType.Main
      };
      if (!this.useAddresses) {
        const {
          firstName,
          lastName,
          email
        } = this.form;
        return {
          details: {
            firstName,
            lastName
          },
          email,
          password,
          username: email
        };
      } else {
        const {
          firstName,
          lastName,
          contactEmail: email
        } = convertedBillingAddress;
        return {
          addresses: [convertedDeliveryAddress, convertedBillingAddress],
          details: {
            firstName,
            lastName
          },
          email,
          password,
          username: email
        };
      }
    },
    async submit() {
      this.$v.form.$touch();
      const consentsValid = await this.validateConsents(this.registrationEmail);
      if (this.$v.form.$invalid || !consentsValid) return;
      const response = await this.$api.user.web.register(this.genNewUser());
      if (response.errored) return;
      this.showSuccessToast('core.notifications.registered');
      if (this.autoLogin) {
        const loginSuccess = await this.performLogin(this.registrationEmail, this.form.password);
        if (!loginSuccess) return;
      }
      this.$emit("submitted");
      this.resetForm();
      if (this.disableLoginRedirect) return;
      this.redirectToLogin();
    },
    resetForm() {
      this.$v.form.$reset();
      forEachKey(this.form, key => {
        if (key === 'acceptedConsents') {
          this.form[key] = [];
        } else if (key === 'deliveryForm') {
          this.form[key] = genEmptyAddress(AddressType.Shipping);
        } else if (key === 'billingForm') {
          this.form[key] = genEmptyAddress(AddressType.Main);
        } else this.form[key] = '';
      });
    },
    showSuccessToast(tPath) {
      this.$toast.success(this.$t(tPath));
    },
    redirectToLogin() {
      this.$router.push(this.localePath(this.$Page.Login));
    }
  },
  validations() {
    const emptyBaseValidation = {
      firstName: {},
      lastName: {},
      email: {}
    };
    const emptyAddressValidation = {
      firstName: {},
      lastName: {},
      contactEmail: {},
      contactPhone: {},
      country: {},
      city: {},
      street: {},
      houseNumber: {},
      zipCode: {}
    };
    const baseValidation = {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      }
    };

    // Password doesn't need an empty template, it will always be validated
    const passwordValidation = {
      password: {
        required,
        minLength: minLength(8)
      },
      confirmedPassword: {
        required,
        repeatPassword: sameAs('password')
      }
    };
    const addressValidation = {
      firstName: {
        required
      },
      lastName: {
        required
      },
      contactEmail: {
        required,
        email
      },
      contactPhone: {
        required
      },
      country: {
        required,
        maxLength: maxLength(75)
      },
      city: {
        required,
        maxLength: maxLength(75)
      },
      street: {
        required,
        maxLength: maxLength(75)
      },
      houseNumber: {
        required,
        maxLength: maxLength(15)
      },
      zipCode: {
        required,
        maxLength: maxLength(25)
      }
    };
    if (!this.useAddresses) {
      return {
        form: {
          ...baseValidation,
          ...passwordValidation,
          deliveryForm: {
            ...emptyAddressValidation
          },
          billingForm: {
            ...emptyAddressValidation
          }
        }
      };
    } else if (!this.options.useDifferentBillingAddress) {
      return {
        form: {
          ...emptyBaseValidation,
          ...passwordValidation,
          deliveryForm: {
            ...addressValidation
          },
          billingForm: {
            ...emptyAddressValidation
          }
        }
      };
    } else {
      return {
        form: {
          ...emptyBaseValidation,
          ...passwordValidation,
          deliveryForm: {
            ...addressValidation
          },
          billingForm: {
            ...addressValidation
          }
        }
      };
    }
  },
  render() {
    const {
      form,
      submit,
      status,
      options,
      allowedCountries,
      assignedConsents: consents,
      $v,
      generateErrorMessage
    } = this;
    const slotProps = {
      form,
      submit,
      status,
      options,
      allowedCountries,
      consents,
      $v,
      generateErrorMessage
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerRegister;