// Thanks to Vuetify team's code, typescript is getting handled
// https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/mixins/registrable/index.ts

import Vue from 'vue';
const instanceInject = propertyName => Vue.extend({
  inject: {
    [propertyName]: {
      default: null
    }
  }
});
export { instanceInject };