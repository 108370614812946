// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../../mixins/ControllerBase';
import VuelidateHelper from '../../../mixins/VuelidateHelper';

// Utils
import { forEachKey } from '../../../utils/hasKey';
import { required, minLength, sameAs, not } from 'vuelidate/lib/validators';
const ControllerChangePassword = mixins(ControllerBase, VuelidateHelper).extend({
  name: 'ControllerChangePassword',
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        newPasswordAgain: ''
      }
    };
  },
  validations: {
    form: {
      oldPassword: {
        required,
        minLength: minLength(8)
      },
      newPassword: {
        required,
        differentPassword: not(sameAs('oldPassword')),
        minLength: minLength(8)
      },
      newPasswordAgain: {
        required,
        repeatPassword: sameAs('newPassword')
      }
    }
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      const {
        oldPassword,
        newPassword
      } = this.form;
      // TODO: back needs to validate repeated new password
      const req = this.$api.user.password.reset({
        oldPassword,
        newPassword
      });
      const res = await this.callWithStatusUpdate(req, 'submitted');
      if (res.errored) return;
      this.$v.form.$reset();
      forEachKey(this.form, key => this.form[key] = '');
    }
  },
  render() {
    const {
      form,
      submit,
      status,
      $v,
      generateErrorMessage
    } = this;
    const slotProps = {
      form,
      submit,
      status,
      $v,
      generateErrorMessage
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerChangePassword;