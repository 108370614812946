import _mergeJSXProps23 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps22 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps21 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps20 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps19 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps18 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps17 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps16 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps15 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps14 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps13 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps12 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps11 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps10 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps9 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps8 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps7 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps6 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps5 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps4 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps3 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps2 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
// Types
import Vue from 'vue';

// Components
import ControllerAddress from '../controllers/checkout/ControllerAddress';
import ControllerShipping from '../controllers/checkout/ControllerShipping';
import ControllerPayment from '../controllers/checkout/ControllerPayment';

// Utils
import { checkedFromInput, preventDefault, valueFromInput } from '../../utils/jsxHelpers';
const CheckoutView = Vue.extend({
  name: 'CheckoutView',
  render() {
    const h = arguments[0];
    const bind = function (object, key) {
      return {
        domProps: {
          value: object[key]
        },
        class: 'block border w-full',
        on: {
          input: valueFromInput(v => {
            if (typeof object[key] === 'string') {
              // @ts-ignore
              object[key] = v;
            } else if (typeof object[key] === 'number') {
              // @ts-ignore
              object[key] = Number(v);
            }
          })
        }
      };
    };
    return h("div", [h("h2", ["Delivery"]), h(ControllerAddress, {
      "scopedSlots": {
        default: props => h("div", _mergeJSXProps21([{}, {
          directives: [{
            name: 'show',
            value: props.isStepActive
          }]
        }]), [h("form", {
          "class": "grid gap-4 w-64",
          "attrs": {
            "novalidate": true
          },
          "on": {
            "submit": preventDefault(props.submit)
          }
        }, [h("fieldset", {
          "class": "grid gap-4 w-64"
        }, [h("label", ["Company", h("input", _mergeJSXProps([{
          "attrs": {
            "type": "text",
            "autocomplete": "company"
          }
        }, bind(props.form.deliveryForm, 'companyName')]))]), h("label", ["Company OIB", h("input", _mergeJSXProps2([{
          "attrs": {
            "type": "text",
            "autocomplete": "company"
          }
        }, bind(props.form.deliveryForm, 'companyOIB')]))]), h("div", {
          "class": "grid grid-cols-2 gap-4"
        }, [h("label", ["Ime", h("input", _mergeJSXProps3([{
          "attrs": {
            "type": "text",
            "autocomplete": "given-name"
          }
        }, bind(props.form.deliveryForm, 'firstName')]))]), h("label", ["Prezime", h("input", _mergeJSXProps4([{
          "attrs": {
            "type": "text",
            "autocomplete": "family-name"
          }
        }, bind(props.form.deliveryForm, 'lastName')]))])]), h("label", ["E-mail", h("input", _mergeJSXProps5([{
          "attrs": {
            "type": "text",
            "autocomplete": "email"
          }
        }, bind(props.form.deliveryForm, 'contactEmail')]))]), h("label", ["Telefon", h("input", _mergeJSXProps6([{
          "attrs": {
            "type": "text",
            "autocomplete": "tel"
          }
        }, bind(props.form.deliveryForm, 'contactPhone')]))]), h("div", {
          "class": "grid grid-cols-2 gap-4"
        }, [h("label", ["Ulica", h("input", _mergeJSXProps7([{
          "attrs": {
            "type": "text",
            "autocomplete": "street-address"
          }
        }, bind(props.form.deliveryForm, 'street')]))]), h("label", ["Ku\u0107ni broj", h("input", _mergeJSXProps8([{
          "attrs": {
            "type": "text"
          }
        }, bind(props.form.deliveryForm, 'houseNumber')]))])]), h("div", {
          "class": "grid grid-cols-2 gap-4"
        }, [h("label", ["Po\u0161tanski broj", h("input", _mergeJSXProps9([{
          "attrs": {
            "type": "text",
            "autocomplete": "postal-code"
          }
        }, bind(props.form.deliveryForm, 'zipCode')]))]), h("label", ["Grad", h("input", _mergeJSXProps10([{
          "attrs": {
            "type": "text",
            "autocomplete": "address-level2"
          }
        }, bind(props.form.deliveryForm, 'city')]))])]), h("label", ["Dr\u017Eava", h("select", _mergeJSXProps11([{
          "attrs": {
            "autocomplete": "country"
          }
        }, bind(props.form.deliveryForm, 'country')]), [h("option", {
          "domProps": {
            "value": ''
          }
        }, ["Odaberite dr\u017Eavu"]), props.allowedCountries.map(country => h("option", {
          "key": country.iso,
          "domProps": {
            "value": country.iso
          }
        }, [country.name]))])])]), h("label", ["Use different billing address", h("input", {
          "attrs": {
            "type": "checkbox"
          },
          "domProps": {
            "checked": props.options.useDifferentBillingAddress
          },
          "on": {
            "input": checkedFromInput(v => props.options.useDifferentBillingAddress = v)
          }
        })]), props.options.useDifferentBillingAddress && h("fieldset", {
          "class": "grid gap-4 w-64"
        }, [h("div", {
          "class": "grid grid-cols-2 gap-4"
        }, [h("label", ["Ime", h("input", _mergeJSXProps12([{
          "attrs": {
            "type": "text",
            "autocomplete": "given-name"
          }
        }, bind(props.form.billingForm, 'firstName')]))]), h("label", ["Prezime", h("input", _mergeJSXProps13([{
          "attrs": {
            "type": "text",
            "autocomplete": "family-name"
          }
        }, bind(props.form.billingForm, 'lastName')]))])]), h("label", ["E-mail", h("input", _mergeJSXProps14([{
          "attrs": {
            "type": "text",
            "autocomplete": "email"
          }
        }, bind(props.form.billingForm, 'contactEmail')]))]), h("label", ["Telefon", h("input", _mergeJSXProps15([{
          "attrs": {
            "type": "text",
            "autocomplete": "tel"
          }
        }, bind(props.form.billingForm, 'contactPhone')]))]), h("div", {
          "class": "grid grid-cols-2 gap-4"
        }, [h("label", ["Ulica", h("input", _mergeJSXProps16([{
          "attrs": {
            "type": "text",
            "autocomplete": "street-address"
          }
        }, bind(props.form.billingForm, 'street')]))]), h("label", ["Ku\u0107ni broj", h("input", _mergeJSXProps17([{
          "attrs": {
            "type": "text"
          }
        }, bind(props.form.billingForm, 'houseNumber')]))])]), h("div", {
          "class": "grid grid-cols-2 gap-4"
        }, [h("label", ["Po\u0161tanski broj", h("input", _mergeJSXProps18([{
          "attrs": {
            "type": "text",
            "autocomplete": "postal-code"
          }
        }, bind(props.form.billingForm, 'zipCode')]))]), h("label", ["Grad", h("input", _mergeJSXProps19([{
          "attrs": {
            "type": "text",
            "autocomplete": "address-level2"
          }
        }, bind(props.form.billingForm, 'city')]))])]), h("label", ["Dr\u017Eava", h("select", _mergeJSXProps20([{
          "attrs": {
            "autocomplete": "country"
          }
        }, bind(props.form.billingForm, 'country')]), [h("option", {
          "domProps": {
            "value": ''
          }
        }, ["Odaberite dr\u017Eavu"]), props.allowedCountries.map(country => h("option", {
          "key": country.iso,
          "domProps": {
            "value": country.iso
          }
        }, [country.name]))])])]), props.consents.map(consent => {
          const acceptedConsents = props.form.acceptedConsents;
          const isConsentChecked = acceptedConsents.includes(consent.uniqueNumber);
          const toggleConsent = v => {
            if (isConsentChecked) {
              const consentIndex = acceptedConsents.indexOf(v);
              acceptedConsents.splice(consentIndex, 1);
            } else {
              acceptedConsents.push(v);
            }
          };
          return h("div", {
            "key": consent.uniqueNumber
          }, [h("div", {
            "class": "flex justify-start items-center space-x-3 px-2 py-1"
          }, [h("input", {
            "attrs": {
              "type": "checkbox"
            },
            "domProps": {
              "checked": isConsentChecked,
              "value": consent.uniqueNumber
            },
            "on": {
              "input": valueFromInput(() => toggleConsent(consent.uniqueNumber))
            }
          }), h("div", {
            "domProps": {
              "innerHTML": consent.displayName
            }
          })])]);
        }), h("button", {
          "attrs": {
            "type": "submit"
          }
        }, ["Dalje"])])])
      }
    }), h("h2", ["Shipping methods"]), h(ControllerShipping, {
      "scopedSlots": {
        default: props => h("form", _mergeJSXProps22([{}, {
          directives: [{
            name: 'show',
            value: props.isStepActive
          }]
        }, {
          "on": {
            "submit": preventDefault(props.submit)
          }
        }]), [props.shippingMethods.map(method => h("div", [h("label", {
          "class": "flex items-center",
          "key": method.id
        }, [h("input", {
          "attrs": {
            "type": "radio"
          },
          "domProps": {
            "value": method.id,
            "checked": method.id === props.form.methodId
          },
          "on": {
            "input": valueFromInput(v => props.form.methodId = Number(v))
          }
        }), h("div", {
          "class": "ml-2 inline-block"
        }, [method.name, h("br"), method.description])]), method.id === props.form.methodId && method.options && method.options.length && h("div", {
          "class": "ml-4"
        }, [method.options.map(option => h("label", {
          "class": "flex items-center",
          "key": option.id
        }, [h("input", {
          "attrs": {
            "type": "radio"
          },
          "domProps": {
            "value": option.id,
            "checked": option.id === props.form.branchId
          },
          "on": {
            "input": valueFromInput(v => props.form.branchId = Number(v))
          }
        }), h("div", {
          "class": "ml-2 inline-block"
        }, [option.name])]))])])), h("button", {
          "attrs": {
            "type": "button"
          },
          "on": {
            "click": () => props.goToPreviousStep()
          }
        }, ["Nazad"]), h("button", {
          "attrs": {
            "type": "submit"
          }
        }, ["Dalje"])])
      }
    }), h("h2", ["Payment methods"]), h(ControllerPayment, {
      "scopedSlots": {
        default: props => h("form", _mergeJSXProps23([{}, {
          directives: [{
            name: 'show',
            value: props.isStepActive
          }]
        }, {
          "on": {
            "submit": preventDefault(props.submit)
          }
        }]), [props.paymentMethods.map(method => h("label", {
          "class": "flex items-center",
          "key": method.id
        }, [h("input", {
          "attrs": {
            "type": "radio"
          },
          "domProps": {
            "value": method.id,
            "checked": method.id === props.form.methodId
          },
          "on": {
            "input": valueFromInput(v => props.form.methodId = Number(v))
          }
        }), h("div", {
          "class": "ml-2 inline-block"
        }, [method.name, h("br"), method.description])])), props.consents.map(consent => {
          const acceptedConsents = props.form.acceptedConsents;
          const isConsentChecked = acceptedConsents.includes(consent.uniqueNumber);
          const toggleConsent = v => {
            if (isConsentChecked) {
              const consentIndex = acceptedConsents.indexOf(v);
              acceptedConsents.splice(consentIndex, 1);
            } else {
              acceptedConsents.push(v);
            }
          };
          return h("div", {
            "key": consent.uniqueNumber
          }, [h("div", {
            "class": "flex justify-start items-center space-x-3 px-2 py-1"
          }, [h("input", {
            "attrs": {
              "type": "checkbox"
            },
            "domProps": {
              "checked": isConsentChecked,
              "value": consent.uniqueNumber
            },
            "on": {
              "input": valueFromInput(() => toggleConsent(consent.uniqueNumber))
            }
          }), h("div", {
            "domProps": {
              "innerHTML": consent.displayName
            }
          })])]);
        }), h("button", {
          "attrs": {
            "type": "button"
          },
          "on": {
            "click": () => props.goToPreviousStep()
          }
        }, ["Nazad"]), h("button", {
          "attrs": {
            "type": "submit"
          }
        }, ["Idemo dalje"])])
      }
    }), h("div", {
      "attrs": {
        "id": 'paypal-button-container'
      }
    })]);
  }
});
export default CheckoutView;