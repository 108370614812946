import consola from 'consola';
import CurlHelper from './CurlHelper';
const responseErrored = error => 'response' in error && !!error.response;
const requestErrored = error => 'request' in error && !!error.request;
const formatError = error => {
  let message = '';
  let statusCode;
  let data;
  if (responseErrored(error)) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const {
      data: resData,
      status
    } = error.response;
    const {
      config
    } = error;
    const isVerbose = response => typeof response !== 'string' && 'error' in response;
    if (isVerbose(resData)) {
      message = resData.error.payload.message;
      statusCode = resData.error.statusCode;
    } else {
      message = typeof resData === 'string' ? resData : resData.data.errorMessage || resData.message;
      statusCode = status;
    }
    data = resData;
    consola.error(statusCode, message);
    if (isVerbose(resData)) {
      const {
        error
      } = resData.error.payload;
      consola.error('GB Error', error);
    }
    consola.error(new CurlHelper(config).generateCommand());
  } else if (requestErrored(error)) {
    // The request was made but no response was received
    // `e.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    const {
      request
    } = error;
    const onServer = request => 'setRequestHeader' in request;
    if (onServer(request)) {
      message = request.statusText || `${error.name}: ${error.message}`;
      statusCode = request.status;
    } else {
      message = error.message;
      statusCode = 0;
    }
    consola.error(request);
  } else {
    // Something happened in setting up the request that triggered an Error
    consola.error('Error', error.message);
    message = `${error.name}: ${error.message}`;
    statusCode = 0;
  }
  return {
    message,
    statusCode,
    data
  };
};
export { formatError };