// Types
import mixins from "vue-typed-mixins";
// Enums
import { ProductFormat } from '../../enums/Product';
import { CartType } from '../../enums/Cart';
import { Modals } from '../../enums/Modals';

// Utils
import { WithPartialInject } from '../../utils/withInject';

// Mixins
import ControllerBase from '../../mixins/ControllerBase';
import ProductListProvider from '../../mixins/ProductListProvider';
import CartModalEvents from '../modals/CartModalEvents';
import BundleCustomizationModalEvents from '../modals/BundleCustomizationModalEvents';
const inject = WithPartialInject('selectedBundle');
export const isProductBundleProduct = to => 'product' in to && typeof to.product === 'number' && 'quantity' in to && 'format' in to;
export const isProductRecordBundleProduct = to => 'product' in to && typeof to.product === 'object' && to.product !== null && 'id' in to.product && 'format' in to.product && 'quantity' in to;
const isBundleProduct = to => 'id' in to && 'quantity' in to && 'format' in to;
const ControllerBundleCustomization = mixins(ControllerBase, inject, CartModalEvents, BundleCustomizationModalEvents, ProductListProvider).extend({
  name: 'ControllerBundleCustomization',
  provide() {
    return {
      refreshBundleConfiguration: this.refreshBundleConfiguration,
      refreshBundleCustomOption: this.refreshBundleCustomOption
    };
  },
  data() {
    return {
      options: {
        quantity: 1
      },
      // Used by nested ControllerProduct, whenever its active product changes or when new custom option is set
      bundleCustomization: []
    };
  },
  computed: {
    cartState() {
      const empty = {
        qty: 0
      };
      if (!this.selectedBundle) return empty;
      return this.$cart.findProduct(this.selectedBundle) || empty;
    },
    wishlistState() {
      const wishlisted = !!this.$wishlist.items.find(item => {
        var _this$selectedBundle;
        return item.product_id === ((_this$selectedBundle = this.selectedBundle) === null || _this$selectedBundle === void 0 ? void 0 : _this$selectedBundle.id);
      });
      return {
        wishlisted
      };
    },
    addableToCart() {
      if (!this.selectedBundle || !this.bundleCustomization.length) return false;
      return !this.bundleCustomization.find(bundledProductConfig => {
        const {
          mainProduct,
          activeProduct = null
        } = bundledProductConfig;
        return mainProduct.format === ProductFormat.Bundle || mainProduct.format === ProductFormat.Configurable && (!(activeProduct !== null && activeProduct !== void 0 && activeProduct.id) || activeProduct.id === mainProduct.id || activeProduct.format !== ProductFormat.Simple && activeProduct.format !== ProductFormat.EProduct);
      });
    },
    removableFromCart() {
      return !!(this.selectedBundle && this.cartState.itemId && this.cartState.qty);
    }
  },
  async fetch() {
    await this.fetchBundledProducts();
  },
  methods: {
    async fetchBundledProducts() {
      var _this$selectedBundle2, _this$selectedBundle3;
      if (!((_this$selectedBundle2 = this.selectedBundle) !== null && _this$selectedBundle2 !== void 0 && (_this$selectedBundle3 = _this$selectedBundle2.bundles) !== null && _this$selectedBundle3 !== void 0 && _this$selectedBundle3.length)) return;
      const productIds = [];

      // Array.map is uncallable per ts because BundledProduct unioned types have no overlap
      this.selectedBundle.bundles.forEach(bundledProduct => {
        if (isProductBundleProduct(bundledProduct)) productIds.push(bundledProduct.product);else if (isProductRecordBundleProduct(bundledProduct)) productIds.push(bundledProduct.product.id);else productIds.push(bundledProduct.id);
      });
      try {
        const responses = await Promise.all(this.genPromises(productIds));
        const errorExists = !!responses.find(res => res.errored);
        if (errorExists) throw new Error('Fetching one or more bundled products failed');
        const bundledProducts = responses.map(res => res.data);
        this.prepareBundleCustomization(bundledProducts);

        // TODO: typing inside ProductListProvider is fucked, fix that and remove ts-ignore
        // @ts-ignore
        this.setProducts(bundledProducts);
      } catch (e) {
        console.error(e.message);
        this.$toast.error(this.$t('error.bundleItemsNotFetched'));
      }
    },
    genPromises(ids) {
      const {
        storeID
      } = this.$application.store || {};
      if (!storeID) throw new Error('Store is not defined');
      return ids.reduce((promises, id) => {
        promises.push(this.$api.product.web.get({
          id,
          storeId: storeID
        }));
        return promises;
      }, []);
    },
    prepareBundleCustomization(products) {
      const genConfigPerProduct = product => {
        const {
          id,
          format
        } = product;
        const config = {
          mainProduct: {
            id,
            format
          },
          customOptionsForm: {}
        };
        if (format === ProductFormat.Configurable) {
          config.activeProduct = {
            id: null,
            format: null
          };
        }
        return config;
      };
      const initialConfiguration = products.map(genConfigPerProduct);
      this.$set(this, 'bundleCustomization', initialConfiguration);
    },
    refreshBundleConfiguration(mainProductId, newActiveProduct) {
      const productConfig = this.bundleCustomization.find(bundledProduct => bundledProduct.mainProduct.id === mainProductId);
      if (!(productConfig !== null && productConfig !== void 0 && productConfig.activeProduct)) return;
      const {
        id,
        format
      } = newActiveProduct;
      this.$set(productConfig, 'activeProduct', {
        id,
        format
      });
    },
    refreshBundleCustomOption(mainProductId, optionLabel, value) {
      const productConfig = this.bundleCustomization.find(bundledProduct => bundledProduct.mainProduct.id === mainProductId);
      if (!productConfig) {
        console.log(`Product config for product ${mainProductId} not found!`);
        return;
      }
      this.$set(productConfig.customOptionsForm, optionLabel, value);
    },
    parseQuantity(quantity) {
      const parsedQuantity = typeof quantity === 'string' ? parseInt(quantity) : quantity;
      if (isNaN(parsedQuantity) || parsedQuantity <= 0) {
        this.showErrorToast('error.invalidQuantity');
        return;
      }
      return parsedQuantity;
    },
    async addToCart() {
      var _this$bundleCustomiza;
      if (!this.selectedBundle) return;
      if (!this.addableToCart) {
        this.showInfoToast('core.notifications.configurationNotSet');
        return;
      }
      const parsedQuantity = this.parseQuantity(this.options.quantity);
      if (!parsedQuantity) return;

      // Keep in mind that this.selectedBundle is always configurabile

      if (!((_this$bundleCustomiza = this.bundleCustomization) !== null && _this$bundleCustomiza !== void 0 && _this$bundleCustomiza.length)) return;

      // At this point, bundle configuration has to be properly set (this.addableToCart takes care of that)
      const bundleOptions = this.bundleCustomization.map(bundledProductConfig => {
        // If there is an activeProduct, that means that product is configurabile
        const {
          mainProduct,
          activeProduct = null,
          customOptionsForm
        } = bundledProductConfig;
        const hasPersonalisation = !!Object.keys(customOptionsForm).length;
        const options = hasPersonalisation ? {
          personalizations: customOptionsForm
        } : null;
        return activeProduct ?
        // activeProduct.id should never be null since addableToCart takes care of that, thats why the exclamation mark
        {
          main: mainProduct.id,
          selected: activeProduct.id,
          options
        } : {
          id: mainProduct.id,
          options
        };
      });

      // TODO: needs to accept options
      const request = this.$cart.addItem(this.selectedBundle, parsedQuantity, {
        bundleOptions
      });
      await this.sendRequest({
        request,
        eventName: 'added-to-cart',
        tPath: 'core.notifications.addedToCart'
      });
    },
    async removeFromCart() {
      if (!this.removableFromCart) {
        this.showInfoToast('core.notifications.itemNotInCart');
        return;
      }
      const request = this.$cart.deleteItem(this.cartState.itemId);
      await this.sendRequest({
        request,
        eventName: 'removed-from-cart',
        tPath: 'core.notifications.removedFromCart'
      });
    },
    async addToWishlist() {
      if (!this.selectedBundle) return;
      const request = this.$api.cms.items.create(this.selectedBundle.id);
      const tPath = this.wishlistState.wishlisted ? 'core.notifications.removedFromWishlist' : 'core.notifications.addedToWishlist';
      await this.sendRequest({
        request,
        eventName: 'added-to-wishlist',
        tPath,
        ignoreCartBehaviour: true,
        ignoreStatusUpdate: true,
        fetchWishlistOnSuccess: true
      });
    },
    async sendRequest(options) {
      const {
        request,
        eventName,
        tPath,
        ignoreStatusUpdate
      } = options;
      const response = await this.callWithStatusUpdate(request, eventName, this.selectedBundle, ignoreStatusUpdate);
      if (response.errored) return;
      if (options.fetchWishlistOnSuccess) {
        const wishlist = await this.$wishlist.fetchWishlist();
        if (wishlist.errored) return;
      }
      this.showSuccessToast(tPath);
      if (options.ignoreCartBehaviour) return;
      if (this.$application.cartAdditionBehaviour === undefined) {
        await this.$application.fetchCheckoutSettings();
      }
      this.hideBundleCustomizationModal();
      this.handleCartBehaviour();
    },
    handleCartBehaviour() {
      const {
        cartAdditionBehaviour
      } = this.$application;
      if (cartAdditionBehaviour === CartType.ShowPopup) {
        this.showCartModal();
      } else if (cartAdditionBehaviour === CartType.RedirectToCart) {
        this.$router.push(this.localePath(this.$Page.Cart));
      }
    },
    showSuccessToast(tPath) {
      this.$toast.success(this.$t(tPath, {
        entity: this.$t('core.entity.bundle')
      }));
    },
    showErrorToast(tPath) {
      this.$toast.error(this.$t(tPath));
    },
    showInfoToast(tPath) {
      this.$toast.info(this.$t(tPath, {
        entity: this.$t('core.entity.bundle')
      }));
    },
    showCartModal() {
      this.$modal.show(Modals.Cart);
    },
    hideBundleCustomizationModal() {
      this.$modal.hide(Modals.BundleCustomization);
    }
  },
  render() {
    const {
      selectedBundle: item,
      options,
      status,
      cartState,
      addToCart,
      removeFromCart,
      addToWishlist,
      wishlistState,
      removableFromCart
    } = this;
    const slotProps = {
      item,
      options,
      status,
      cartState,
      addToCart,
      removeFromCart,
      addToWishlist,
      wishlistState,
      removableFromCart
    };
    return this.renderContainer(slotProps, ['item']);
  }
});
export default ControllerBundleCustomization;