export let AddressType;
(function (AddressType) {
  AddressType["Main"] = "main";
  AddressType["Billing"] = "billing";
  AddressType["Shipping"] = "shipping";
  AddressType["Other"] = "other";
})(AddressType || (AddressType = {}));
export let CheckoutSteps;
(function (CheckoutSteps) {
  CheckoutSteps["Address"] = "address";
  CheckoutSteps["Payment"] = "payment";
  CheckoutSteps["Shipping"] = "shipping";
})(CheckoutSteps || (CheckoutSteps = {}));