// Types
import mixins from "vue-typed-mixins";
// Mixins
import BaseInjector from '../../mixins/BaseInjector';
import ControllerProductProviderData from '../../providerData/ControllerProductProviderData';
import { WithPartialInject } from '../../utils/withInject';
import ControllerBase from '../../mixins/ControllerBase';
const ControllerProductConfiguration = mixins(WithPartialInject('switchProduct'), BaseInjector(ControllerProductProviderData), ControllerBase).extend({
  name: 'ControllerProductConfiguration',
  data() {
    return {
      models: {}
    };
  },
  computed: {
    activeProduct() {
      var _this$computedItems, _this$computedItems$m, _this$computedItems$m2;
      return (_this$computedItems = this.computedItems) === null || _this$computedItems === void 0 ? void 0 : (_this$computedItems$m = _this$computedItems.mainProduct) === null || _this$computedItems$m === void 0 ? void 0 : (_this$computedItems$m2 = _this$computedItems$m.associatedProducts) === null || _this$computedItems$m2 === void 0 ? void 0 : _this$computedItems$m2.find(p => Object.values(p.attributes).every(v => Object.values(this.models).includes(Number(v))));
    },
    attributes() {
      var _this$computedItems2, _this$computedItems2$;
      const attrs = (_this$computedItems2 = this.computedItems) === null || _this$computedItems2 === void 0 ? void 0 : (_this$computedItems2$ = _this$computedItems2.mainProduct) === null || _this$computedItems2$ === void 0 ? void 0 : _this$computedItems2$.associatedProductsAttributes;
      if (!attrs) return [];
      return attrs.map(attr => {
        const {
          models
        } = this;
        return {
          label: attr.label,
          type: attr.inputType,
          options: attr.values,
          get value() {
            return models[attr.attributeCode];
          },
          set value(v) {
            models[attr.attributeCode] = v;
          }
        };
      });
    }
  },
  watch: {
    'activeProduct.id': {
      handler(id) {
        var _this$switchProduct;
        (_this$switchProduct = this.switchProduct) === null || _this$switchProduct === void 0 ? void 0 : _this$switchProduct.call(this, id);
      }
    },
    'computedItems.mainProduct.associatedProductsAttributes': {
      immediate: true,
      handler(v) {
        v === null || v === void 0 ? void 0 : v.forEach(attr => {
          const value = attr.values.find(v => v.shopDefault);
          this.$set(this.models, attr.attributeCode, (value === null || value === void 0 ? void 0 : value.id) || '');
        });
      }
    }
  },
  render() {
    const slotProps = this.attributes.map(item => ({
      item
    }));
    return this.renderContainer(slotProps);
  }
});
export default ControllerProductConfiguration;