// Types
import Vue from 'vue';
const StatusUpdateable = Vue.extend({
  data() {
    return {
      status: {
        pending: false,
        errored: false
      }
    };
  },
  methods: {
    async callWithStatusUpdate(callback, eventName, eventData, ignoreStatusUpdate = false) {
      !ignoreStatusUpdate && (this.status.pending = true);
      const res = await callback;
      if (!ignoreStatusUpdate) {
        this.status.pending = false;
        this.status.errored = res.errored;
      }
      eventName && !res.errored && this.$emit(eventName, eventData);
      return res;
    }
  }
});
export default StatusUpdateable;