const valueFromInput = callback => {
  return e => callback(e.target.value);
};
const checkedFromInput = callback => {
  return e => callback(e.target.checked);
};
const preventDefault = callback => e => {
  e.preventDefault();
  callback(e);
};
export { valueFromInput, checkedFromInput, preventDefault };