import './CmsAdminLayout.css';

// Types
import mixins from "vue-typed-mixins";
// Components
import AdminButton from '../components/ui/AdminButton';
import AdminGlassPanel from '../components/ui/AdminGlassPanel';

// Utils
import { valueFromInput } from '../utils/jsxHelpers';

// Main
const CmsAdminLayout = mixins().extend({
  name: 'CmsAdminLayout',
  provide() {
    return {
      adminLayout: this
    };
  },
  data() {
    return {
      consents: [],
      consentAssignation: [],
      navigationItems: [{
        to: '/admin/consent',
        label: 'core.navigation.consents'
      }, {
        to: '/admin/templates',
        label: 'core.navigation.templates'
      }]
    };
  },
  computed: {
    availableLocales() {
      const locales = this.$i18n.locales;
      const normalisedLocales = locales === null || locales === void 0 ? void 0 : locales.map(locale => typeof locale === 'string' ? {
        code: locale
      } : locale);
      if (!normalisedLocales) return [];
      const localesWithoutCurrent = normalisedLocales.filter(locale => {
        return locale.code !== this.$i18n.locale;
      });
      return normalisedLocales || localesWithoutCurrent || [];
    }
  },
  methods: {
    async fetchConsents() {
      const res = await this.$api.gdpr.web.consent.filter({});
      if (res.errored) return;
      const c = this.consents;
      c.splice(0, c.length, ...res.data.records);
    },
    async fetchConsentAssignation() {
      const res = await this.$api.cms.consent.filter({
        perPage: 50
      });
      if (res.errored) return;
      const c = this.consentAssignation;
      c.splice(0, c.length, ...res.data.data);
    },
    async submitConsentAssignation({
      assigned_to,
      consents,
      requiredConsents
    }) {
      const res = await this.$api.cms.consent.create({
        assigned_to,
        consents,
        requiredConsents
      });
      if (res.errored) return res;
      const ca = this.consentAssignation;
      const index = ca.findIndex(assignation => assignation.assigned_to === assigned_to);
      const newAssignation = {
        assigned_to,
        consents: [...consents],
        requiredConsents: [...requiredConsents]
      };
      if (index < 0) {
        ca.push(newAssignation);
      } else {
        ca.splice(index, 1, newAssignation);
      }
      return res;
    },
    async switchLanguage(code) {
      this.$router.push(this.switchLocalePath(code));
    }
  },
  render() {
    const h = arguments[0];
    return h("div", {
      "attrs": {
        "id": 'cms-admin-layout'
      }
    }, [h(AdminGlassPanel, {
      "attrs": {
        "id": 'layout-panel'
      }
    }, [h("header", {
      "class": 'p-4 flex space-x-4 items-center'
    }, [h("img", {
      "class": 'h-10',
      "attrs": {
        "src": '/cms-public/logo-simple.svg'
      }
    }), h("h3", [this.$t('core.navigation.adminPanel')]), h("select", {
      "class": "border-white border border-opacity-35 bg-black rounded-xl p-2 bg-opacity-10",
      "domProps": {
        "value": this.$i18n.locale
      },
      "on": {
        "input": valueFromInput(v => this.switchLanguage(v))
      }
    }, [this.availableLocales.map(locale => h("option", {
      "class": 'text-black',
      "key": 'locale-' + locale.code,
      "domProps": {
        "value": locale.code
      }
    }, [locale.name]))]), h("div", {
      "class": 'flex-grow'
    }), h(AdminButton, {
      "attrs": {
        "secondary": true,
        "to": this.localePath('/')
      }
    }, [this.$t('core.navigation.backToSite')])]), h("hr"), h("div", {
      "class": 'flex flex-grow min-h-0'
    }, [h("nav", [h("ul", [this.navigationItems.map(item => h("li", [h("nuxt-link", {
      "attrs": {
        "to": this.localePath(item.to)
      },
      "class": "cursor-pointer h-12 flex items-center transition-colors duration-150 px-4"
    }, [this.$t(item.label)])]))])]), h("div"), h("main", {
      "class": 'flex-grow flex flex-col min-h-0'
    }, [h("nuxt", {
      "attrs": {
        "keep-alive": true
      }
    })])])])]);
  }
});
export default CmsAdminLayout;