// Types
import mixins from "vue-typed-mixins"; // Mixins
import ControllerBase from '../../../mixins/ControllerBase';
import { WithPartialInject } from '../../../utils/withInject';

// Utils
import consola from 'consola';

// Enums
import { AddressType, CheckoutSteps } from '../../../enums/Checkout';
const ControllerShipping = mixins(ControllerBase, WithPartialInject('delivery', 'stepState', 'goToNextStep', 'goToPreviousStep')).extend({
  name: 'ControllerShipping',
  data() {
    var _this$$cart$storage$c;
    return {
      shippingMethods: [],
      form: {
        methodId: ((_this$$cart$storage$c = this.$cart.storage.cart) === null || _this$$cart$storage$c === void 0 ? void 0 : _this$$cart$storage$c.deliveryMethodId) || 0,
        branchId: null
      },
      lastSavedMethod: 0,
      lastSavedBranch: null,
      debounce: null
    };
  },
  computed: {
    isStepActive() {
      return this.stepState.activeStep === CheckoutSteps.Shipping;
    },
    user() {
      return this.$auth.user || this.$cart.guestUser;
    },
    branchId() {
      const {
        options
      } = this.$cart.storage.cart || {};
      if (!options) return null;
      return JSON.parse(options).deliveryBranchId || null;
    }
  },
  watch: {
    'delivery.country': 'getApplicableDeliveryMethods',
    // 'form.methodId': 'saveDeliveryMethod',
    'user.id': 'getApplicableDeliveryMethods',
    'user.addresses': 'getApplicableDeliveryMethods' // TODO: debounce or smth
  },

  created() {
    this.getApplicableDeliveryMethods();
    this.form.branchId = this.branchId;
  },
  methods: {
    async getApplicableDeliveryMethods() {
      var _this$$application$co, _this$$application$cu;
      if (this.$cart.isEProductCart) return;
      if (!this.$cart.session) {
        consola.error('Cart session not initialised!');
        return;
      }
      const {
        user
      } = this;
      if (!user || !user.id || !user.addresses) return;
      if (!this.$application.countries.length) {
        await this.$application.fetchCountries();
      }
      const {
        country
      } = this.delivery;
      const countryId = (_this$$application$co = this.$application.countries.getByIso(country || '')) === null || _this$$application$co === void 0 ? void 0 : _this$$application$co.id;
      if (!countryId) return;
      const findAddress = type => user.addresses.find(address => address.type === type);
      const billingAddress = findAddress(AddressType.Main);
      const shippingAddress = findAddress(AddressType.Shipping);
      if (!(billingAddress !== null && billingAddress !== void 0 && billingAddress.id) || !(shippingAddress !== null && shippingAddress !== void 0 && shippingAddress.id)) return;
      const body = {
        userId: user.id,
        cartId: this.$cart.session.id,
        protectCode: this.$cart.session.protectCode,
        countryId,
        currencyId: ((_this$$application$cu = this.$application.currency) === null || _this$$application$cu === void 0 ? void 0 : _this$$application$cu.id) || 0,
        userBillingAddressId: billingAddress.id,
        userShippingAddressId: shippingAddress.id
      };
      const {
        delivery
      } = this.$api.ecommerce.checkout;
      const res = await delivery.getApplicableDeliveryMethods(body);
      const s = this.shippingMethods;
      if (res.errored) {
        s.splice(0);
        return;
      }
      s.splice(0, s.length, ...res.data);
    },
    async saveDeliveryMethod(newMethodId = 0, oldMethodId = 0, newBranchId = null, oldBranchId = null) {
      if (newMethodId === this.lastSavedMethod && newBranchId === this.lastSavedBranch) return;
      const res = await this.callWithStatusUpdate(this.$cart.updateDeliveryMethod(newMethodId, newBranchId));
      if (res.errored) {
        this.form.methodId = oldMethodId;
        this.form.branchId = oldBranchId;
        return;
      }
      this.lastSavedMethod = newMethodId;
      this.lastSavedBranch = newBranchId;
    },
    validate() {
      if (!this.form.methodId) {
        this.$toast.error(this.$t('error.shippingMethodUndefined'));
        return false;
      }
      const selectedMethod = this.shippingMethods.find(method => method.id === this.form.methodId);
      // Somehow method was not found
      if (!selectedMethod) {
        this.$toast.error(this.$t('error.shippingMethodNotFound'));
        return false;
      }
      // Selected method does not include branches
      else if (!selectedMethod.options) return true;
      // Selected method includes branches, but no branch is selected
      else if (!this.form.branchId) {
        this.$toast.error(this.$t('error.shippingBranchUndefined'));
        return false;
      }
      // Selected method includes branches and branch is selected
      return true;
    },
    async submit() {
      var _this$goToNextStep;
      if (!this.validate()) {
        return;
      }
      await this.saveDeliveryMethod(this.form.methodId, this.lastSavedMethod, this.form.branchId, this.lastSavedBranch);
      (_this$goToNextStep = this.goToNextStep) === null || _this$goToNextStep === void 0 ? void 0 : _this$goToNextStep.call(this);
      this.$emit("submitted");
    }
  },
  render() {
    const {
      shippingMethods,
      form,
      status,
      isStepActive,
      submit,
      goToPreviousStep
    } = this;
    const slotProps = {
      shippingMethods,
      form,
      status,
      isStepActive,
      submit,
      goToPreviousStep
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerShipping;