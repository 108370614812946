/**
 * Takes address with country in ISO string format and returns a new address
 * with the same country as an ID number
 * @param address address with country property in ISO format
 * @param application active application service
 */
const convertAddressCountryToId = (address, application) => {
  var _application$countrie;
  return {
    ...address,
    country: (_application$countrie = application.countries.getByIso(address.country || '')) === null || _application$countrie === void 0 ? void 0 : _application$countrie.id
  };
};
export { convertAddressCountryToId };