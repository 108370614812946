// Types
import Vue from 'vue';

// Components

const ForgotPasswordView = Vue.extend({
  name: 'ForgotPasswordView',
  render() {
    const h = arguments[0];
    return h("div", ["Forgot password?"]);
  }
});
export default ForgotPasswordView;