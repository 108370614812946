// Types
import mixins from "vue-typed-mixins";
// Components
import { CookieElement } from '../../overridableComponents';

// Mixins
import EditorContainer from '../../../mixins/EditorContainer';
import LayoutElementProvider from '../../../mixins/LayoutElementProvide';

// Enums
import { StaticElements } from '../../../enums/StaticElements';

// Main
const CookieContainer = mixins(EditorContainer, LayoutElementProvider(StaticElements.Cookie)).extend({
  name: 'CookieContainer',
  render() {
    const h = arguments[0];
    return h(CookieElement);
  }
});
export default CookieContainer;