// Types
import Vue from 'vue';

// Utils
import { preventDefault, valueFromInput } from '../../utils/jsxHelpers';

// Components
import ControllerChangePassword from '../controllers/user/ControllerChangePassword';
import ControllerLogin from '../controllers/user/ControllerLogin';
import ControllerRegister from '../controllers/user/ControllerRegister';
import ControllerWishlist from '../controllers/user/ControllerWishlist';
import ControllerProductList from '../controllers/ControllerProductList';
import ControllerProduct from '../controllers/ControllerProduct';
import ControllerOrderHistory from '../controllers/user/ControllerOrderHistory';
import ControllerUser from '../controllers/user/ControllerUser';
const ProfileView = Vue.extend({
  name: 'ProfileView',
  render() {
    const h = arguments[0];
    return h("div", [this.$auth.loggedIn && h(ControllerUser, {
      "scopedSlots": {
        default: props => h("form", {
          "on": {
            "submit": preventDefault(props.submit)
          }
        }, [h("label", ["First name", h("input", {
          "attrs": {
            "type": "text"
          },
          "domProps": {
            "value": props.form.firstName
          },
          "on": {
            "input": valueFromInput(v => props.form.firstName = v)
          }
        })]), h("label", ["Last name", h("input", {
          "attrs": {
            "type": "text"
          },
          "domProps": {
            "value": props.form.lastName
          },
          "on": {
            "input": valueFromInput(v => props.form.lastName = v)
          }
        })]), h("label", ["E-mail", h("input", {
          "attrs": {
            "type": "email"
          },
          "domProps": {
            "value": props.form.email
          },
          "on": {
            "input": valueFromInput(v => props.form.email = v)
          }
        })]), h("button", {
          "attrs": {
            "type": "submit"
          }
        }, ["Promijeni"])])
      }
    }), this.$auth.loggedIn && h(ControllerChangePassword, {
      "scopedSlots": {
        default: props => h("form", {
          "on": {
            "submit": preventDefault(props.submit)
          }
        }, [h("label", ["Old password", h("input", {
          "attrs": {
            "type": "password"
          },
          "domProps": {
            "value": props.form.oldPassword
          },
          "on": {
            "input": valueFromInput(v => props.form.oldPassword = v)
          }
        })]), h("label", ["New password", h("input", {
          "attrs": {
            "type": "password"
          },
          "domProps": {
            "value": props.form.newPassword
          },
          "on": {
            "input": valueFromInput(v => props.form.newPassword = v)
          }
        })]), h("label", ["Repeat new password", h("input", {
          "attrs": {
            "type": "password"
          },
          "domProps": {
            "value": props.form.newPasswordAgain
          },
          "on": {
            "input": valueFromInput(v => props.form.newPasswordAgain = v)
          }
        })]), h("button", {
          "attrs": {
            "type": "submit"
          }
        }, ["Save new password"])])
      }
    }), !this.$auth.loggedIn && h(ControllerLogin, {
      "scopedSlots": {
        default: props => h("form", {
          "on": {
            "submit": preventDefault(props.submit)
          }
        }, [h("label", ["Username", h("input", {
          "attrs": {
            "type": "text"
          },
          "domProps": {
            "value": props.form.username
          },
          "on": {
            "input": valueFromInput(v => props.form.username = v)
          }
        })]), h("label", ["Password", h("input", {
          "attrs": {
            "type": "password"
          },
          "domProps": {
            "value": props.form.password
          },
          "on": {
            "input": valueFromInput(v => props.form.password = v)
          }
        })]), h("button", {
          "attrs": {
            "type": "submit"
          }
        }, ["Prijavi"])])
      }
    }), !this.$auth.loggedIn && h(ControllerRegister, {
      "scopedSlots": {
        default: props => h("form", {
          "on": {
            "submit": preventDefault(props.submit)
          }
        }, [h("label", ["First name", h("input", {
          "attrs": {
            "type": "text"
          },
          "domProps": {
            "value": props.form.firstName
          },
          "on": {
            "input": valueFromInput(v => props.form.firstName = v)
          }
        })]), h("label", ["Last name", h("input", {
          "attrs": {
            "type": "text"
          },
          "domProps": {
            "value": props.form.lastName
          },
          "on": {
            "input": valueFromInput(v => props.form.lastName = v)
          }
        })]), h("label", ["E-mail", h("input", {
          "attrs": {
            "type": "email"
          },
          "domProps": {
            "value": props.form.email
          },
          "on": {
            "input": valueFromInput(v => props.form.email = v)
          }
        })]), h("label", ["Password", h("input", {
          "attrs": {
            "type": "password"
          },
          "domProps": {
            "value": props.form.password
          },
          "on": {
            "input": valueFromInput(v => props.form.password = v)
          }
        })]), h("button", {
          "attrs": {
            "type": "submit"
          }
        }, ["Registriraj"])])
      }
    }), this.$auth.loggedIn && h(ControllerWishlist, {
      "scopedSlots": {
        default: () => h(ControllerProductList, [h(ControllerProduct, {
          "scopedSlots": {
            default: props => {
              var _props$item;
              return h("div", [(_props$item = props.item) === null || _props$item === void 0 ? void 0 : _props$item.name]);
            }
          }
        })])
      }
    }), this.$auth.loggedIn && h(ControllerOrderHistory, {
      "scopedSlots": {
        default: props => h("div", [props.paginationState.totalItems])
      }
    }), this.$auth.loggedIn && h("button", {
      "on": {
        "click": () => this.$auth.logout()
      }
    }, ["logout"])]);
  }
});
export default ProfileView;