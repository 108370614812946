//Types
import Vue from 'vue';

// Components
import ControllerPostCategoryList from '../controllers/ControllerPostCategoryList';
import ControllerPostCategory from '../controllers/ControllerPostCategory';
import ControllerPostList from '../controllers/ControllerPostList';

// Main
const PostCategory = Vue.extend({
  name: 'PostCategory',
  render() {
    const h = arguments[0];
    return h("div", [h("h1", {
      "class": "font-bold text-3xl text-center my-6"
    }, ["POST CATEGORY VIEW"]), h(ControllerPostCategoryList, {
      "class": "bg-teal-300 my-6",
      "scopedSlots": {
        default: ({
          item,
          index
        }) => {
          return h("cms-link", {
            "attrs": {
              "to": item
            },
            "class": "block"
          }, [`Category ${index}: ${item.title}`]);
        }
      }
    }), h(ControllerPostCategory, {
      "scopedSlots": {
        default: ({
          item
        }) => {
          return h("div", [h("p", {
            "class": "font-semibold text-center"
          }, ["Posts from category:", ' ', h("span", {
            "class": "font-extrabold uppercase"
          }, [item.title])]), h(ControllerPostList, {
            "class": "bg-red-300 ",
            "scopedSlots": {
              default: props => h("div", [props.items.map((item, index) => h("cms-link", {
                "attrs": {
                  "to": item
                },
                "class": "block",
                "key": item.id
              }, [`Post ${index}: ${item.title}`])), h("div", {
                "class": 'space-x-2'
              }, [h("button", {
                "attrs": {
                  "type": 'button'
                },
                "class": 'bg-green-400 px-2',
                "on": {
                  "click": () => props.firstPage()
                }
              }, ["Prva stranica"]), h("button", {
                "attrs": {
                  "type": 'button'
                },
                "class": 'bg-green-400 px-2',
                "on": {
                  "click": () => props.previousPage()
                }
              }, ["Prethodna stranica"]), h("button", {
                "attrs": {
                  "type": 'button'
                },
                "class": 'bg-green-400 px-2',
                "on": {
                  "click": () => props.nextPage()
                }
              }, ["Sljede\u0107a stranica"]), h("button", {
                "attrs": {
                  "type": 'button'
                },
                "class": 'bg-green-400 px-2',
                "on": {
                  "click": () => props.lastPage()
                }
              }, ["Posljednja stranica"]), h("button", {
                "attrs": {
                  "type": 'button'
                },
                "class": 'bg-green-400 px-2',
                "on": {
                  "click": () => props.loadMore()
                }
              }, ["Daj jo\u0161"]), props.paginationState.onFirstPage && h("span", {
                "class": 'bg-red-400 px-2'
              }, ["na prvoj sam"]), props.paginationState.onLastPage && h("span", {
                "class": 'bg-red-400 px-2'
              }, ["na zadnjoj sam"]), props.status.pending && h("span", {
                "class": 'bg-purple-400 px-2'
              }, ["cekam"])])])
            }
          })]);
        }
      }
    })]);
  }
});
export default PostCategory;