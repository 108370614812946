import { Page } from '@gauss/cms-shared';
import consola from 'consola';
function genNavigationItemPath(navItem, i18nLocalePathFunc) {
  var _navItem$externalLink, _navItem$externalLink2;
  // If navigation item serves just as a parent for children links, it doesn't
  // need to have a link on it's own. In that case we return '/' because empty
  // string would break cms-link/nuxt-link (some normalise error).
  // TODO: Error the fuck out of this in future. You should be punished if you using cms-link in a wrong place
  if (!navItem.baseLink) return '/';
  const shouldOpenInNewTab = navItem.isExternal && !((_navItem$externalLink = navItem.externalLink) !== null && _navItem$externalLink !== void 0 && _navItem$externalLink.isStatic);
  if (shouldOpenInNewTab) return navItem.linkV2;

  // This is some bullshit hacky way of linking to static pages, e.g. "shop",
  // "blog/objave", "profile/profil" etc.
  if ((_navItem$externalLink2 = navItem.externalLink) !== null && _navItem$externalLink2 !== void 0 && _navItem$externalLink2.isStatic) {
    return i18nLocalePathFunc('/' + navItem.linkV2);
  }
  return navItem.linkV2 || navItem.link || '/';
}
function genTermPath(term, i18nLocalePathFunc, query) {
  if (term.typeSlug === 'post') {
    return i18nLocalePathFunc({
      name: Page.Post,
      params: {
        slug: term.slug
      },
      query
    });
  } else if (term.typeSlug === 'product') {
    return i18nLocalePathFunc({
      name: Page.Product,
      params: {
        slug: term.slug
      },
      query
    });
  } else if (term.typeSlug === 'page') {
    return i18nLocalePathFunc({
      name: Page.Post,
      params: {
        slug: term.slug
      },
      query
    });
  } else if (term.typeSlug === 'category') {
    return i18nLocalePathFunc({
      name: Page.Blog,
      params: {
        slug: term.slug
      },
      query
    });
  } else if (term.typeSlug === 'tag') {
    return i18nLocalePathFunc({
      name: Page.Blog,
      params: {
        slug: term.slug
      },
      query
    });
  }
  consola.error(`Cannot map term of type ${term.typeSlug}.`);
  return '/';
}
export { genNavigationItemPath, genTermPath };