// Types
import { forEachKey } from '../utils/hasKey';
import { WithPartialInject } from '../utils/withInject';
import mixins from 'vue-typed-mixins';
import Vue from 'vue';
const TypingFix = (o, pd) => Vue.extend({});
const BaseInjector = d =>
// (Vue as Vue.VueConstructor<Vue & Record<N, BaseProviderProvide<V>>>).extend({
mixins(WithPartialInject(d.providerName), TypingFix({
  [d.providerName]: d.items
}, d)).extend({
  computed: {
    computedItems() {
      const provider = this[d.providerName];
      if (!provider) return null;
      const stuff = {};
      forEachKey(d.items, key => {
        if (!provider[key]) return stuff[key] = this[key];
        const value = provider[key];
        if (Array.isArray(value)) {
          var _provider$tickets, _provider$tickets$key;
          // @ts-expect-error _uid is not given in interface, but it does exist
          const uid = this._uid;
          // @ts-ignore
          const i = provider === null || provider === void 0 ? void 0 : (_provider$tickets = provider.tickets) === null || _provider$tickets === void 0 ? void 0 : (_provider$tickets$key = _provider$tickets[key]) === null || _provider$tickets$key === void 0 ? void 0 : _provider$tickets$key[uid];
          if (i === undefined) return;
          stuff[key] = value[i];
        } else stuff[key] = value;
      });
      return stuff;
    }
  },
  created() {
    forEachKey(d.items, key => {
      var _this$d$providerName;
      return (_this$d$providerName = this[d.providerName]) === null || _this$d$providerName === void 0 ? void 0 : _this$d$providerName.registerController(key, this);
    });
  },
  beforeDestroy() {
    forEachKey(d.items, key => {
      var _this$d$providerName2;
      return (_this$d$providerName2 = this[d.providerName]) === null || _this$d$providerName2 === void 0 ? void 0 : _this$d$providerName2.unregisterController(key, this);
    });
  }
});
export default BaseInjector;
// // Types
// import { forEachKey } from '../utils/hasKey'
// import { BaseProviderProvide } from './BaseProvider'
// import { WithPartialInject } from '../utils/withInject'
// import mixins from 'vue-typed-mixins'
// import Vue from 'vue'
//
// const wtf = <O extends Record<string, unknown>, P extends pd>(o: O, pd: P) =>
//   (Vue as Vue.VueConstructor<
//     Vue & { computedItems: P['items'] | null } & Partial<
//         Record<keyof O, BaseProviderProvide<O[keyof O]>>
//       >
//   >).extend({})
//
// type it = Record<string, Record<string, unknown> | unknown[]>
//
// type pd = {
//   providerName: string
//   items: Record<string, unknown>
// }
//
// const BaseInjector = <
//   N extends string,
//   V extends Record<string, unknown>,
//   D extends pd
// >(
//   providerName: N,
//   items: V,
//   d: D
// ) =>
//   // (Vue as Vue.VueConstructor<Vue & Record<N, BaseProviderProvide<V>>>).extend({
//   mixins(
//     WithPartialInject<Record<N, V>>(providerName),
//     wtf({ [providerName]: items }, d)
//   ).extend({
//     computed: {
//       computedItems(): V | null {
//         const provider = this[d.providerName]
//         if (!provider) return null
//         const stuff = {} as V
//         forEachKey(items, (key) => {
//           if (!provider[key]) return (stuff[key] = this[key])
//           const value = provider[key]
//           if (Array.isArray(value)) {
//             // @ts-expect-error _uid is not given in interface, but it does exist
//             const uid = this._uid as number
//             const i = provider?.tickets?.[uid]
//             if (i === undefined) return
//             stuff[key] = value[i]
//           } else stuff[key] = value
//         })
//         return stuff
//       },
//     },
//     created() {
//       forEachKey(d.items, (key) =>
//         this[d.providerName]?.registerController(key, this)
//       )
//     },
//     beforeDestroy() {
//       forEachKey(d.items, (key) =>
//         this[d.providerName]?.unregisterController(key, this)
//       )
//     },
//   })
//
// export default BaseInjector