import mixins from "vue-typed-mixins"; // Components
import ControllerCart from '../controllers/ControllerCart';
import { preventDefault, valueFromInput } from '../../utils/jsxHelpers';
import AdminButton from '../ui/AdminButton';
import CmsLink from '../ui/CmsLink';
import ControllerProduct from '../controllers/ControllerProduct';
import ControllerCrossSell from '../controllers/ControllerCrossSell';
const CartModalView = mixins().extend({
  name: 'CartModalView',
  render() {
    const h = arguments[0];
    return h("div", {
      "class": "py-6 px-8 w-full h-full overflow-y-scroll"
    }, [h("div", {
      "class": "flex space-x-6 items-center justify-end"
    }, [h(AdminButton, {
      "attrs": {
        "secondary": true
      },
      "on": {
        "click": () => this.$emit('close')
      }
    }, [this.$t('core.action.close')]), h(CmsLink, {
      "attrs": {
        "to": this.$Page.Cart
      }
    }, [h(AdminButton, [this.$t('core.action.goToCart')])])]), h("div", {
      "class": "flex-1 my-5"
    }, [h("table", {
      "class": "w-full text-sm lg:text-base",
      "attrs": {
        "cellspacing": "0"
      }
    }, [h("thead", [h("tr", {
      "class": "h-12 uppercase"
    }, [h("th", {
      "class": "hidden md:table-cell"
    }), h("th", {
      "class": "text-left"
    }, ["Proizvod"]), h("th", {
      "class": "lg:text-right text-left pl-5 lg:pl-0"
    }, [h("span", {
      "class": "lg:hidden",
      "attrs": {
        "title": "Quantity"
      }
    }, ["Qtd"]), h("span", {
      "class": "hidden lg:inline"
    }, ["Koli\u010Dina"])]), h("th", {
      "class": "hidden text-right md:table-cell"
    }, ["Cijena"]), h("th", {
      "class": "text-right"
    }, ["Ukupno"])])]), h(ControllerCart, {
      "attrs": {
        "tag": "tbody"
      },
      "scopedSlots": {
        default: props => props.items.map(cartItem => h("tr", [h("td", {
          "class": "hidden pb-4 md:table-cell"
        }, [h("a", {
          "attrs": {
            "href": "#"
          }
        }, [h("cms-image", {
          "class": "w-20 rounded",
          "attrs": {
            "media": cartItem.productData.media
          }
        })])]), h("td", [h("p", [h("cms-link", {
          "attrs": {
            "to": cartItem
          },
          "class": "mb-2"
        }, [cartItem.productData.name])]), h("p", [cartItem.options]), h("button", {
          "attrs": {
            "type": "button"
          },
          "class": "text-gray-700",
          "on": {
            "click": () => props.deleteCartItem(cartItem)
          }
        }, [h("small", ["Ukloni"])])]), h("td", {
          "class": "justify-center md:justify-end md:flex mt-6"
        }, [h("div", {
          "class": "w-20 h-10"
        }, [h("div", {
          "class": "relative flex flex-row w-full h-8"
        }, [h("input", {
          "attrs": {
            "type": "number"
          },
          "domProps": {
            "value": cartItem.qty
          },
          "class": "w-full font-semibold text-center text-gray-700 bg-gray-200 outline-none focus:outline-none hover:text-black focus:text-black",
          "on": {
            "input": valueFromInput(v => cartItem.qty = Number(v))
          }
        })])])]), h("td", {
          "class": "hidden text-right md:table-cell"
        }, [h("span", {
          "class": "text-sm lg:text-base font-medium"
        }, [cartItem.prices.converted.finalPrice])]), h("td", {
          "class": "text-right"
        }, [h("span", {
          "class": "text-sm lg:text-base font-medium"
        }, [cartItem.prices.converted.row])])]))
      }
    })]), h("hr", {
      "class": "pb-6 mt-6"
    }), h("div", {
      "class": "my-4 mt-6 -mx-2 lg:flex"
    }, [h("div", {
      "class": "lg:px-2 lg:w-1/2"
    }, [h("div", {
      "class": "p-4 bg-gray-100 rounded-full"
    }, [h("h1", {
      "class": "ml-2 font-bold uppercase"
    }, ["Kupon\u010De"])]), h("div", {
      "class": "p-4"
    }, [h("p", {
      "class": "mb-4 italic"
    }, ["Lorem ipsum dolor sit amet, consectetur adipiscing elit."]), h(ControllerCart, {
      "class": "justify-center md:flex",
      "scopedSlots": {
        default: props => h("form", {
          "on": {
            "submit": preventDefault(props.cartState.couponCode ? props.removeCoupon : props.applyCoupon)
          }
        }, [h("div", {
          "class": "flex items-center w-full h-13 pl-3 bg-white bg-gray-100 border rounded-full"
        }, [h("input", {
          "attrs": {
            "disabled": !!props.cartState.couponCode,
            "type": "text",
            "name": "code",
            "id": "coupon",
            "placeholder": "Apply coupon"
          },
          "domProps": {
            "value": props.cartState.couponCode || props.form.coupon
          },
          "on": {
            "input": valueFromInput(v => props.form.coupon = v)
          },
          "class": "w-full bg-gray-100 outline-none appearance-none focus:outline-none active:outline-none"
        }), h("button", {
          "attrs": {
            "type": "submit"
          },
          "class": "text-sm flex items-center px-3 py-1 text-white bg-gray-800 rounded-full outline-none md:px-4 hover:bg-gray-700 focus:outline-none active:outline-none"
        }, [h("em", {
          "class": "core-icon-gift"
        }), h("span", {
          "class": "font-medium"
        }, [props.cartState.couponCode ? 'Ukloni kupon' : 'Primjeni kupon'])])])])
      }
    })]), h("div", {
      "class": "p-4 mt-6 bg-gray-100 rounded-full"
    }, [h("h1", {
      "class": "ml-2 font-bold uppercase"
    }, ["Instrukcije"])]), h("div", {
      "class": "p-4"
    }, [h("p", {
      "class": "mb-4 italic"
    }, ["Lorem ipsum dolor sit amet, consectetur adipiscing elit."]), h("textarea", {
      "class": "w-full h-24 p-2 bg-gray-100 rounded"
    })])]), h(ControllerCart, {
      "class": "lg:px-2 lg:w-1/2",
      "scopedSlots": {
        default: props => h("div", [h("div", {
          "class": "p-4 bg-gray-100 rounded-full"
        }, [h("h1", {
          "class": "ml-2 font-bold uppercase"
        }, ["Detalji"])]), h("div", {
          "class": "p-4"
        }, [h("p", {
          "class": "mb-6 italic"
        }, ["Lorem ipsum dolor sit amet, consectetur adipiscing elit."]), h("div", {
          "class": "flex justify-between border-b"
        }, [h("div", {
          "class": "lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800"
        }, ["Suma"]), h("div", {
          "class": "lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900"
        }, [this.$currency(props.priceSummary.itemsTotal)])]), props.cartState.couponCode && h("div", {
          "class": "flex justify-between pt-4 border-b"
        }, [h("div", {
          "class": "flex lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-gray-800"
        }, [h("button", {
          "on": {
            "click": () => props.removeCoupon()
          },
          "class": "mr-2"
        }, [h("em", {
          "class": "core-icon-trash-alt core-icon-trash w-4 text-red-600 hover:text-red-800",
          "attrs": {
            "aria-hidden": "true"
          }
        })]), "Kupon \"", props.cartState.couponCode, "\""]), h("div", {
          "class": "lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-green-700"
        }, ["-", this.$currency(props.priceSummary.coupon)])]), h("div", {
          "class": "flex justify-between pt-4 border-b"
        }, [h("div", {
          "class": "lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800"
        }, ["Dostava"]), h("div", {
          "class": "lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900"
        }, [this.$currency(props.priceSummary.delivery)])]), h("div", {
          "class": "flex justify-between pt-4 border-b"
        }, [h("div", {
          "class": "lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800"
        }, ["Porez"]), h("div", {
          "class": "lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900"
        }, [this.$currency(props.priceSummary.tax.cartTotal)])]), h("div", {
          "class": "flex justify-between pt-4 border-b"
        }, [h("div", {
          "class": "lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800"
        }, ["Ukupno"]), h("div", {
          "class": "lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900"
        }, [this.$currency(props.priceSummary.cartTotal)])]), h("cms-link", {
          "attrs": {
            "to": this.$Page.Checkout
          },
          "class": "flex justify-center items-center w-full px-10 py-3 mt-6 font-medium text-white uppercase bg-gray-800 rounded-full shadow item-center hover:bg-gray-700 focus:shadow-outline focus:outline-none"
        }, [h("em", {
          "class": "core-icon-credit-card"
        }), h("span", {
          "class": "ml-2 mt-5px"
        }, ["Nastavi na \u010Dekout"])])])])
      }
    })])]), h("div", {
      "class": "bg-indigo-500 p-5"
    }, [h("h3", {
      "class": "text-lg font-extrabold uppercase"
    }, ["Cross sell:"]), h(ControllerCrossSell, {
      "scopedSlots": {
        default: ({
          item
        }) => h("div", [h("h2", {
          "class": "font-semibold"
        }, ["Product name: ", item.name]), h("div", {
          "class": "bg-white mx-auto py-2"
        }, [h("span", {
          "class": "font-semibold uppercase"
        }, ["Controller product:"]), h(ControllerProduct, {
          "class": "p-5 flex items-center",
          "scopedSlots": {
            default: props => {
              var _props$item;
              return h("div", [h("h2", {
                "class": "font-semibold"
              }, [h("span", {
                "class": "text-sm font-extrabold"
              }, [(_props$item = props.item) === null || _props$item === void 0 ? void 0 : _props$item.name]), ", qty:", props.cartState.qty]), h("button", {
                "attrs": {
                  "type": "button"
                },
                "on": {
                  "click": () => {
                    props.addToCart();
                  }
                },
                "class": "px-2 bg-teal-500 uppercase font-semibold"
              }, ["add to cart"]), h("button", {
                "attrs": {
                  "type": "button"
                },
                "on": {
                  "click": () => {
                    props.commitChanges();
                  }
                },
                "class": "px-2 bg-orange-500 uppercase font-semibold"
              }, ["commit changes"])]);
            }
          }
        })])])
      }
    })])]);
  }
});
export default CartModalView;