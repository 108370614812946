// Components
import LayeredNavigation from './LayeredNavigation';
import ControllerCategory from '../controllers/ControllerCategory';
import ControllerProductList from '../controllers/ControllerProductList';
import ControllerProduct from '../controllers/ControllerProduct';
import ControllerLayeredNavigation from '../controllers/ControllerLayeredNavigation';
import ControllerFeaturedProducts from '../controllers/ControllerFeaturedProducts';

// Helpers
import { valueFromInput } from '../../utils/jsxHelpers';

// Types
import Vue from 'vue';
const ProductList = Vue.extend({
  components: {
    LayeredNavigation,
    ControllerCategory,
    ControllerProduct,
    ControllerProductList,
    ControllerFeaturedProducts
  },
  render() {
    const h = arguments[0];
    return h(ControllerCategory, {
      "scopedSlots": {
        default: categoryProps => h("div", [h("div", {
          "class": "text-gray-700 body-font"
        }, [h("h2", {
          "class": "text-sm title-font text-gray-500 tracking-widest"
        }, ["BRAND NAME"]), h("h1", {
          "class": "text-gray-900 text-3xl title-font font-medium mb-4"
        }, [
        //@ts-ignore
        categoryProps.category.name])]), h("div", {
          "class": "grid grid-cols-3 gap-20"
        }, [h("div", {
          "class": "col-span-1"
        }, [h(ControllerLayeredNavigation, {
          "scopedSlots": {
            single: ({
              filter
            }) => h("div", {
              "class": "flex border-t border-gray-300 py-2"
            }, [h("h4", {
              "class": "text-gray-500"
            }, [filter.label]), filter.choices.map(value => {
              return h("label", {
                "attrs": {
                  "for": String(value.attributeID)
                }
              }, [value.valueLabel, " (", value.totalProducts, ")", h("input", {
                "attrs": {
                  "id": String(value.attributeID),
                  "type": "radio"
                },
                "domProps": {
                  "value": value.attributeID,
                  "checked": filter.value === value.attributeID
                },
                "on": {
                  "input": () => filter.value = value.attributeID
                }
              })]);
            })]),
            multiple: ({
              filter
            }) => h("div", {
              "class": "flex border-t border-gray-300 py-2"
            }, [h("h4", {
              "class": "text-gray-500"
            }, [filter.label]), filter.choices.map(value => {
              const selectedValues = filter.value;
              const isChecked = selectedValues.includes(value.attributeID);
              const toggleValue = v => {
                if (isChecked) {
                  const vIndex = selectedValues.indexOf(v);
                  selectedValues.splice(vIndex, 1);
                } else {
                  selectedValues.push(v);
                }
              };
              return h("label", {
                "attrs": {
                  "for": String(value.attributeID)
                }
              }, [value.valueLabel, " (", value.totalProducts, ")", h("input", {
                "attrs": {
                  "id": String(value.attributeID),
                  "type": "checkbox"
                },
                "domProps": {
                  "value": value.attributeID,
                  "checked": isChecked
                },
                "on": {
                  "input": () => toggleValue(value.attributeID)
                }
              })]);
            })]),
            number: ({
              filter
            }) => h("div", {
              "class": "flex border-t border-gray-300 py-2"
            }, [h("h4", {
              "class": "text-gray-500"
            }, [filter.label]), h("input", {
              "attrs": {
                "type": "number",
                "min": filter.min,
                "max": filter.maxValue
              },
              "domProps": {
                "value": filter.minValue
              },
              "on": {
                "input": valueFromInput(v => filter.minValue = Number(v))
              }
            }), h("input", {
              "attrs": {
                "type": "number",
                "max": filter.max,
                "min": filter.minValue
              },
              "domProps": {
                "value": filter.maxValue
              },
              "on": {
                "input": valueFromInput(v => filter.maxValue = Number(v))
              }
            })])
          }
        })]), h("div", {
          "class": "col-span-2"
        }, ["Istaknuto:", h(ControllerFeaturedProducts, {
          "scopedSlots": {
            default: props => h("div", [props.item.name])
          }
        }), h("div", {
          "class": "flex justify-between items-center"
        }, [h("span", ["Total records: ", categoryProps.paginationState.totalItems]), h("div", [h("span", {
          "class": "mr-3"
        }, ["Per page:"]), h("select", {
          "class": "border border-solid rounded-sm border-gray-300 h-10 px-5 cursor-pointer",
          "domProps": {
            "value": categoryProps.options.itemsPerPage
          },
          "on": {
            "change": valueFromInput(v => categoryProps.options.itemsPerPage = v)
          }
        }, [categoryProps.itemsPerPageOptions.map(option => h("option", {
          "domProps": {
            "value": option
          },
          "key": option
        }, [option]))]), h("select", {
          "class": "border border-solid rounded-sm border-gray-300 h-10 px-5 ml-8 cursor-pointer",
          "domProps": {
            "value": categoryProps.options.sort || undefined
          },
          "on": {
            "change": valueFromInput(v => categoryProps.options.sort = v)
          }
        }, [categoryProps.sortOptions.map(option => h("option", {
          "domProps": {
            "value": option
          },
          "key": option
        }, [option]))])])]), h(ControllerProductList, {
          "class": "grid grid-cols-3 gap-10 mt-5",
          "scopedSlots": {
            default: () => h(ControllerProduct, {
              "scopedSlots": {
                default: props => {
                  var _props$item, _props$item2, _props$item3;
                  return h("div", {
                    "class": "col-span-1",
                    "key": (_props$item = props.item) === null || _props$item === void 0 ? void 0 : _props$item.id
                  }, [h("cms-link", {
                    "attrs": {
                      "to": props.item
                    }
                  }, [h("cms-image", {
                    "class": "p-4",
                    "attrs": {
                      "media": (_props$item2 = props.item) === null || _props$item2 === void 0 ? void 0 : _props$item2.media
                    }
                  })]), h("div", {
                    "class": "mt-4"
                  }, [h("h3", {
                    "class": "text-gray-500 text-xs tracking-widest title-font mb-1"
                  }, ["CATEGORY"]), h("h2", {
                    "class": "text-gray-900 title-font text-lg font-medium"
                  }, [(_props$item3 = props.item) === null || _props$item3 === void 0 ? void 0 : _props$item3.name]), h("p", {
                    "class": "mt-1"
                  }, ["$16.00"]), h("button", {
                    "attrs": {
                      "type": 'button'
                    },
                    "on": {
                      "click": () => props.addToCart()
                    }
                  }, ["Ko\u0161ara"])])]);
                }
              }
            })
          }
        }), h("div", {
          "class": "flex justify-center"
        }, [h("button", {
          "class": "w-8 h-8 rounded-sm",
          "attrs": {
            "disabled": categoryProps.options.page === 1
          },
          "on": {
            "click": () => categoryProps.options.page = 1
          }
        }, ['<<']), h("button", {
          "class": "w-8 h-8 rounded-sm",
          "attrs": {
            "disabled": categoryProps.options.page === 1
          },
          "on": {
            "click": () => categoryProps.options.page--
          }
        }, ['<']), categoryProps.pageNumbers.map(page => h("button", {
          "key": page,
          "class": ['w-8 h-8 rounded-sm', {
            'bg-blue-400 text-white': categoryProps.options.page === page
          }],
          "on": {
            "click": () => categoryProps.options.page = page
          }
        }, [page])), h("button", {
          "class": "w-8 h-8 rounded-sm",
          "attrs": {
            "disabled": categoryProps.options.page === categoryProps.paginationState.totalPages
          },
          "on": {
            "click": () => categoryProps.options.page++
          }
        }, ['>']), h("button", {
          "class": "w-8 h-8 rounded-sm",
          "attrs": {
            "disabled": categoryProps.options.page === categoryProps.paginationState.totalPages
          },
          "on": {
            "click": () => categoryProps.options.page = categoryProps.paginationState.totalPages
          }
        }, ['>>'])])])])])
      }
    });
  }
});
export default ProductList;