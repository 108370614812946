function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import Vue from 'vue';
function hasKey(obj, key) {
  return key in obj;
}
export default class BaseService {
  constructor(name, state = {}, ctx) {
    _defineProperty(this, "storage", void 0);
    this.storage = Vue.observable(state);
    if (state) {
      Object.keys(state).forEach(key => {
        hasKey(state, key) && Vue.set(this.storage, key, state[key]);
      });
    }
    if (!ctx) return;
    if (process.server && state) {
      ctx.beforeNuxtRender(({
        nuxtState
      }) => {
        if (!nuxtState.cmsState) {
          nuxtState.cmsState = {};
        }
        nuxtState.cmsState[name] = {};
        Object.keys(state).forEach(key => {
          hasKey(this.storage, key) && (nuxtState.cmsState[name][key] = this.storage[key]);
        });
      });
    }
    if (process.client && state && ctx.nuxtState.cmsState) {
      Object.keys(state).forEach(key => {
        this.setState(key, ctx.nuxtState.cmsState[name][key]);
      });
    }
  }
  setState(key, value) {
    Vue.set(this.storage, key, value);
  }
}