function animationFrameThrottle(func) {
  let inThrottle = false;
  return function (...args) {
    if (!inThrottle) {
      inThrottle = true;
      requestAnimationFrame(() => {
        inThrottle = false;
        func.apply(this, args);
      });
    }
  };
}
export { animationFrameThrottle };