// Types
import Vue from 'vue';
import { forEachKey } from '../utils/hasKey';
// Main
const Debouncable = debounces => Vue.extend({
  data() {
    const debounceIds = {};
    forEachKey(debounces, key => {
      debounceIds[key] = null;
    });
    return {
      debounceIds
    };
  },
  methods: {
    debounce(key, callback) {
      const debounceId = this.debounceIds[key];
      if (debounceId) {
        clearTimeout(debounceId);
      }
      this.debounceIds[key] = setTimeout(callback, debounces[key]);
    }
  }
});
export default Debouncable;