import './AdminButton.css';

// Types
import mixins from "vue-typed-mixins";
// Utils
import { forEachKey } from '../../utils/hasKey';

// Main
const AdminButton = mixins().extend({
  name: 'AdminButton',
  props: {
    primary: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button',
      validator(v) {
        return ['button', 'submit'].includes(v);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: ''
    }
  },
  computed: {
    colour() {
      const mapping = {
        primary: 'primary',
        secondary: 'secondary',
        error: 'error'
      };
      const props = this.$options.props;
      let colourProp = 'primary';
      forEachKey(props, prop => this[prop] === true && mapping[prop] && (colourProp = prop));
      return mapping[colourProp];
    }
  },
  render() {
    var _this$$scopedSlots$de, _this$$scopedSlots;
    const h = arguments[0];
    const {
      icon
    } = this;
    const defaultSlot = (_this$$scopedSlots$de = (_this$$scopedSlots = this.$scopedSlots).default) === null || _this$$scopedSlots$de === void 0 ? void 0 : _this$$scopedSlots$de.call(_this$$scopedSlots, {});
    return this.to ? h("nuxt-link", {
      "attrs": {
        "to": this.to
      },
      "class": ['core-admin-button', this.colour, {
        icon
      }],
      "on": {
        "click": e => this.$emit("click", e)
      }
    }, [defaultSlot]) : h("button", {
      "attrs": {
        "type": this.type,
        "disabled": this.disabled
      },
      "class": ['core-admin-button', this.colour, {
        icon
      }],
      "on": {
        "click": e => this.$emit("click", e)
      }
    }, [defaultSlot]);
  }
});
export default AdminButton;