// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../mixins/ControllerBase';

// Main
const ControllerProductCategories = mixins(ControllerBase).extend({
  name: 'ControllerProductCategories',
  data() {
    return {
      categories: []
    };
  },
  async fetch() {
    await this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      const body = {
        filters: [],
        sort: {
          param: 'id',
          order: 'asc'
        },
        tree: true
      };
      const request = this.$api.product.web.category.filter(body);
      const response = await this.callWithStatusUpdate(request, 'fetchedCategories');
      if (response.errored) return;
      const {
        data
      } = response;
      const r = this.categories;
      r.splice(0, r.length, ...data.records);
    }
  },
  render() {
    const slotProps = this.categories.map(item => ({
      item,
      status: this.status
    }));
    return this.renderContainer(slotProps);
  }
});
export default ControllerProductCategories;