function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import BaseService from './BaseService';
import consola from 'consola';
import axios from 'axios';

// Types

import { moduled } from './ApiService';
class CartService extends BaseService {
  constructor(ctx) {
    super('cart', {
      cart: null,
      guestUser: null
    }, ctx);
    _defineProperty(this, "api", void 0);
    _defineProperty(this, "ctx", void 0);
    this.ctx = ctx;
    this.api = ctx.$api;
  }
  get session() {
    const {
      protectCode,
      id
    } = this.storage.cart || {};
    if (!protectCode || !id) return null;
    return {
      protectCode,
      id
    };
  }
  get items() {
    if (!this.storage.cart) return [];
    return this.storage.cart.items;
  }
  get guestUser() {
    return this.storage.guestUser || null;
  }
  get isEProductCart() {
    var _this$storage$cart;
    return !!((_this$storage$cart = this.storage.cart) !== null && _this$storage$cart !== void 0 && _this$storage$cart.digitalOnly);
  }

  /**
   * Checks if cart id and protect code are in cookies and fetches cart
   * with that data if it exists.
   */
  async checkSessionOnInit() {
    const id = this.ctx.$getCookie('cartId');
    const protectCode = this.ctx.$getCookie('protectCode');
    if (!protectCode || !id) return;
    await this.fetchCart(id, protectCode);
  }

  /**
   * Fetch cart for existing session and write cart id and protect code into cookies.
   * @param id
   * @param protectCode
   */
  async fetchCart(id, protectCode) {
    if (!id || !protectCode) return;

    // todo: consider adding currencyId
    const data = await this.api.ecommerce.checkout.cart.getCart(id, protectCode);
    if (!data.errored) {
      this.setState('cart', data.data);
      this.setCartCookies();
      return data;
    }
    const shouldClearCart = [400, 409].includes(data.statusCode);
    if (shouldClearCart) {
      consola.error('Clearing cart session.');
      this.clearSession();
      // todo reset steps
      // await commit('HARD_RESET_CART_STEPS')
    }

    return data;
  }

  /**
   * Clears cart storage and removes cart id and protect code cookies.
   */
  clearSession() {
    this.setState('cart', null);
    this.ctx.$deleteCookie('cartId');
    this.ctx.$deleteCookie('protectCode');
  }

  /**
   * Creates new cart session and writes cart id and protect code into cookies.
   */
  async newSession() {
    const data = await this.api.ecommerce.checkout.cart.getNewCartSession();
    !data.errored && this.setState('cart', data.data);
    this.setCartCookies();
    return data;
  }
  /**
   * If cart session exists, writes cart id and protect code into cookies.
   */
  setCartCookies() {
    if (this.session) {
      const opts = {
        maxAge: 60 * 60 * 24 * 365,
        path: '/'
      };
      this.ctx.$setCookie('cartId', this.session.id, opts);
      this.ctx.$setCookie('protectCode', this.session.protectCode, opts);
    }
  }

  /**
   * After logout, cart id and protect code are deleted from cookies. After logging in, we need to fetch cart id and protect code, fetch cart with them and once again write cart id and protect code into cookies.
   */
  async fetchAbandonedSession() {
    // Commented code should actually be entire method, but since getAbandoned return 404 if there was no cart session(like when new user logs in)
    // we dont want to display error message so we cant use our apiService, which is completely retarded
    // const data = await this.api.ecommerce.checkout.cart.getAbandoned()
    // !data.errored && (await this.fetchCart(data.data.id, data.data.protectCode))
    try {
      const {
        headers,
        baseURL
      } = this.ctx.$axios.defaults;
      const response = (await axios.request({
        baseURL,
        url: moduled('ecommerce', 'checkout/cart/getAbandoned', 'v2'),
        method: 'post',
        headers
      })).data.data;
      await this.fetchCart(response.id, response.protectCode);
    } catch (e) {
      consola.error('$cart.fetchAbandonedSession failed (probably because new user with no cart session logged in): ', e.message);
    }
  }

  /**
   * Find product in cart items
   * @param product target product object
   */
  findProduct(product) {
    return this.items.find(item => item.productId === product.id);
  }

  /**
   * Find cart item by id
   * @param itemId target item id
   */
  findItem(itemId) {
    return this.items.find(item => item.itemId === itemId);
  }

  /**
   * Adds product to current cart session. If session doesn't exist,
   * it creates it before adding product.
   * */
  async addItem(product, quantity = 1, options = {}) {
    var _this$ctx$$applicatio;
    if (!this.session) {
      const res = await this.newSession();
      return res.errored ? res : await this.addItem(product, quantity, options);
    }
    const hasCustomOptions = product => typeof product !== 'number' && 'customOptions' in product;
    const {
      addItem
    } = this.api.ecommerce.checkout.cart;
    const body = {
      cartId: this.session.id,
      protectCode: this.session.protectCode,
      currencyId: (_this$ctx$$applicatio = this.ctx.$application.currency) === null || _this$ctx$$applicatio === void 0 ? void 0 : _this$ctx$$applicatio.id,
      productId: typeof product === 'number' ? product : product.id,
      qty: Number(quantity),
      options
      // currencyId: rootState.application.selectedCurrencyId
    };

    // TODO: this is temporarily hardcoded to always take the first option and value for Celero, TBD as customisable options a la product configuration
    if (hasCustomOptions(product) && !options.customPriceOption) {
      var _product$customOption;
      if (((_product$customOption = product.customOptions[0]) === null || _product$customOption === void 0 ? void 0 : _product$customOption.option_title) === 'subscription') {
        var _product$customOption2, _product$customOption3, _product$customOption4;
        const sku = (_product$customOption2 = product.customOptions[0]) === null || _product$customOption2 === void 0 ? void 0 : (_product$customOption3 = _product$customOption2.custom_option_values[0]) === null || _product$customOption3 === void 0 ? void 0 : _product$customOption3.sku;
        const title = (_product$customOption4 = product.customOptions[0]) === null || _product$customOption4 === void 0 ? void 0 : _product$customOption4.option_title;
        if (sku && title) {
          body.options = {
            ...body.options,
            customPriceOption: {
              customOptionTitle: title,
              customOptionValue: sku
            }
          };
        }
      }
    } else if (options.customPriceOption) {
      body.options = {
        ...body.options,
        customPriceOption: options.customPriceOption
      };
    } else {
      const {
        storeID
      } = this.ctx.$application.store || {};
      if (storeID && typeof product !== 'number') {
        const expandedProduct = await this.api.product.web.get({
          id: product.id,
          storeId: storeID // You have to be fucking kidding me
        });

        return expandedProduct.errored ? expandedProduct : await this.addItem(expandedProduct.data, quantity, options);
      }
    }

    // // TODO: this is temporary horrible hotfix, configurabile products are not sent in correct PARENT_ID:CHILD_ID format until backend solves a problem with returning format inside BundleProduct type and Bundle type.
    // // TODO: Delete this shit ASAP.
    // // should be using if(product.format === ProductFormat.Bundle) when that stuff gets added
    // if (
    //   typeof product !== 'number' &&
    //   //@ts-ignore
    //   product.bundles &&
    //   //@ts-ignore
    //   Array.isArray(product.bundles) &&
    //   //@ts-ignore
    //   product.bundles.length
    // ) {
    //   //@ts-ignore
    //   const bundleOptions = product.bundles.map((productFromBundle) => {
    //     if (productFromBundle.id) {
    //       // Bundle type, from product/checkBundles route
    //       return productFromBundle.id
    //     } else if (productFromBundle.product && productFromBundle.product.id) {
    //       // ProductRecord type, from product/web/filter route (can also be format === 'bundle')
    //       return productFromBundle.product.id
    //     } else {
    //       // Product type, from product/web/get route (can also be format === 'bundle')
    //       return productFromBundle.product
    //     }
    //   })
    //   //@ts-ignore
    //   body.options = {
    //     ...body.options,
    //     bundleOptions,
    //   }
    // }
    const data = await addItem(body);
    !data.errored && this.setState('cart', data.data);
    return data;
  }

  /**
   * Update item in cart.
   * @param itemId id of cart item, NOT product id
   * @param quantity new quantity to set
   * @param options personalisation, subscription etc.
   * @param cancelToken used for canceling requests (advanced usage)
   * @param setState whether to override the whole cart object with the new one from response of this call
   */
  async updateItem(itemId, quantity = 1, options, cancelToken, setState = true) {
    var _this$ctx$$applicatio2;
    if (!this.session) {
      throw new Error("Session doesn't exist");
    }
    const body = {
      cartId: this.session.id,
      protectCode: this.session.protectCode,
      currencyId: (_this$ctx$$applicatio2 = this.ctx.$application.currency) === null || _this$ctx$$applicatio2 === void 0 ? void 0 : _this$ctx$$applicatio2.id,
      itemId,
      qty: Number(quantity),
      options
      // currencyId: rootState.application.selectedCurrencyId
    };

    const data = await this.api.ecommerce.checkout.cart.updateItem({
      ...body,
      cancelToken
    });
    if (setState) {
      !data.errored && this.setState('cart', data.data);
    } else {
      var _this$storage$cart2;
      const prices = (_this$storage$cart2 = this.storage.cart) === null || _this$storage$cart2 === void 0 ? void 0 : _this$storage$cart2.prices;
      prices && !data.errored && Object.assign(prices, data.data.prices);
    }
    // TODO: handle this stupid thing differently
    // items should be separate and updated when needed, not the whole cart always
    if (!data.errored) {
      this.items.splice(0, this.items.length, ...data.data.items);
    }
    return data;
  }

  /**
   * Set cart item state
   * @param product product by which cart item will be searched
   * @param options new state to commit - quantity
   * @param customOptions persionalizations and stuff
   */
  async setItem(product, options, customOptions) {
    const cartItem = this.findProduct(product);
    if (cartItem) {
      return await this.updateItem(cartItem.itemId, options.quantity, customOptions);
    } else {
      return await this.addItem(product, options.quantity, customOptions);
    }
  }
  async deleteItem(itemId) {
    if (!this.session) {
      throw new Error("Session doesn't exist");
    }
    const body = {
      cartId: this.session.id,
      protectCode: this.session.protectCode,
      itemId
      // currencyId: rootState.application.selectedCurrencyId
    };

    const data = await this.api.ecommerce.checkout.cart.deleteItem(body);
    !data.errored && this.setState('cart', data.data);
    return data;
  }
  async clearItems() {
    if (!this.session || !this.items.length) {
      consola.error('Session does not exist or cart is already empty');
      return;
    }
    const body = {
      cartId: this.session.id,
      protectCode: this.session.protectCode
    };
    const data = await this.api.ecommerce.checkout.cart.clearCart(body);
    !data.errored && this.setState('cart', data.data);
  }
  async updateGuestUser(user) {
    if (!this.session) {
      throw new Error("Session doesn't exist");
    }
    const data = await this.api.user.web.register(user);
    if (!data.errored) {
      this.setState('guestUser', data.data);
      this.ctx.$setCookie('guestUser', data.data.email);
    }
    return data;
  }
  async updateDeliveryMethod(deliveryMethodId, branchId) {
    var _this$ctx$$auth$user, _this$guestUser;
    if (!this.session) {
      throw new Error("Session doesn't exist");
    }
    const userId = ((_this$ctx$$auth$user = this.ctx.$auth.user) === null || _this$ctx$$auth$user === void 0 ? void 0 : _this$ctx$$auth$user.id) || ((_this$guestUser = this.guestUser) === null || _this$guestUser === void 0 ? void 0 : _this$guestUser.id);
    if (!userId) {
      throw new Error('No active user');
    }
    const data = await this.api.ecommerce.checkout.delivery.saveDeliveryMethod({
      userId,
      cartId: this.session.id,
      protectCode: this.session.protectCode,
      deliveryMethodId,
      branchId
    });
    !data.errored && this.setState('cart', data.data);
    return data;
  }
  async applyCoupon(couponCode) {
    if (!this.session) {
      throw new Error("Session doesn't exist");
    }
    const data = await this.api.ecommerce.checkout.cart.applyCoupon({
      cartId: this.session.id,
      protectCode: this.session.protectCode,
      couponCode
    });
    !data.errored && this.setState('cart', data.data);
    return data;
  }
  async removeCoupon() {
    if (!this.session) {
      throw new Error("Session doesn't exist");
    }
    const data = await this.api.ecommerce.checkout.cart.removeCoupon({
      cartId: this.session.id,
      protectCode: this.session.protectCode
    });
    !data.errored && this.setState('cart', data.data);
    return data;
  }
}
export default CartService;