// Types
import Vue from 'vue';

// Components
import ControllerSearch from '../controllers/ControllerSearch';

// Utils
import { preventDefault, valueFromInput } from '../../utils/jsxHelpers';
const SearchView = Vue.extend({
  name: 'SearchView',
  render() {
    const h = arguments[0];
    return h(ControllerSearch, {
      "scopedSlots": {
        default: ({
          form,
          categories,
          submit,
          items
        }) => {
          const setCategory = category => form.activeCategory = category.slug;
          return h("div", [' ', h("form", {
            "on": {
              "submit": preventDefault(submit)
            }
          }, [h("label", [this.$t('core.navigation.search'), h("input", {
            "attrs": {
              "type": "text",
              "placeholder": this.$t('core.navigation.search')
            },
            "domProps": {
              "value": form.search
            },
            "on": {
              "input": valueFromInput(v => form.search = v)
            }
          })])]), h("div", {
            "class": 'p-2 bg-white rounded-md shadow-lg border border-gray-300',
            "style": "min-width: 300px"
          }, [h("ul", {
            "class": "flex items-center space-x-4 text-sm mb-4"
          }, [categories.map(category => h("li", [h("button", {
            "on": {
              "click": () => setCategory(category)
            },
            "class": `hover:underline ${form.activeCategory === category.slug ? 'text-blue-500' : 'text-black'}`
          }, [category.name])]))]), h("ul", [items.map(item => h("li", {
            "class": "flex items-center"
          }, [h("cms-image", {
            "attrs": {
              "media": item.media || item.image
            },
            "class": "mr-3 w-8 h-8 object-cover object-center"
          }), h("cms-link", {
            "attrs": {
              "to": item
            },
            "class": "text-sm"
          }, [
          // @ts-ignore
          item.title || item.name || '/'])]))])])]);
        }
      }
    });
  }
});
export default SearchView;