// Types
import Vue from 'vue';
import { valueFromInput } from '../../utils/jsxHelpers';
const LayeredNavigation = Vue.extend({
  inject: {
    filters: {
      default: () => []
    },
    setFilter: {
      default: () => null
    },
    clearFilter: {
      default: () => null
    }
  },
  data() {
    return {
      layeredFilters: []
    };
  },
  computed: {
    layeredFilterData() {
      const data = {};
      const computed = {};
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const ctx = this;
      this.layeredFilters.forEach(lf => {
        data['_' + lf.attributeCode] = ctx.getLayeredFilterValue(lf.attributeCode);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        computed[lf.attributeCode] = {
          get() {
            return this.$data['_' + lf.attributeCode];
          },
          set(value) {
            this.$data['_' + lf.attributeCode] = value;
            value ? ctx.setFilter({
              key: lf.attributeCode,
              value: [value]
            }) : ctx.clearFilter(lf.attributeCode);
          }
        };
      });
      return new Vue({
        data: () => data,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        computed
      });
    }
  },
  watch: {
    filters: 'fetchLayeredNavigation'
  },
  created() {
    this.fetchLayeredNavigation();
  },
  methods: {
    async fetchLayeredNavigation() {
      try {
        const data = await this.$api.product.layered.navigation(this.filters);
        // todo: typing
        if (!Array.isArray(data)) return;
        this.layeredFilters.splice(0, this.layeredFilters.length, ...data);
      } catch (e) {
        console.error(e.response.data.message);
      }
    },
    getLayeredFilter(attributeCode) {
      return this.layeredFilters.find(filter => filter.attributeCode === attributeCode);
    },
    setLayeredFilter(e, attributeCode) {
      const value = parseInt(e.target.value);
      value ? this.setFilter({
        key: attributeCode,
        value: [value]
      }) : this.clearFilter(attributeCode);
    },
    getLayeredFilterValue(attributeCode) {
      const param = this.filters.find(f => f.key === attributeCode);
      if (!param) return;
      if (Array.isArray(param.value)) {
        return param.value[0];
      } else {
        return param.value;
      }
    }
  },
  render() {
    const h = arguments[0];
    return h("div", {
      "class": "mt-6"
    }, [this.layeredFilters.map(filter => h("div", {
      "class": "flex border-t border-gray-300 py-2",
      "key": filter.attributeCode
    }, [h("label", {
      "class": "text-gray-500",
      "attrs": {
        "for": filter.attributeCode
      }
    }, [filter.label]), h("select", {
      "class": "ml-auto text-gray-900",
      "attrs": {
        "id": filter.attributeCode
      },
      "domProps": {
        "value": this.layeredFilterData[filter.attributeCode]
      },
      "on": {
        "change": valueFromInput(v => this.layeredFilterData[filter.attributeCode] = v)
      }
    }, [h("option", {
      "domProps": {
        "value": undefined
      }
    }, ["None"]), filter.values.map(option => h("option", {
      "domProps": {
        "value": option.attributeID
      }
    }, [option.valueLabel, " (", option.totalProducts, ")"]))])]))]);
  }
});
export default LayeredNavigation;