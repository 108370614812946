import Vue from 'vue';
import { GoalType } from '../enums/CustomForm';
import axios from 'axios';
import consola from 'consola';
const DoubleOptInHelper = Vue.extend({
  data() {
    return {
      message: ''
    };
  },
  methods: {
    async handleDoubleOptInGoal(submitResponse) {
      if (!process.client) throw new Error('Called too soon');
      const actionPerGoalType = {
        [GoalType.Message]: this.showNotification,
        [GoalType.File]: this.downloadFile,
        [GoalType.Url]: this.redirectUser
      };
      actionPerGoalType[submitResponse.goalType](submitResponse);

      //todo: handle all possible goalTypes
    },

    showNotification(submitResponse) {
      const messageByType = {
        confirmEmail: 'core.notifications.confirmEmail',
        successMessage: 'core.notifications.emailConfirmed'
      };
      const message = messageByType[submitResponse.goal];
      this.$toast.success(this.$t(message));
      this.message = this.$t(message);
    },
    async downloadFile(submitResponse) {
      // Anchor element has 'download' attribute which should download the file,
      // but in late 2018 they added security measurements which wont allow you to download file if it isn't coming from the same origin
      // That's why we have to use blobs
      try {
        this.message = this.$t('core.notifications.downloading');
        const response = await axios.request({
          url: submitResponse.goal,
          method: 'get',
          responseType: 'blob' //important
        });

        const downloadUrl = URL.createObjectURL(response.data);
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = 'download';
        anchor.click();
        URL.revokeObjectURL(downloadUrl);
      } catch (e) {
        consola.error(this.$t('error.downloadFailed'));
      }
    },
    redirectUser(submitResponse) {
      this.message = this.$t('core.notifications.redirecting');
      window.location.replace(submitResponse.goal);
    }
  }
});
export default DoubleOptInHelper;