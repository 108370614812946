// Components
import FooterContainer from '../components/elements/footer/FooterContainer';
import HeaderContainer from '../components/elements/header/HeaderContainer';
import CookieContainer from '../components/elements/cookie/CookieContainer';
import CmsMenu from '../components/ui/CmsMenu';

// Types
import mixins from "vue-typed-mixins"; // Mixins
import { DomainListEvents } from '../components/modals/DomainList';
import { ReleasesEvents } from '../components/modals/Releases';

// Utils
import packageVersion from '../packageVersion';
import axios from 'axios';

// Enums
import { Modals } from '../enums/Modals';
// Main
const CmsMainLayout = mixins(DomainListEvents, ReleasesEvents).extend({
  name: 'CmsMainLayout',
  components: {
    GboxMedia: () => import('../components/modals/GboxMedia'),
    LinkOptionsModal: () => import('../components/modals/LinkOptionsModal'),
    IconOptionsModal: () => import('../components/modals/IconOptionsModal'),
    DomainList: () => import('../components/modals/DomainList'),
    ReleasesModal: () => import('../components/modals/Releases'),
    CartModal: () => import('../components/modals/CartModal'),
    PostsModal: () => import('../components/modals/PostsModal'),
    ProductsModal: () => import('../components/modals/ProductsModal'),
    ProductCategoriesModal: () => import('../components/modals/ProductCategoriesModal'),
    BundleCustomizationModal: () => import('../components/modals/BundleCustomizationModal')
  },
  provide() {
    return {
      isCartView: false,
      dummyMode: false
    };
  },
  data() {
    return {
      latestTag: '',
      latestReleases: []
    };
  },
  computed: {
    marginTop() {
      return this.$display.layout.top + 'px';
    },
    marginBottom() {
      return this.$display.layout.bottom + 'px';
    },
    onLatestVersion() {
      if (!this.latestTag || !packageVersion) return true;
      const chunkVersion = version => version.split('.').map(b => parseInt(b, 10));
      const [lmaj, lmin, lp] = chunkVersion(this.latestTag);
      const [cmaj, cmin, cp] = chunkVersion(packageVersion);
      return lmaj === cmaj && lmin === cmin && lp === cp;
    }
  },
  head: {
    bodyAttrs: {
      class: 'overflow-y-scroll'
    }
  },
  mounted() {
    this.fetchLatestPackageTag();
    // this.fetchLatestReleases()
  },

  methods: {
    isLatestTag(newTag, oldTag) {
      if (!newTag || !oldTag) return false;
      const chunkVersion = version => version.split('.').map(b => parseInt(b, 10));
      const [lmaj, lmin, lp] = chunkVersion(newTag);
      const [cmaj, cmin, cp] = chunkVersion(oldTag);
      return lmaj === cmaj && lmin === cmin && lp === cp;
    },
    async fetchLatestPackageTag() {
      if (this.$isProduction) return;
      const tagFromStorge = localStorage.getItem('latest-tag') || '';
      const [tag, timestamp] = tagFromStorge.split('@');
      const difference = Date.now() - parseInt(timestamp || '');
      const differenceInHours = difference / (1000 * 60 * 60);
      if (tag && differenceInHours < 1) {
        this.latestTag = tag;
        return;
      }
      try {
        const {
          data: latestTag
        } = await axios.request({
          method: 'get',
          url: '/_cli/get-latest-tag'
        });
        if (typeof latestTag !== 'string') return;
        this.latestTag = latestTag;
        localStorage.setItem('latest-tag', `${latestTag}@${Date.now()}`);
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
    async fetchLatestReleases() {
      var _this$$application$fi;
      if (this.$isProduction) return;
      const gitlabProjectId = (_this$$application$fi = this.$application.findCmsOption('GitlabProjectId')) === null || _this$$application$fi === void 0 ? void 0 : _this$$application$fi.value;
      if (!gitlabProjectId) return;
      const releaseFromStorage = localStorage.getItem('latest-release') || '';
      const [tag, timestamp] = releaseFromStorage.split('@');
      const difference = Date.now() - parseInt(timestamp || '');
      const differenceInHours = difference / (1000 * 60 * 60);
      if (tag && differenceInHours < 0.08) {
        return;
      }
      try {
        var _latestReleases$;
        const {
          data: latestReleases
        } = await axios.request({
          method: 'post',
          url: '/_cli/get-latest-release',
          data: {
            gitlabProjectId,
            gitlabBranch: this.$config.gitlabBranch
          }
        });
        if (!Array.isArray(latestReleases)) return;
        this.latestReleases = latestReleases;
        const latestTag = (_latestReleases$ = latestReleases[0]) === null || _latestReleases$ === void 0 ? void 0 : _latestReleases$.tag_name;
        const isLatest = this.isLatestTag(latestTag, tag);
        !isLatest && this.$modal.show(Modals.Releases, {
          releases: latestReleases
        });
        localStorage.setItem('latest-release', `${latestTag}@${Date.now()}`);
      } catch (e) {
        this.$toast.error(e.message);
      }
    }
  },
  render() {
    const h = arguments[0];
    const {
      marginTop,
      marginBottom
    } = this;
    return h("div", {
      "attrs": {
        "id": "cms-main-layout"
      }
    }, [!this.$isProduction && h(CmsMenu, {
      "attrs": {
        "placement": 'right-start',
        "zIndex": 'z-core-version',
        "padding": 'p-0'
      }
    }, [h("ul", [h("li", [h("a", {
      "attrs": {
        "href": 'https://gitlab.gaussx.com/GaussBoxCore/npmPackages/npm-gb-cms-core/-/blob/master/CHANGELOG.md',
        "target": 'blank'
      },
      "class": 'h-12 flex items-center hover:bg-blue-100 transition-colors duration-150 px-4'
    }, ["Changelog"])]), h("li", [h("a", {
      "attrs": {
        "href": 'https://main-npm-gb-cms-core-debug.gauss.dev/',
        "target": 'blank'
      },
      "class": 'h-12 flex items-center hover:bg-blue-100 transition-colors duration-150 px-4'
    }, ["Documentation"])]), this.$isDevMode && h("li", {
      "class": 'cursor-pointer h-12 flex items-center hover:bg-blue-100 transition-colors duration-150 px-4',
      "on": {
        "click": () => this.$isBackofficeUser ? this.$modal.show(Modals.DomainList) : this.$router.push('/adminlogin')
      }
    }, ["Change domain"]), this.$isDevMode && h("li", [h("nuxt-link", {
      "attrs": {
        "to": '/section-tool'
      },
      "class": 'cursor-pointer h-12 flex items-center hover:bg-blue-100 transition-colors duration-150 px-4'
    }, ["Section tool"])])]), h("aside", {
      "slot": 'activator',
      "attrs": {
        "id": "package-version"
      },
      "class": ['z-core-version cursor-pointer fixed top-0 left-0 rounded-sm m-1 px-1 text-white text-center leading-tight', this.onLatestVersion ? 'bg-blue-400' : 'bg-yellow-400']
    }, [h("div", {
      "class": "text-xs font-hairline"
    }, ["core version"]), packageVersion])]), h(HeaderContainer, {
      "class": 'z-core-header'
    }), h("main", {
      "style": {
        marginTop
      },
      "attrs": {
        "id": "cms-content"
      }
    }, [h("nuxt")]), h(FooterContainer, {
      "style": {
        marginBottom
      }
    }), h(CookieContainer), this.$isBackofficeUser && h("gbox-media"), this.$isInEditMode && h("link-options-modal"), this.$isInEditMode && h("icon-options-modal"), h("cart-modal"), this.$isInEditMode && h("posts-modal"), this.$isInEditMode && h("products-modal"), this.$isInEditMode && h("product-categories-modal"), !this.$isProduction && h("domain-list"), !this.$isProduction && h("releases-modal"), h("bundle-customization-modal")]);
  }
});
export default CmsMainLayout;