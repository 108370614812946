// Types
import Vue from 'vue';
export const defaultProductFilterSelectFields = ['name', 'id', 'type', 'media', 'slug', 'sku', 'format', 'bundles', 'discount', 'basicPrice', 'retailPrice', 'categoryPriority'];

/**
 * This mixin should be implemented inside every Controller/Editor that fetches products with 'product/web/filter' route
 * Its computed property computedSelectParam() should always be merged into request params
 * */

const ProductFilterFieldSelector = Vue.extend({
  props: {
    /** @param additionalProductSelectFields Every filter route has 'select' param in which we can send additional properties we would like to have in our fetched objects.
     There are default additional properties for product/web/filter route, defined inside 'defaultProductFilterSelectFields', but sometimes that's not enough.
     Thats why there is 'additionalProductFields' prop for devs to append even more properties
     */
    additionalProductFields: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    /** @param computedSelectParam should be included in every single 'product/web/filter' request*/
    computedSelectParam() {
      return [...new Set([...defaultProductFilterSelectFields, ...this.additionalProductFields])];
    }
  }
});
export default ProductFilterFieldSelector;