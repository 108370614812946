// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../mixins/ControllerBase';
import { WithPartialInject } from '../../utils/withInject';
import Debouncable from '../../mixins/Debouncable';

// Utils
import { forEachKey } from '../../utils/hasKey';

// Enums
import { Page } from '@gauss/cms-shared';
import { LayeredNavigationType } from '../../enums/LayeredNavigation';
// Main
const ControllerLayeredNavigation = mixins(ControllerBase, Debouncable({
  form: 500
}), WithPartialInject('setFilters', 'layeredFilters')).extend({
  name: 'ControllerLayeredNavigation',
  props: {
    pickFilters: {
      type: Array,
      default: null
    },
    activeFilterCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      localLayeredFilters: [],
      form: {}
    };
  },
  async fetch() {
    // If on client, this component will render before Container is finished fetching
    if (this.layeredFilters && process.client) {
      const unwatch = this.$watch('layeredFilters', () => {
        this.copyFiltersToLocal();
        this.initialiseFormWatcher();
        unwatch();
      });
    } else if (this.layeredFilters) {
      this.copyFiltersToLocal();
    } else {
      await this.fetchLocalLayeredNavigation();
    }
  },
  mounted() {
    this.localLayeredFilters.length && this.initialiseFormWatcher();
    this.emitActiveFilterCount();
  },
  computed: {
    pickedFilters() {
      const {
        pickFilters,
        localLayeredFilters
      } = this;
      if (!pickFilters) return localLayeredFilters;
      return this.localLayeredFilters.filter(filter => pickFilters.includes(filter.attributeCode));
    }
  },
  methods: {
    copyFiltersToLocal() {
      const l = this.localLayeredFilters;
      l.splice(0, l.length, ...this.layeredFilters);
      this.createForm();
    },
    async fetchLocalLayeredNavigation() {
      const filters = this.getFiltersAsArray();
      const response = await this.$api.product.layered.navigation(filters);
      if (response.errored) return;
      // TODO: whyyyyyyyyyyyyyyyyy
      if (!Array.isArray(response.data)) return;
      const f = this.localLayeredFilters;
      f.splice(0, f.length, ...response.data);
      this.createForm();
    },
    createForm() {
      const obj = {};
      this.localLayeredFilters.forEach(filter => {
        obj[filter.attributeCode] = this.getValueFromQuery(filter) || this.getDefaultValueFor(filter);
      });
      this.$set(this, 'form', obj);
    },
    getValueFromQuery(filter) {
      const qValue = this.$route.query[filter.attributeCode];
      if (!qValue) return;
      const q = qValue.split(',').map(Number);
      return q && filter.type === LayeredNavigationType.Dropdown ? q[0] : q;
    },
    getDefaultValueFor(filter) {
      if (filter.type === LayeredNavigationType.Number) {
        var _filter$values;
        let [minValue, maxValue] = [0, 0];
        if (filter !== null && filter !== void 0 && (_filter$values = filter.values) !== null && _filter$values !== void 0 && _filter$values.length) {
          const values = filter.values[0];
          minValue = values.minValue;
          maxValue = values.maxValue;
        }
        return [minValue, maxValue];
      } else {
        const defaultOption = filter.values.find(value => value.isDefault);
        const defaultValue = defaultOption === null || defaultOption === void 0 ? void 0 : defaultOption.attributeID;
        const emptyValueMap = {
          [LayeredNavigationType.Dropdown]: defaultValue || null,
          [LayeredNavigationType.MultiSelect]: defaultValue ? [defaultValue] : []
        };
        return emptyValueMap[filter.type] || null;
      }
    },
    initialiseFormWatcher() {
      this.$watch('form', () => {
        const {
          setFilters,
          $router
        } = this;
        if (!setFilters) return;
        const callback = () => {
          const activeFilters = this.getFiltersAsArray();
          setFilters(activeFilters);
          this.emitActiveFilterCount(activeFilters.length);
          $router.replace({
            query: this.genQuery()
          });
        };
        this.debounce('form', callback);
      },
      // Dont use immediate, we dont want to have immediate retailPrice query param pushed
      {
        deep: true
      });
    },
    // TODO: POSSIBLY REMOVE THIS FUNCTIONALITY
    emitActiveFilterCount(count) {
      let finalCount = count;
      if (typeof finalCount !== 'number') {
        const activeFilters = this.getFiltersAsArray();
        finalCount = activeFilters === null || activeFilters === void 0 ? void 0 : activeFilters.length;
      }
      this.$emit('update:activeFilterCount', finalCount);
    },
    getFiltersAsArray() {
      const filters = [];
      forEachKey(this.form, key => {
        const value = this.form[key];
        if (Array.isArray(value)) {
          if (!value.length) return;
          if (value.length !== 2) {
            filters.push({
              key,
              value
            });
            // Price filter always has length of two and has to have both min and max entered
          } else if (value[0] !== null && value[1] !== null) {
            filters.push({
              key,
              value
            });
          }
        } else if (value) {
          filters.push({
            key,
            value: [value]
          });
        }
      });
      return filters;
    },
    genDropdownSlot(item) {
      const {
        form
      } = this;
      const {
        single
      } = this.$scopedSlots;
      if (!single) return this.genUnusedSlot('single');
      return single({
        resetFilter: () => this.clearLocalFilter(item),
        filter: {
          attributeCode: item.attributeCode,
          label: item.label,
          type: item.type,
          choices: item.values,
          get value() {
            return form[item.attributeCode];
          },
          set value(v) {
            form[item.attributeCode] = v;
          }
        }
      });
    },
    genMultiSelectSlot(item) {
      const {
        form
      } = this;
      const {
        multiple
      } = this.$scopedSlots;
      if (!multiple) return this.genUnusedSlot('multiple');
      return multiple({
        resetFilter: () => this.clearLocalFilter(item),
        filter: {
          attributeCode: item.attributeCode,
          label: item.label,
          type: item.type,
          choices: item.values,
          get value() {
            return form[item.attributeCode];
          },
          set value(v) {
            form[item.attributeCode] = v;
          }
        }
      });
    },
    genNumberSlot(item) {
      var _this$$application$cu, _item$values;
      const {
        form
      } = this;
      const {
        number
      } = this.$scopedSlots;
      if (!number) return this.genUnusedSlot('number');
      // If category contains 0 products, after fetching layered navigation, backend will return LayeredFilters, but values property inside it will be an empty array
      // Thats why we need default values
      let [minValue, maxValue, totalProducts, valueLabel] = [0, 0, 0, ((_this$$application$cu = this.$application.currency) === null || _this$$application$cu === void 0 ? void 0 : _this$$application$cu.label) || ''];
      if (item !== null && item !== void 0 && (_item$values = item.values) !== null && _item$values !== void 0 && _item$values.length) {
        const values = item.values[0];
        minValue = values.minValue;
        maxValue = values.minValue;
        totalProducts = values.totalProducts;
        valueLabel = values.valueLabel;
      }
      return number({
        resetFilter: () => this.clearLocalFilter(item),
        filter: {
          attributeCode: item.attributeCode,
          label: item.label,
          type: item.type,
          min: minValue,
          max: maxValue,
          totalProducts,
          valueLabel,
          get minValue() {
            return form[item.attributeCode][0];
          },
          set minValue(v) {
            ;
            form[item.attributeCode].splice(0, 1, v);
          },
          get maxValue() {
            return form[item.attributeCode][1];
          },
          set maxValue(v) {
            ;
            form[item.attributeCode].splice(1, 1, v);
          }
        }
      });
    },
    genUnusedSlot(type) {
      const h = this.$createElement;
      return [h("div", {
        "class": 'bg-red-600'
      }, ["Nije postavljen slot \"", type, "\""])];
    },
    genQuery() {
      return this.getFiltersAsArray().reduce((query, filter) => {
        const isPossiblyPriceFilter = filter.value.length === 2;
        const isPriceFilled = filter.value[0] !== 0 && filter.value[1] !== 0;
        if (isPossiblyPriceFilter && !isPriceFilled) return query;
        query[filter.key] = filter.value.join(',');
        return query;
      }, {});
    },
    pushToProductList() {
      const productListLocation = {
        name: Page.Product,
        query: this.genQuery()
      };
      this.$router.push(this.localePath(productListLocation));
    },
    clearLocalFilter(filter) {
      const value = this.getDefaultValueFor(filter);
      Object.assign(this.form, {
        ...this.form,
        [filter.attributeCode]: value
      });
    },
    resetFilters() {
      const obj = {};
      this.localLayeredFilters.forEach(filter => {
        obj[filter.attributeCode] = this.getDefaultValueFor(filter);
      });
      Object.assign(this.form, obj);
    }
  },
  render() {
    const h = arguments[0];
    const {
      pickedFilters: items,
      form
    } = this;
    // @ts-ignore default is not always defined, TS is a liar
    return this.$scopedSlots.default ? this.renderContainer({
      items,
      form
    }) : this.createModularContainer(items.map(item => {
      const errorContainer = this.$isDevMode ? h("div", {
        "class": 'bg-red-600'
      }, ["Nije prepoznat filter tipa \"", item.type, "\""]) : undefined;
      switch (item.type) {
        case LayeredNavigationType.Dropdown:
          return this.genDropdownSlot(item);
        case LayeredNavigationType.Number:
          return this.genNumberSlot(item);
        case LayeredNavigationType.MultiSelect:
          return this.genMultiSelectSlot(item);
        default:
          return errorContainer;
      }
    }));
  }
});
export default ControllerLayeredNavigation;