//Types
import mixins from "vue-typed-mixins";
//Mixins
import ControllerBase from '../../mixins/ControllerBase';
import DoubleOptInHelper from '../../mixins/DoubleOptInHelper';
const dummyModeInject = InjectFrom().keys('dummyMode');
const pageConfirmEmailInject = InjectFrom().keys('goalType', 'goal', 'confirmedEmail');

// Utils
import { InjectFrom } from '../../utils/withInject';
export const isDoubleOptInFormResponse = to => 'goalType' in to && 'goal' in to;
const ControllerConfirmEmail = mixins(ControllerBase, dummyModeInject, pageConfirmEmailInject, DoubleOptInHelper).extend({
  name: 'ControllerEmailConfirmed',
  async mounted() {
    if (!this.goal || !this.goalType || !this.confirmedEmail) {
      this.$nuxt.context.error({
        message: 'goal or goalType were not defined',
        statusCode: 404
      });
      return;
    }
    this.handleDoubleOptInGoal({
      goal: this.goal,
      goalType: this.goalType
    });
    this.$emit('email-confirmed', {
      confirmedEmail: this.confirmedEmail,
      goal: this.goal,
      goalType: this.goalType
    });
  },
  render() {
    const {
      message,
      goalType,
      goal
    } = this;
    const slotProps = {
      message,
      goalType,
      goal
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerConfirmEmail;