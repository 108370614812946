import Vue from 'vue';
import { ErrorView } from '../components/overridableComponents';
const ErrorLayout = Vue.extend({
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  provide() {
    return {
      error: this.error
    };
  },
  layout: ctx => {
    var _ctx$$application;
    return (_ctx$$application = ctx.$application) !== null && _ctx$$application !== void 0 && _ctx$$application.domain ? 'CmsMainLayout' : 'default';
  },
  name: 'ErrorLayout',
  render() {
    const h = arguments[0];
    return h(ErrorView);
  }
});
export default ErrorLayout;