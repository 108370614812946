import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
// Types
import mixins from "vue-typed-mixins";
// Enums
import { Modals } from '../../enums/Modals';

// Utils
import { typedModal } from '../../utils/typedModals';
export const DomainListEvents = typedModal();
const DomainList = mixins(DomainListEvents).extend({
  data() {
    return {
      loading: false,
      domains: [],
      domainInFile: ''
    };
  },
  computed: {
    selectedDomain() {
      var _this$domains$find;
      return ((_this$domains$find = this.domains.find(d => d.url === this.domainInFile)) === null || _this$domains$find === void 0 ? void 0 : _this$domains$find.name) || '';
    },
    list() {
      const h = this.$createElement;
      return this.domains.map(d => h("li", {
        "class": ['cursor-pointer h-12 flex items-center justify-center rounded-sm transition-colors duration-75', d.url === this.domainInFile ? 'bg-blue-600 text-white hover:bg-blue-400 hover:text-white' : 'hover:bg-blue-200 hover:text-blue-600'],
        "on": {
          "click": () => this.submit(d)
        }
      }, [d.name]));
    },
    baseUrl() {
      const {
        host = ''
      } = process.client ? window.location : {};
      return `http://${host}/_cli/`;
    }
  },
  methods: {
    async beforeOpen() {
      await this.getDomain();
      this.loading = true;
      const res = await this.$api.settings.domain.filter();
      if (res.errored) return;
      this.domains = res.data.records;
      this.loading = false;
    },
    async getDomain() {
      this.domainInFile = await this.$axios.$request({
        method: 'get',
        baseURL: this.baseUrl,
        url: 'get-domain'
      });
    },
    async submit(domain) {
      this.domainInFile = await this.$axios.$request({
        method: 'post',
        baseURL: this.baseUrl,
        url: 'set-domain',
        data: {
          domain: domain.url
        }
      });
    }
  },
  render() {
    const h = arguments[0];
    return h("modal", _mergeJSXProps([{
      "attrs": {
        "name": Modals.DomainList,
        "id": Modals.DomainList,
        "height": '60%'
      }
    }, {
      "on": {
        'before-open': this.beforeOpen
      }
    }]), [h("div", {
      "class": "p-4 h-full flex flex-col"
    }, [h("div", {
      "class": 'mb-4'
    }, ["Selected: ", this.loading ? '...' : this.selectedDomain || 'None', this.domainInFile !== this.$config.forceDomain && h("div", {
      "class": 'text-red-600'
    }, ["Nuxt is retarded, please rebuild to apply changes."])]), h("ul", {
      "class": "flex-1 min-h-0 overflow-auto bg-gray-200 shadow-inner rounded-sm p-2"
    }, [this.loading ? h("li", {
      "class": 'h-full w-full flex items-center justify-center'
    }, ["Loading..."]) : this.list])])]);
  }
});
export default DomainList;