function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export default class CurlHelper {
  constructor(config) {
    _defineProperty(this, "request", void 0);
    this.request = config;
  }
  getHeaders(oneLine = false) {
    let headers = '';
    const mapH = obj => Object.keys(obj).map(h => `-H '${h}: ${obj[h]}'`).join(oneLine ? ' ' : '\n ');
    Object.keys(this.request.headers).forEach(key => {
      if (typeof this.request.headers[key] === 'object') {
        const eh = this.request.headers[key];
        if (!Object.keys(eh).length) return;
        headers += mapH(eh);
        return;
      }
      headers += mapH({
        [key]: this.request.headers[key]
      });
    });
    return headers;
  }
  getMethod() {
    return `-X '${(this.request.method || '').toUpperCase()}'`;
  }
  getBody() {
    const {
      data,
      method
    } = this.request;
    if (!method || !data || method.toUpperCase() === 'GET') return '';
    const string = typeof data === 'string' ? data : JSON.stringify(data);
    return string ? `--data '${string.trim()}'` : '';
  }
  getUrl() {
    if (this.request.baseURL) {
      return `${this.request.baseURL}/${this.request.url}`;
    }
    return this.request.url || '';
  }
  getQueryString() {
    const {
      params
    } = this.request;
    if (!params) return '';
    const mapped = Object.keys(params).map(p => `${p}=${params[p]}`).join('&');
    return mapped ? `?${mapped}` : '';
  }
  getBuiltURL() {
    let url = this.getUrl();
    if (this.getQueryString()) {
      url = url.charAt(url.length - 1) == '/' ? url.substr(0, url.length - 1) : url;
      url += this.getQueryString();
    }
    return `'${url.trim()}'`;
  }
  generateCommand(oneLine = false) {
    if (!this.request) return '';
    const content = [this.getBuiltURL(), this.getMethod(), this.getHeaders(oneLine), this.getBody()].join(oneLine ? ' ' : '\n ');
    return `curl ${content}`.trim();
  }
}