// Types
import mixins from "vue-typed-mixins";
// Main
const CheckoutSuccessView = mixins().extend({
  name: 'CheckoutSuccessView',
  render() {
    const h = arguments[0];
    return h("div", {
      "class": "container text-center"
    }, [h("h2", {
      "class": "text-3xl font-bold"
    }, ["Svr\u0161etak"]), h("div", ["Narud\u017Eba: ", this.$route.query.orderSlug])]);
  }
});
export default CheckoutSuccessView;