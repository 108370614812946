import mixins from "vue-typed-mixins";
import '../ui/globalStyle.css';
import '../ui/icomoon.css';

// Components
import ControllerBundleCustomization from '../controllers/ControllerBundleCustomization';
import ControllerProductList from '../controllers/ControllerProductList';
import ControllerProduct from '../controllers/ControllerProduct';
import ControllerProductConfiguration from '../controllers/ControllerProductConfiguration';
import ControllerProductCustomOptions from '../controllers/ControllerProductCustomOptions';
import AdminButton from '../ui/AdminButton';
import CmsLink from '../ui/CmsLink';
import CmsImage from '../ui/CmsImage';

//Enums

// Utils
import { valueFromInput } from '../../utils/jsxHelpers';
const BundleCustomizationModalView = mixins().extend({
  name: 'BundleCustomizationModalView',
  components: {
    // CartModalView,
    CmsImage,
    CmsLink,
    AdminButton,
    ControllerBundleCustomization,
    ControllerProductList,
    ControllerProduct,
    ControllerProductConfiguration,
    ControllerProductCustomOptions
  },
  render() {
    const h = arguments[0];
    return h("div", {
      "class": "p-6 w-full h-full overflow-y-scroll"
    }, [h("div", {
      "class": "flex items-center justify-end"
    }, [h(AdminButton, {
      "attrs": {
        "secondary": true
      },
      "on": {
        "click": () => this.$emit('close')
      }
    }, [this.$t('core.action.close')])]), h("div", {
      "class": "my-5"
    }, [h(ControllerBundleCustomization, {
      "class": "wide-controller-container",
      "scopedSlots": {
        default: ({
          item,
          options,
          cartState,
          addToCart,
          removeFromCart
        }) => h("div", [h("p", {
          "class": "controller-title"
        }, ["Controller bundle customization:"]), h("div", {
          "class": "flex items-center justify-between space-x-3 lg:space-x-6 xl:space-x-12"
        }, [h(CmsLink, {
          "attrs": {
            "to": item
          },
          "scopedSlots": {
            default: () => h(CmsImage, {
              "attrs": {
                "media": item === null || item === void 0 ? void 0 : item.media
              },
              "class": "h-24 w-24"
            })
          }
        }), h("div", {
          "class": "text-md px-3 py-2 bg-orange-300 flex-grow rounded text-center"
        }, [h("p", [h("span", {
          "class": "uppercase font-bold text-sm"
        }, ["Bundle:"]), ' ' + (item === null || item === void 0 ? void 0 : item.name)]), h("p", [h("span", {
          "class": "uppercase font-bold text-sm"
        }, ["In cart:"]), ' ' + cartState.qty])]), h("input", {
          "attrs": {
            "type": "number"
          },
          "domProps": {
            "value": options.quantity
          },
          "class": "number-input",
          "on": {
            "input": valueFromInput(v => options.quantity = Number(v))
          }
        }), h("button", {
          "attrs": {
            "type": "button"
          },
          "on": {
            "click": () => {
              addToCart();
            }
          },
          "class": "bg-orange-500 text-white custom-btn-mini"
        }, ["add to cart"])]), h("div", {
          "class": "wide-controller-container"
        }, [h("p", {
          "class": "controller-subtitle"
        }, ["Controller product list:"]), h(ControllerProductList, {
          "scopedSlots": {
            default: () => h(ControllerProduct, {
              "class": "wide-controller-container",
              "scopedSlots": {
                default: props => {
                  var _props$item2;
                  return h("div", [h("p", {
                    "class": "controller-subtitle"
                  }, ["Controller product"]), h("div", {
                    "class": "flex items-center justify-between space-x-3 lg:space-x-6 xl:space-x-12"
                  }, [h(CmsLink, {
                    "attrs": {
                      "to": props.item
                    },
                    "scopedSlots": {
                      default: () => {
                        var _props$item;
                        return h(CmsImage, {
                          "attrs": {
                            "media": (_props$item = props.item) === null || _props$item === void 0 ? void 0 : _props$item.media
                          },
                          "class": "h-24 w-24"
                        });
                      }
                    }
                  }), h("p", {
                    "class": "text-md px-3 py-2 bg-gray-300 flex-grow text-center rounded"
                  }, [h("span", {
                    "class": "uppercase font-bold text-sm"
                  }, ["Product:"]), ' ' + ((_props$item2 = props.item) === null || _props$item2 === void 0 ? void 0 : _props$item2.name)]), h(ControllerProductConfiguration, {
                    "scopedSlots": {
                      default: ({
                        item
                      }) => h("label", [item.label + ' ', h("select", {
                        "domProps": {
                          "value": item.value
                        },
                        "on": {
                          "change": valueFromInput(v => item.value = Number(v))
                        }
                      }, [
                      //@ts-ignore
                      item.options.map(option => h("option", {
                        "domProps": {
                          "value": option.id
                        },
                        "key": option.id
                      }, [option.value]))])])
                    }
                  }), h(ControllerProductCustomOptions, {
                    "scopedSlots": {
                      text: ({
                        option
                      }) => h("label", {
                        "class": "block"
                      }, [option.label, h("input", {
                        "domProps": {
                          "value": option.value
                        },
                        "on": {
                          "input": valueFromInput(v => option.value = v)
                        },
                        "attrs": {
                          "type": "text"
                        }
                      })]),
                      number: ({
                        option
                      }) => h("label", {
                        "class": "block"
                      }, [option.label, h("input", {
                        "domProps": {
                          "value": option.value
                        },
                        "on": {
                          "input": valueFromInput(v => option.value = v)
                        },
                        "attrs": {
                          "type": "number"
                        }
                      })])
                    }
                  })])]);
                }
              }
            })
          }
        })])])
      }
    })])]);
  }
});
export default BundleCustomizationModalView;