// Types
import mixins from "vue-typed-mixins";
// Mixins
import ControllerBase from '../../../mixins/ControllerBase';
import VuelidateHelper from '../../../mixins/VuelidateHelper';

// Utils
import { email, required } from 'vuelidate/lib/validators';
const ControllerUser = mixins(ControllerBase, VuelidateHelper).extend({
  name: 'ControllerUser',
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: ''
      }
    };
  },
  created() {
    if (!this.$auth.loggedIn) return;
    const f = this.form;
    const {
      firstName,
      lastName
    } = this.$auth.user.details || {};
    f.firstName = firstName || '';
    f.lastName = lastName || '';
    f.email = this.$auth.user.email || '';
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      const {
        firstName,
        lastName,
        email
      } = this.form;
      // TODO: back needs to validate repeated new password
      const request = this.$api.user.web.update({
        details: {
          firstName,
          lastName
        },
        email,
        username: email
      });
      const res = await this.callWithStatusUpdate(request, 'submitted');
      if (res.errored) return;
    }
  },
  render() {
    const {
      form,
      submit,
      status,
      $v,
      generateErrorMessage
    } = this;
    const slotProps = {
      form,
      submit,
      status,
      $v,
      generateErrorMessage
    };
    return this.renderContainer(slotProps);
  }
});
export default ControllerUser;