const cookies = (ctx, inject) => {
  const getCookie = name => {
    return ctx.$cookies.get(name);
  };
  inject('getCookie', getCookie);
  const setCookie = (name, value, options = {
    maxAge: 2592000
  }) => {
    ctx.$cookies.set(name, value, {
      ...options,
      maxAge: options.maxAge || 2592000
    });
  };
  inject('setCookie', setCookie);
  const deleteCookie = name => {
    ctx.$cookies.remove(name);
  };
  inject('deleteCookie', deleteCookie);
};
export default cookies;