// Types
import Vue from 'vue';
// Utils
import consola from 'consola';

// Main
const EditorContainer = Vue.extend({
  provide() {
    return {
      registerEditor: this.registerEditor,
      unregisterEditor: this.unregisterEditor,
      registeredEditors: this.registeredEditors
    };
  },
  data() {
    return {
      registeredEditors: []
    };
  },
  methods: {
    registerEditor(newEditor) {
      this.registeredEditors.push(newEditor);
      const hasDuplicates = this.registeredEditors.filter(editor => editor.name === newEditor.name).length > 1;
      hasDuplicates && consola.error(`${this.$options.name}: Multiple editors with the name \'${newEditor.name}\'!`);
    },
    unregisterEditor(editorToUnregister) {
      const index = this.registeredEditors.findIndex(editor => editor.name === editorToUnregister.name);
      this.registeredEditors.splice(index, 1);
    }
  }
});
export default EditorContainer;