import './CmsSection.scss';
import mixins from 'vue-typed-mixins';
import sectionView from '../../mixins/sectionView';
import { instanceInject } from '../../mixins/instanceInject';
const injects = instanceInject('standardSectionBase');
export default mixins(sectionView, injects).extend({
  components: {
    // debugSection: () => import('@/core/sections/tools/debugSections'),
    // gboxVisibilityModal: () => import('@/core/pages/modals/visibility')
  },
  props: {
    tag: {
      type: String,
      default: 'section'
    },
    options: {
      type: Object,
      default: () => ({})
    },
    hideVisibility: {
      type: Boolean,
      default: false
    },
    showSectionOptions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showVisibilityModal: false
    };
  },
  computed: {
    // showDebugComponents() {
    //   return (
    //     (process.env.NODE_ENV === 'development' || process.env.debug) &&
    //     this.$route.hash === '#debugComponents'
    //   )
    // },
    isFirstSection() {
      var _this$standardSection;
      return ((_this$standardSection = this.standardSectionBase) === null || _this$standardSection === void 0 ? void 0 : _this$standardSection.index) === 1;
    },
    isLastSection() {
      var _this$standardSection2, _this$standardSection3;
      return ((_this$standardSection2 = this.standardSectionBase) === null || _this$standardSection2 === void 0 ? void 0 : _this$standardSection2.totalComponents) === ((_this$standardSection3 = this.standardSectionBase) === null || _this$standardSection3 === void 0 ? void 0 : _this$standardSection3.index);
    },
    returnSectionName() {
      var _base$componentData;
      const base = this.standardSectionBase;
      return (base === null || base === void 0 ? void 0 : (_base$componentData = base.componentData) === null || _base$componentData === void 0 ? void 0 : _base$componentData.handler) || '';
    }
  },
  methods: {
    genTransitionedSlots() {
      const nodes = [];
      ['link', 'list', 'options'].forEach(slot => {
        var _this$$scopedSlots$sl, _this$$scopedSlots;
        nodes.push(this.$createElement('transition', {
          props: {
            enterActiveClass: 'animated fadeInDownBig',
            leaveActiveClass: 'animated fadeOutUp'
          }
        }, (_this$$scopedSlots$sl = (_this$$scopedSlots = this.$scopedSlots)[slot]) === null || _this$$scopedSlots$sl === void 0 ? void 0 : _this$$scopedSlots$sl.call(_this$$scopedSlots, {
          name: slot
        })));
      });
      return nodes;
    },
    genVisibilityButton() {
      if (this.$route.query.mobileView || !this.contentEditableView || this.hideVisibility) return null;
      return this.$createElement('button', {
        class: 'section-button section-button--visibility',
        domProps: {
          type: 'button'
        },
        on: {
          click: () => {
            this.showVisibilityModal = !this.showVisibilityModal;
          }
        },
        directives: [{
          name: 'tooltip',
          modifiers: {
            right: true
          },
          value: 'Click here to change section visibility'
        }]
      }, [this.$createElement('fa', {
        props: {
          icon: 'eye'
        }
      })]);
    },
    genSectionOptionsButton() {
      if (!this.showSectionOptions || !this.contentEditableView) return null;
      return this.$createElement('button', {
        class: 'section-button',
        domProps: {
          type: 'button'
        },
        on: {
          click: () => {
            this.$emit('openSectionOptions');
          }
        },
        directives: [{
          name: 'tooltip',
          modifiers: {
            right: true
          },
          value: 'Click here to edit section options'
        }]
      }, [this.$createElement('i', {
        class: 'core-icon-cog'
      })]);
    }
  },
  render(h) {
    return h(this.tag, {
      class: [`section_${this.returnSectionName}`, {
        'section--first': this.isFirstSection
      }, {
        'section--last': this.isLastSection
      }]
    }, [this.$slots.default, ...this.genTransitionedSlots(), this.genVisibilityButton(), this.genSectionOptionsButton()]);
  }
});